/*
 * @LastEditTime: 2022-10-08 07:52:17
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\FullStatistics2\index.js
 */
import { styled } from 'twin.macro'
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import { useState, useRef } from 'react'
import { useQueries } from 'react-query'
import { useRecoilValue } from 'recoil'
import { useFullscreen, useToggle } from 'react-use'
import { reverse } from 'ramda'

import { fishPond, pharmacy } from 'service'
import { userInfoAtom } from 'store/auth'

import InspectFullStatic from './Inspect'
import PatrolFullStatic from './Patrol'
import Map from './Map'

const Wrap = styled.div`
    font-family: 'SuCaiJiShiKuFangTi';
    .ant-modal-content {
        background-color: rgba(9, 61, 92, 0.8) !important;
    }
`

export default function Main() {
    const userInfo = useRecoilValue(userInfoAtom)
    const [show, toggle] = useToggle(false)
    const fullRef = useRef(null)
    const isFullscreen = useFullscreen(fullRef, show, { onClose: () => toggle(false) })
    const [current, setCurrent] = useState({})
    const [showType, setShowType] = useState(undefined)
    const [fryTypeId, setFryType] = useState(undefined)
    const [areaPaths, setAreaPath] = useState(userInfo.areaPaths)

    const [{ data: pondList }, { data: pharmacyList }] = useQueries([
        {
            queryKey: ['fish-pond', 'list', userInfo.areaPaths, fryTypeId],
            queryFn: () => fishPond.list({ areaPaths: userInfo.areaPaths, fryTypeId, filterCoordinate: true }),
        },
        {
            queryKey: ['pharmacy', 'list', areaPaths],
            queryFn: () => pharmacy.list({ areaPaths }),
            select: (data) => reverse(data),
        },
    ])

    const onMarkerClick = (value, type) => {
        setCurrent(value)
        setShowType(type)
    }

    return (
        <Wrap ref={fullRef}>
            <FullScreenContainer>
                <div className='w-full h-full box-border relative'>
                    {showType === 'inspect' && (
                        <InspectFullStatic
                            fullRef={fullRef}
                            pondList={pondList}
                            toggle={toggle}
                            userInfo={userInfo}
                            currentPond={current}
                            isFullscreen={isFullscreen}
                            onFryTypeChange={(value) => setFryType(value)}
                            onPondChange={(value) => setCurrent(value)}
                        />
                    )}
                    {showType === 'patrol' && (
                        <PatrolFullStatic
                            fullRef={fullRef}
                            userInfo={userInfo}
                            toggle={toggle}
                            pharmacyList={pharmacyList}
                            isFullscreen={isFullscreen}
                            currentPharmacy={current}
                            onAreaChange={(value) => (value ? setAreaPath(value) : setAreaPath(userInfo.areaPaths))}
                            onPharmacyChange={(value) => setCurrent(value)}
                        />
                    )}

                    <Map
                        showType={showType}
                        selectedPond={current}
                        pondList={pondList}
                        pharmacyList={pharmacyList}
                        onMarkerClick={onMarkerClick}
                    />
                </div>
            </FullScreenContainer>
        </Wrap>
    )
}
