/*
 * @LastEditTime: 2022-10-13 10:06:17
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\User\Expert\Update.js
 */
import { DatePicker, Form, Input, Segmented, Switch } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { user } from 'service'
import dayjs from 'dayjs'

const { Item } = Form

export default function PatrolUpdate({ current, onClose }) {
    const queryClient = useQueryClient()

    const updateMutation = useMutation(user.update, {
        onSuccess: () => {
            queryClient.invalidateQueries(['expert'])
            onClose()
        },
    })

    const onSubmit = (values) => {
        updateMutation.mutate({
            ...values,
            birthday: values.birthday?.format('YYYY-MM-DD') ?? undefined,
        })
    }

    return (
        <Form
            name='patrol-drawer-form'
            onFinish={onSubmit}
            layout='vertical'
            autoComplete='off'
            initialValues={{
                ...current,
                birthday: current.birthday ? dayjs(current.birthday) : null,
            }}
        >
            <Item hidden name='id'>
                <Input />
            </Item>
            <Item hidden name='type'>
                <Input />
            </Item>
            <Item label='姓名' name='name' rules={[{ required: true, message: '请输入' }]}>
                <Input />
            </Item>
            <Item
                label='手机号码'
                name='mobile'
                rules={[
                    { required: true, message: '请输入' },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                    },
                ]}
                validateTrigger='onBlur'
            >
                <Input maxLength={11} disabled={current.unionid !== undefined} />
            </Item>
            <Item label='出生日期' name='birthday'>
                <DatePicker className='w-full' />
            </Item>
            <Item label='擅长领域' name='goodAt' rules={[{ required: true, message: '请输入' }]}>
                <Input />
            </Item>
            <Item label='性别' name='gender'>
                <Segmented
                    options={[
                        { label: '男', value: 'male' },
                        { label: '女', value: 'female' },
                    ]}
                />
            </Item>
            <Item
                label='账号状态'
                name='enabled'
                valuePropName='checked'
                rules={[{ required: true, message: '请输入选择类型' }]}
            >
                <Switch />
            </Item>
            <Item label='专家介绍' name='remark' rules={[{ required: true, message: '请输入' }]}>
                <Input.TextArea />
            </Item>
        </Form>
    )
}
