/*
 * @LastEditTime: 2022-09-28 08:43:03
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/Patrol/TaskInfo.js
 */
import { Descriptions, Image, Space, Spin } from 'antd'
import { head } from 'ramda'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { pharmacyTask } from 'service'
import tw, { styled } from 'twin.macro'

const Wrap = styled.div`
    ${tw`h-[90vh] overflow-y-auto`};
    .ant-descriptions-title {
        color: #fff;
    }
`
const Title = tw.div`w-full bg-gradient-to-b from-[rgba(61,169,169,0.85)] to-transparent text-white text-lg px-4 py-2 rounded-full text-center`
const { Item } = Descriptions

const taskTypeMap = {
    plan: '计划任务',
    rectification: '整改任务',
    fish: '鱼类抽查（非计划任务）',
    water: '水质抽查（非计划任务）',
    storehouse: '仓库检查（非计划任务）',
    log: '日志检查（非计划任务）',
}

export default function Main({ taskId, pharmacyInfo, getContainer }) {
    const { data, isFetching } = useQuery(['pharmacy-task', 'one', taskId], () => pharmacyTask.one(taskId), {
        placeholderData: {},
        enabled: !!taskId,
    })
    const current = useMemo(() => (data.farmProduceVOList ? head(data.farmProduceVOList) : {}), [data])

    if (isFetching) {
        return <Spin className='flex items-center justify-center' />
    }

    return (
        <Wrap className='space-y-4'>
            <Title>巡查任务信息</Title>
            <div className='grid grid-cols-2 text-white gap-2'>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务编号</div>
                    <div>{data.code}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务药店</div>
                    <div>{data.pharmacyName}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务类型</div>
                    <div>{taskTypeMap[data.taskType]}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务截止时间</div>
                    <div>{data.endTime}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>药店位置</div>
                    <div>{pharmacyInfo.addreess || '未记录'}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>发布时间</div>
                    <div>{data.createTime}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>巡店员</div>
                    <div>{data.patrollerName}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务状态</div>
                    <div>{data.state === 2 ? '已完成' : '待完成'}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>完成时间</div>
                    <div>{data.completeTime}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>是否超期</div>
                    <div>{data.overdue ? '超期' : '未超期'}</div>
                </div>
            </div>
            <Title>巡查记录信息</Title>
            <div className='space-y-2'>
                <Descriptions
                    title='农业投入品巡查检查记录表'
                    bordered
                    size='small'
                    labelStyle={{ backgroundColor: '#58768c', fontSize: '12px', color: '#fff' }}
                    contentStyle={{ backgroundColor: '#58768c', fontSize: '12px', color: '#9cf4ff' }}
                    column={2}
                >
                    <Item label='被检查单位名称'>{current.company}</Item>
                    <Item label='检查地点'>{current.address}</Item>
                    <Item label='类别' span={2}>
                        {current.catalogName}
                    </Item>
                    <Item label='生产（经营）品种'>{current.variety}</Item>
                    <Item label='规模（面积/库存等）'>{current.scale}</Item>
                    <Item span={2} label='依法履行行政许可手续、被检查单位有关人员是否法定资质'>
                        {current.qualification ? '是' : '否'}
                    </Item>
                    <Item span={2} label='依法制作农业投入品生产、经营、销售记录，及其相关台账、资料等'>
                        {current.make ? '是' : '否'}
                    </Item>
                    <Item
                        span={2}
                        label={
                            <span>
                                '是否建立完善的农业投入品生产经营管
                                <span
                                    style={{
                                        fontFamily: 'serif',
                                    }}
                                    className='text-xs'
                                >
                                    理
                                </span>
                                制度、操作规范等'
                            </span>
                        }
                    >
                        {current.build ? '是' : '否'}
                    </Item>
                    <Item span={2} label='商毒农药、限用农药档案记录是否规范'>
                        {current.pesticide ? '是' : '否'}
                    </Item>
                    <Item
                        span={2}
                        label={
                            <span>
                                主要负责人是否了解农产品质量管
                                <span
                                    style={{
                                        fontFamily: 'serif',
                                    }}
                                    className='text-xs'
                                >
                                    理
                                </span>
                                的相关法律、法规及相关禁止性规定
                            </span>
                        }
                    >
                        {current.learn ? '是' : '否'}
                    </Item>
                    <Item
                        span={2}
                        label={
                            <span>
                                农业投入品是否实施追溯管
                                <span
                                    style={{
                                        fontFamily: 'serif',
                                    }}
                                    className='text-xs'
                                >
                                    理
                                </span>
                            </span>
                        }
                    >
                        {current.traceability ? '是' : '否'}
                    </Item>
                    <Item span={2} label='主要问题'>
                        {current.problem}
                    </Item>
                    <Item span={2} label='整改意见'>
                        {current.opinion}
                    </Item>
                    <Item span={2} label='限制整改时间'>
                        {current.rectificationDate}
                    </Item>
                    <Item span={2} label='备注'>
                        {current.remark}
                    </Item>
                    <Item label='巡查员'>{current.patrollerName}</Item>
                    <Item label='巡查时间'>{current.createTime}</Item>
                </Descriptions>
                <div className='space-y-10'>
                    <Descriptions
                        size='small'
                        column={2}
                        labelStyle={{ fontSize: '12px', color: '#fff' }}
                        contentStyle={{ fontSize: '12px', color: '#9cf4ff' }}
                        layout='vertical'
                    >
                        <Item label='打卡时间'>{data.punchTime}</Item>
                        <Item label='打卡地点'>{data.location}</Item>
                        <Item label='任务照片'>
                            <Space>
                                {data.attachmentsVOList &&
                                    data.attachmentsVOList.map((x) => (
                                        <Image
                                            src={x.url}
                                            className='!w-24 !h-24 object-cover'
                                            preview={{ getContainer }}
                                        />
                                    ))}
                            </Space>
                        </Item>
                    </Descriptions>
                </div>
            </div>
        </Wrap>
    )
}
