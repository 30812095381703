/*
 * @LastEditTime: 2022-07-22 09:16:57
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Information/InformationType/Update.js
 */
import { Form, Input, Switch } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { cmsType } from 'service'

const { Item } = Form

export default function InformationUpdate({ current, onClose }) {
    const queryClient = useQueryClient()

    const updateMutation = useMutation(cmsType.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('cms-type')
            onClose()
        },
    })

    const onSubmit = (values) => {
        updateMutation.mutate({ ...values, id: current.id })
    }

    return (
        <Form
            name='information-drawer-form'
            onFinish={onSubmit}
            initialValues={current}
            layout='vertical'
            autoComplete='off'
        >
            <Item
                label='类型名称'
                name='name'
                rules={[
                    { required: true, message: '必填' },
                    { max: 5, message: '不能超过5个字' },
                ]}
            >
                <Input />
            </Item>
            <Item label='类型状态' name='state' valuePropName='checked'>
                <Switch />
            </Item>
        </Form>
    )
}
