import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, message, Form, Input, DatePicker, Image, Select, Drawer, Popover } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDisclosure } from 'utils/useDisclosure'
import { findLast, isEmpty, isNil, propEq } from 'ramda'

import { pharmacy } from 'service'
import Create from './Create'
import Update from './Update'
import PharmacyInfo from './PharmacyInfo/index'
import dayjs from 'dayjs'
import ConfirmButton from 'components/ConfirmButton'
import { QrcodeOutlined } from '@ant-design/icons'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item, useForm } = Form
const { RangePicker } = DatePicker

const statusOptions = [
    { label: '启用', value: 'true' },
    { label: '禁用', value: 'false' },
]

const businessOptions = [
    { label: '在业', value: 'operate' },
    { label: '暂停营业', value: 'suspend' },
    { label: '停业', value: 'close' },
]

const Publish = ({ current, onClose }) => {
    return <PharmacyInfo current={current} onClose={onClose} />
}

export default function DrugstoreManager() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenInfo, onOpen: onOpenInfo, onClose: onCloseInfo } = useDisclosure()
    const [form] = useForm()

    const [query, setQuery] = useQueryParams({
        name: StringParam,
        code: StringParam,
        businessStatus: StringParam,
        state: StringParam,
        createTimeFrom: StringParam,
        createTimeTo: StringParam,
        time: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const { data, isFetching, refetch } = useQuery(['pharmacy', 'page', query], () => pharmacy.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const deleteMutation = useMutation(pharmacy.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries('pharmacy')
            message.success('删除成功')
        },
    })

    const qrCodeMutation = useMutation(pharmacy.qrCode, {
        onSuccess: () => {
            message.success('操作成功')
            refetch()
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const handleManager = (item) => {
        setCurrent(item)
        onOpenInfo()
    }

    const columns = [
        {
            title: '药店编号',
            dataIndex: 'code',
        },
        {
            title: '药店封面',
            key: 'url',
            render: (r) => {
                return (
                    <Image
                        src={r.attachments !== undefined && r.attachments.length > 0 ? r.attachments[0].url : ''}
                        className='object-cover'
                        width={64}
                        height={64}
                    />
                )
            },
        },
        {
            title: '药店名称',
            dataIndex: 'name',
        },
        {
            title: '药店评级',
            dataIndex: 'score',
        },
        {
            title: '经营状态',
            key: 'prescription',
            render: (r) => findLast(propEq('value', r.businessStatus))(businessOptions).label,
        },
        {
            title: '门店负责人',
            dataIndex: 'principal',
        },
        {
            title: '负责人电话',
            dataIndex: 'phone',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
        },
        {
            title: '状态',
            key: 'switch',
            width: 60,
            align: 'center',
            render: (r) => <Space>{r.state ? '启用' : '禁用'}</Space>,
        },
        {
            title: '二维码',
            align: 'center',
            render: (item) =>
                item.qrcode ? (
                    <Popover content={<Image src={item.qrcode} />} trigger='click'>
                        <Button size='small' type='link' icon={<QrcodeOutlined />} />
                    </Popover>
                ) : (
                    <Button size='small' type='link' onClick={() => qrCodeMutation.mutate(item.id)}>
                        生成二维码
                    </Button>
                ),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                    <Button type='link' size='small' onClick={() => handleManager(r)}>
                        管理账号
                    </Button>
                </Space>
            ),
        },
    ]

    return isOpenInfo ? (
        <Publish current={current} onClose={onCloseInfo} />
    ) : (
        <Wrap>
            <Form
                form={form}
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        createTimeFrom:
                            !isEmpty(value.time) && value.time?.length > 1
                                ? value?.time[0]?.format('YYYY-MM-DD hh:mm:ss')
                                : undefined,
                        createTimeTo:
                            !isEmpty(value.time) && value.time?.length > 1
                                ? value.time[1]?.format('YYYY-MM-DD hh:mm:ss')
                                : undefined,
                        time: undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    time:
                        query.createTimeFrom && query.createTimeTo
                            ? [dayjs(query.createTimeFrom), dayjs(query.createTimeTo)]
                            : undefined,
                }}
                className='gap-2'
            >
                <Item name='name' className='w-48'>
                    <Input placeholder='药店名称' />
                </Item>
                <Item name='code' className='w-48'>
                    <Input placeholder='药店编号' />
                </Item>
                <Item name='businessStatus' className='w-48'>
                    <Select allowClear placeholder='经营状态' options={businessOptions} />
                </Item>
                <Item name='state' className='w-48'>
                    <Select allowClear placeholder='药店状态' options={statusOptions} />
                </Item>
                <Form.Item name='time'>
                    <RangePicker placeholder={['创建时间', '时间范围']} />
                </Form.Item>

                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching || qrCodeMutation.isLoading}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                    showSizeChanger: false,
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '添加药店' : '修改药店'}
                onClose={onClose}
                width='500px'
                extra={
                    <Button form='pharmacy-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
