/*
 * @LastEditTime: 2022-07-18 14:08:27
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/components/ErrorPage/index.js
 */
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'

export default function Main({ status, title }) {
    const navigate = useNavigate()
    return (
        <div className='w-full h-screen flex flex-col items-center justify-center'>
            <Result
                status={status}
                title={title}
                extra={
                    <Button type='primary' onClick={() => navigate('/', { replace: true })}>
                        返回
                    </Button>
                }
            />
        </div>
    )
}
