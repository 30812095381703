/*
 * @LastEditTime: 2022-08-30 15:50:09
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/FishIll/Medication.js
 */
import { Card, Form, TreeSelect, Empty, Spin } from 'antd'
import { useState, useMemo } from 'react'
import { useQueries } from 'react-query'
import { isEmpty } from 'ramda'
import { Bar } from '@ant-design/plots'

import { area, medicineStock } from 'service'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function Medication() {
    const [areaPaths, setAreaPaths] = useState(undefined)

    const [areaTree, stockOut] = useQueries([
        {
            queryKey: ['area', 'tree', 'street'],
            queryFn: () => area.tree({ level: 'street' }),
            placeholderData: [],
        },
        {
            queryKey: ['stock-statis-area', areaPaths],
            queryFn: () => medicineStock.statisStockByMedicine({ areaPaths }),
            placeholderData: [],
            keepPreviousData: true,
            select: (data) =>
                data.map((x) => ({
                    label: x.medicineName,
                    总数: x.amount,
                })),
        },
    ])

    const config = useMemo(
        () => ({
            data: stockOut.data,
            xField: '总数',
            yField: 'label',
            minBarWidth: 20,
            maxBarWidth: 20,
        }),
        [stockOut.data]
    )

    return (
        <Card title='区域用药统计' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={stockOut.isFetching}>
                <Form autoComplete='off' layout='inline' className='gap-2 h-12 justify-end'>
                    <Item className='w-48'>
                        <TreeSelect
                            placeholder='全部区域'
                            allowClear
                            onChange={(value) => setAreaPaths(value)}
                            showSearch
                            treeNodeFilterProp='filter'
                        >
                            {renderTreeNode(areaTree.data, 'street')}
                        </TreeSelect>
                    </Item>
                </Form>
                {isEmpty(stockOut.data) ? (
                    <Empty className='h-60 flex flex-col items-center justify-center' />
                ) : (
                    <Bar {...config} />
                )}
            </Spin>
        </Card>
    )
}
