/*
 * @LastEditTime: 2022-08-30 17:47:50
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Information/InformationContent/Update.js
 */
import { useRef } from 'react'
import tw from 'twin.macro'
import { Button, Form, Input, Select, Upload, Radio, Space, message, DatePicker, Spin, Divider } from 'antd'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { head, isEmpty, isNil } from 'ramda'
import { useMutation, useQueries, useQueryClient } from 'react-query'
import moment from 'moment'

import Editor from './Editor'

import './index.scss'
import { FILE_URL } from 'utils/api'
import { cmsContent, cmsType } from 'service'

const { Item, useWatch, useForm } = Form
const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b sticky top-0 bg-white z-10`
const Content = tw.div`p-4 space-y-4`

export default function InformationUpdate({ current, onClose }) {
    const editorRef = useRef(null)
    const [form] = useForm()
    const imgLen = useWatch('url', form)
    const queryClient = useQueryClient()

    const [{ data }, { data: content, isFetching, refetch }] = useQueries([
        { queryKey: ['cms-type', 'list'], queryFn: () => cmsType.list(), placeholderData: [] },
        {
            queryKey: ['cms-content', 'one', current.id],
            queryFn: () => cmsContent.one(current.id),
            cacheTime: 0,
            placeholderData: {},
        },
    ])

    const updateMutaion = useMutation(cmsContent.update)

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const onCancelPublish = async () => {
        await updateMutaion.mutateAsync({ ...content, grounding: false })
        message.success('成功取消发布')
        refetch()
    }

    // 保存并发布
    const onPublish = async () => {
        const values = await form.validateFields()
        const html = editorRef.current.getHtml()
        const url = head(values.url).response?.body.url ?? content.url
        await updateMutaion.mutateAsync({
            ...values,
            content: html,
            grounding: true,
            url,
            id: content.id,
            publishedTime: moment(values.publishedTime).format('YYYY-MM-DD'),
        })
        message.success('发布成功')
        queryClient.invalidateQueries('cms-content')
        onClose()
    }

    // 保存
    const onSubmit = async (values) => {
        const html = editorRef.current.getHtml()
        const url = head(values.url).response?.body.url ?? content.url
        await updateMutaion.mutateAsync({
            ...values,
            content: html,
            grounding: false,
            url,
            id: content.id,
            publishedTime: moment(values.publishedTime).format('YYYY-MM-DD'),
        })
        message.success('保存成功')
        queryClient.invalidateQueries('cms-content')
    }

    if (isFetching) {
        return (
            <div className='flex items-center justify-center w-full h-full'>
                <Spin />
            </div>
        )
    }

    return (
        <Wrap className='infomationUpdatePages'>
            <Header>
                <Space>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={onClose} />
                    <span className='text-xl font-bold'>发布资讯</span>
                </Space>
                <Space>
                    {content.grounding ? (
                        <Button onClick={onCancelPublish}>取消发布</Button>
                    ) : (
                        <>
                            <Button ghost type='primary' form='information-drawer-form' htmlType='submit'>
                                保存
                            </Button>
                            <Button type='primary' onClick={onPublish}>
                                保存并且发布
                            </Button>
                        </>
                    )}
                </Space>
            </Header>
            <Content>
                <Form
                    form={form}
                    className='w-[800px] grid grid-cols-2 gap-4'
                    name='information-drawer-form'
                    onFinish={onSubmit}
                    autoComplete='off'
                    layout='vertical'
                    initialValues={{
                        ...content,
                        publishedTime: content.publishedTime && moment(content.publishedTime),
                        url: [
                            {
                                url: content.url,
                            },
                        ],
                    }}
                >
                    <Item label='资讯标题' name='title' rules={[{ required: true, message: '必填' }]}>
                        <Input disabled={content.grounding} />
                    </Item>
                    <Item label='资讯类型' name='cmsTypeId' rules={[{ required: true, message: '必填' }]}>
                        <Select
                            disabled={content.grounding}
                            options={data.map((item) => ({ value: item.id, label: item.name }))}
                        />
                    </Item>
                    <Item label='发布日期' name='publishedTime' rules={[{ required: true, message: '必填' }]}>
                        <DatePicker className='w-full' />
                    </Item>

                    <Item label='是否推荐' name='recommend'>
                        <Radio.Group disabled={content.grounding}>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Item>
                    <Item
                        label='封面图片'
                        name='url'
                        getValueFromEvent={normFile}
                        valuePropName='fileList'
                        rules={[{ required: true, message: '必填' }]}
                    >
                        <Upload
                            action={FILE_URL}
                            accept='image/*'
                            headers={{
                                auth: localStorage.getItem('Authorization'),
                            }}
                            name='file'
                            listType='picture-card'
                            data={{ bucketName: 'img' }}
                            disabled={content.grounding}
                        >
                            {(isNil(imgLen) || isEmpty(imgLen)) && (
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            )}
                        </Upload>
                    </Item>
                </Form>
                {content.grounding ? (
                    <>
                        <Divider>资讯内容</Divider>
                        <div dangerouslySetInnerHTML={{ __html: content.content }} />
                    </>
                ) : (
                    <Editor ref={editorRef} content={content.content} disabled={content.grounding} />
                )}
            </Content>
        </Wrap>
    )
}
