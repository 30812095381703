/**
 * 巡店任务目标
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Table, Form, Input, DatePicker, Result, Tag, Space, Drawer, message } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useDisclosure } from 'utils/useDisclosure'
import ConfirmButton from 'components/ConfirmButton'
import { pharmacyTaskTarget } from 'service'

import Create from './Create'
import Detail from './Detail'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function TaskTarget() {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        code: StringParam,
        name: StringParam,
        endTimeFrom: StringParam,
        endTimeTo: StringParam,
        publishedTimeFrom: StringParam,
        publishedTimeTo: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createdTime,desc'),
    })
    const [current, setCurrent] = useState(null)

    const { data, isFetching, isError } = useQuery(
        ['pharmacy-task-target', 'page', query],
        () => pharmacyTaskTarget.page(query),
        {
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        }
    )

    const invalidTarget = useMutation(pharmacyTaskTarget.invalid, {
        onSuccess: () => {
            queryClient.invalidateQueries(['pharmacy-task-target', 'page'])
            message.success('作废成功')
            onClose()
        },
    })
    const publishTarget = useMutation(pharmacyTaskTarget.publish, {
        onSuccess: () => {
            queryClient.invalidateQueries(['pharmacy-task-target', 'page'])
            message.success('发布成功')
            onClose()
        },
    })

    const deleteMutation = useMutation(pharmacyTaskTarget.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(['pharmacy-task-target', 'page'])
            message.success('删除成功')
            onClose()
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const goDetail = (item) => {
        setCurrent(item)
        onOpen()
    }

    const onInvalid = (item) => {
        invalidTarget.mutate(item)
    }
    const onDelete = (item) => {
        deleteMutation.mutate(item)
    }
    const onPublish = (item) => {
        publishTarget.mutate(item)
    }

    const columns = [
        {
            title: '任务目标编号',
            dataIndex: 'code',
        },
        {
            title: '任务目标名称',
            dataIndex: 'name',
        },
        {
            title: '任务截止日期',
            dataIndex: 'endTime',
        },
        {
            title: '状态',
            dataIndex: 'state',
            render: (r) => {
                return r === 0 ? (
                    <Tag color='blue'>待发布</Tag>
                ) : r === 1 ? (
                    <Tag color='green'>已发布</Tag>
                ) : r === 2 ? (
                    <Tag color='red'>已作废</Tag>
                ) : (
                    ''
                )
            },
        },
        {
            title: '发布人',
            dataIndex: 'publisherName',
        },
        {
            title: '发布时间',
            dataIndex: 'publishedTime',
        },
        {
            title: '操作',
            key: 'id',
            width: 150,
            align: 'center',
            render: (r) => {
                return (
                    <Space>
                        <Button type='link' size='small' onClick={() => goDetail(r)}>
                            详情
                        </Button>
                        <Button
                            type='link'
                            size='small'
                            onClick={() => navigate(`/task/store-list?taskTargetCode=${r.code}`)}
                        >
                            查看相关任务
                        </Button>
                        {r.state === 0 && (
                            <>
                                <Button size='small' type='link' onClick={() => onPublish(r.id)}>
                                    发布
                                </Button>
                                <ConfirmButton
                                    buttonProps={{ size: 'small', type: 'link' }}
                                    onConfirm={() => onDelete(r.id)}
                                />
                            </>
                        )}
                        {r.state === 1 && (
                            <>
                                <ConfirmButton
                                    buttonProps={{ size: 'small', type: 'link' }}
                                    buttonText='作废'
                                    tips='作废目标只会影响未处理的任务，已经完成的任务不会受到影响，确定要作废吗？'
                                    onConfirm={() => onInvalid(r.id)}
                                />
                            </>
                        )}
                    </Space>
                )
            },
        },
    ]
    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        endTimeFrom: value.time ? value.time[0].format('YYYY-MM-DD') : undefined,
                        endTimeTo: value.time ? value.time[1].format('YYYY-MM-DD') : undefined,
                        publishedTimeFrom: value.publishedTime
                            ? value.publishedTime[0].format('YYYY-MM-DD')
                            : undefined,
                        publishedTimeTo: value.publishedTime ? value.publishedTime[1].format('YYYY-MM-DD') : undefined,
                        time: undefined,
                        publishedTime: undefined,
                        page: 0,
                    })
                }
            >
                <Item name='code' className='w-48'>
                    <Input placeholder='目标编号' allowClear />
                </Item>
                <Item name='name' className='w-48'>
                    <Input placeholder='目标名称' allowClear />
                </Item>

                <Item name='time' className='w-60'>
                    <DatePicker.RangePicker className='w-full' placeholder={['任务截止', '始止时间']} />
                </Item>
                <Item name='publishedTime' className='w-60'>
                    <DatePicker.RangePicker className='w-full' placeholder={['发布时间', '始止时间']} />
                </Item>
                <Item>
                    <Button type='primary' htmlType='submit' ghost>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            {isError ? (
                <Result status='error' subTitle='发生一些意外，可能是因为服务器出现了故障，请重试！' />
            ) : (
                <Table
                    rowKey='id'
                    dataSource={data.content}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                        total: data?.totalElements ?? 0,
                        pageSize: query.size,
                        current: query.page + 1,
                        showSizeChanger: false,
                        onChange: (page) => setQuery({ page: page - 1 }),
                    }}
                />
            )}
            <Drawer
                width='500px'
                visible={isOpen}
                title={isNil(current) ? '新建巡店目标' : '巡店目标详细'}
                onClose={onClose}
                extra={
                    isNil(current) && (
                        <Button form='patroller-target-form' type='primary' htmlType='submit'>
                            提交
                        </Button>
                    )
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Detail current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
