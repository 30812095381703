/*
 * @LastEditTime: 2022-09-19 15:15:30
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/FrytypeProportion.js
 * @FileName: 出勤率
 */
import { Loading } from '@jiaminghi/data-view-react'
import { useQuery } from 'react-query'
import { isEmpty, isNil } from 'ramda'
import { Bar } from '@ant-design/plots'

import { fishPond } from 'service'

export default function Main({ areaPaths }) {
    const { data, isFetching, isError } = useQuery(
        ['fish-pond', 'statis', areaPaths],
        () => fishPond.statis({ areaPaths }),
        {
            enabled: !isEmpty(areaPaths) && !isNil(areaPaths),
        }
    )

    return (
        <div className='h-full flex flex-col'>
            <div className='h-full'>
                {isError ? (
                    <div className='w-full h-full flex items-center justify-center text-[#64e5ce] text-base'>
                        无法获取数据
                    </div>
                ) : isFetching ? (
                    <Loading />
                ) : (
                    <Bar
                        padding={55}
                        yField='fryType'
                        xField='fishPondNum'
                        seriesField='fryType'
                        data={data || []}
                        yAxis={{ label: { style: { fill: '#fff' } } }}
                        xAxis={{ label: { style: { fill: '#fff' } } }}
                        legend={false}
                        label={{ type: 'outer' }}
                    />
                )}
            </div>
        </div>
    )
}
