/*
 * @LastEditTime: 2022-09-19 08:46:59
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/Target/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Select, Table, Drawer, Form, Input, DatePicker, Result, Modal, message, Tag } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useMutation, useQueries } from 'react-query'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import { useDisclosure } from 'utils/useDisclosure'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'
import { fryType, target } from 'service'

const Wrap = tw.div`w-full h-full p-4 space-y-4`
const { Item } = Form

const taskTypeMap = {
    fish: '鱼类抽查',
    water: '水质抽查',
    storehouse: '仓库检查',
    log: '日志检查',
}

export default function TaskTarget() {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        code: StringParam,
        name: StringParam,
        taskType: StringParam,
        fryTypeId: NumberParam,
        publishedTimeFrom: StringParam,
        publishedTimeTo: StringParam,
        endTimeFrom: StringParam,
        endTimeTo: StringParam,
        sort: withDefault(ArrayParam, ['publishedTime,desc']),
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
    })
    const [current, setCurrent] = useState(null)

    const [{ data, isFetching, isError, refetch }, { data: fryTypes }] = useQueries([
        {
            queryKey: ['target', 'page', query],
            queryFn: () => target.page(query),
            placeholderData: { content: [], totalElements: 0 },
        },
        { queryKey: ['fry-type', 'list'], queryFn: () => fryType.list(), placeholderData: [] },
    ])

    const publishTarget = useMutation(target.publish, {
        onSuccess: () => {
            message.success('发布成功')
            refetch()
        },
    })

    const invalidTarget = useMutation(target.invalid, {
        onSuccess: () => {
            message.success('作废成功')
            refetch()
        },
    })

    const deleteTarget = useMutation(target.delete, {
        onSuccess: () => {
            message.success('删除成功')
            refetch()
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const onPublish = (id) => {
        Modal.confirm({
            title: '温馨提示',
            content: '发布后系统会自动派发任务到相关巡塘员。发布后目标只能作废不能删除，请知悉！',
            onOk: () => publishTarget.mutate(id),
        })
    }

    const onInvalid = (id) => {
        invalidTarget.mutate(id)
    }

    const onDelete = (id) => {
        deleteTarget.mutate(id)
    }

    const columns = [
        {
            title: '目标编号',
            dataIndex: 'code',
        },
        {
            title: '目标标题',
            dataIndex: 'name',
        },
        {
            title: '任务种类',
            key: 'taskType',
            render: (r) => taskTypeMap[r.taskType],
        },
        {
            title: '相关鱼种',
            dataIndex: 'fryTypeName',
        },
        {
            title: '任务截止时间',
            dataIndex: 'endTime',
        },
        {
            title: '发布人',
            dataIndex: 'publisherName',
        },
        {
            title: '发布时间',
            dataIndex: 'publishedTime',
        },
        {
            title: '状态',
            key: 'state',
            render: (r) =>
                r.state === 0 ? (
                    <Tag color='blue'>未发布</Tag>
                ) : r.state === 1 ? (
                    <Tag color='green'>已发布</Tag>
                ) : (
                    <Tag color='red'>已作废</Tag>
                ),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        详情
                    </Button>
                    {r.state === 0 && (
                        <>
                            <Button size='small' type='link' onClick={() => onPublish(r.id)}>
                                发布
                            </Button>
                            <ConfirmButton
                                buttonProps={{ size: 'small', type: 'link' }}
                                onConfirm={() => onDelete(r.id)}
                            />
                        </>
                    )}
                    {r.state === 1 && (
                        <>
                            <Button
                                type='link'
                                size='small'
                                onClick={() => navigate(`/task/list?taskTargetCode=${r.code}`)}
                            >
                                查看相关任务
                            </Button>
                            <ConfirmButton
                                buttonProps={{ size: 'small', type: 'link' }}
                                buttonText='作废'
                                tips='作废目标只会影响未处理的任务，已经完成的任务不会受到影响，确定要作废吗？'
                                onConfirm={() => onInvalid(r.id)}
                            />
                        </>
                    )}
                </Space>
            ),
        },
    ]
    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        publishedTimeFrom: value.time ? value.time[0].format('YYYY-MM-DD HH:mm:ss') : undefined,
                        publishedTimeTo: value.time ? value.time[1].format('YYYY-MM-DD HH:mm:ss') : undefined,
                        endTimeFrom: value.time1 ? value.time1[0].format('YYYY-MM-DD') : undefined,
                        endTimeTo: value.time1 ? value.time1[1].format('YYYY-MM-DD') : undefined,
                        time: undefined,
                        time1: undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    time:
                        query.publishedTimeFrom && query.publishedTimeTo
                            ? [dayjs(query.publishedTimeFrom), dayjs(query.publishedTimeTo)]
                            : undefined,
                    time1:
                        query.endTimeFrom && query.endTimeTo
                            ? [dayjs(query.endTimeFrom), dayjs(query.endTimeTo)]
                            : undefined,
                }}
            >
                <Item name='code' className='w-48'>
                    <Input placeholder='目标编号' allowClear />
                </Item>
                <Item name='name' className='w-48'>
                    <Input placeholder='目标名称' allowClear />
                </Item>
                <Item name='taskType' className='w-48'>
                    <Select
                        placeholder='任务种类'
                        allowClear
                        options={[
                            { label: '鱼类抽查', value: 'fish' },
                            { label: '水质抽查', value: 'water' },
                            { label: '仓库检查', value: 'storehouse' },
                            { label: '日志检查', value: 'log' },
                        ]}
                    />
                </Item>
                <Item name='fryTypeId' className='w-48'>
                    <Select
                        placeholder='鱼种'
                        allowClear
                        options={fryTypes?.map((x) => ({ label: x.fryType, value: x.id }))}
                    />
                </Item>
                <Item name='time' className='w-60'>
                    <DatePicker.RangePicker className='w-full' placeholder={['发布日期', '始止时间']} />
                </Item>
                <Item name='time1' className='w-60'>
                    <DatePicker.RangePicker className='w-full' placeholder={['截止日期', '始止时间']} />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            {isError ? (
                <Result status='error' subTitle='发生一些意外，可能是因为服务器出现了故障，请重试！' />
            ) : (
                <Table
                    rowKey='id'
                    dataSource={data.content}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                        total: data?.totalElements ?? 0,
                        pageSize: query.size,
                        current: query.page + 1,
                        showSizeChanger: false,
                        onChange: (page) => setQuery({ page: page - 1 }),
                    }}
                />
            )}

            <Drawer
                visible={isOpen}
                width='500px'
                title={isNil(current) ? '新建目标' : '目标详细'}
                onClose={onClose}
                destroyOnClose
                extra={
                    isNil(current) && (
                        <Button form='target-drawer-form' type='primary' htmlType='submit'>
                            提交
                        </Button>
                    )
                }
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
