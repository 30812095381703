import tw from 'twin.macro'

import { Button, Table, Form, Input, DatePicker, Select, Image, Tag } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { useQuery } from 'react-query'
import { all, keys } from 'ramda'
import { useNavigate } from 'react-router-dom'

import { testingRecord } from 'service'
import { Enscrypt } from 'utils/encrypt'
import dayjs from 'dayjs'

const { Item } = Form

const Wrap = tw.div`w-full h-full space-y-4 p-4`

const testingTypeMap = {
    symptom: '细菌、病毒检测',
    seeding: '药残检测(入苗检测)',
    emergence: '药残检测(出鱼检测)',
    spot: '药残检测(抽检监测)',
}

export default function FishType() {
    const navigate = useNavigate()
    const [query, setQuery] = useQueryParams({
        code: StringParam,
        testObject: StringParam,
        testingType: StringParam,
        pondCode: StringParam,
        testingOrganName: StringParam,
        haveResult: StringParam,
        shouYangStart: StringParam,
        shouYangEnd: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })
    const { data, isFetching } = useQuery(['testing-record', 'page', query], () => testingRecord.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const handleOpenDetail = (item) => {
        const id = Enscrypt(item.id)
        navigate(`/detection/record/detail/${id}`)
    }

    const columns = [
        {
            title: '检测单编号',
            dataIndex: 'code',
        },
        {
            title: '送检对象',
            dataIndex: 'testObject',
        },
        {
            title: '检测类型',
            key: 'testingType',
            render: (r) => testingTypeMap[r.testingType],
        },
        {
            title: '鱼塘号',
            dataIndex: 'pondCode',
        },
        {
            title: '样品名称',
            dataIndex: 'fryTypeName',
        },
        {
            title: '收样日期',
            dataIndex: 'shouYangTime',
        },
        {
            title: '检测单位',
            dataIndex: 'testingOrganName',
        },
        {
            title: '检测结果',
            key: 'haveResult',
            render: (r) => {
                if (r.haveResult) {
                    const { capabilityResult } = r.testingResultVO
                    const isPassed = all((x) => x.isStandard, capabilityResult)
                    return isPassed ? <Tag color='green'>合格</Tag> : <Tag color='red'>不合格</Tag>
                } else {
                    return <Tag>待上传</Tag>
                }
            },
        },
        {
            title: '发起时间',
            dataIndex: 'createTime',
        },
        {
            title: '二维码',
            key: 'qrcode',
            width: 120,
            render: (r) => <Image src={r.qrcode} style={{ width: '80px', height: '80px' }} />,
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Button type='link' size='small' onClick={() => handleOpenDetail(r)}>
                    查看详情
                </Button>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        page: 0,
                        shouYangStart: value.shouYangTime ? value.shouYangTime[0].format('YYYY-MM-DD') : undefined,
                        shouYangEnd: value.shouYangTime ? value.shouYangTime[1].format('YYYY-MM-DD') : undefined,
                    })
                }
                initialValues={{
                    ...query,
                    shouYangTime:
                        query.shouYangStart && query.shouYangEnd
                            ? [dayjs(query.shouYangStart), dayjs(query.shouYangEnd)]
                            : undefined,
                }}
            >
                <Item name='code'>
                    <Input placeholder='检测单编号' />
                </Item>
                <Item name='testObject'>
                    <Input placeholder='送检对象' />
                </Item>
                <Item name='pondCode'>
                    <Input placeholder='鱼塘号' />
                </Item>
                <Item name='testingType' className='w-48'>
                    <Select
                        allowClear
                        placeholder='检测类型'
                        options={keys(testingTypeMap).map((x) => ({ label: testingTypeMap[x], value: x }))}
                    />
                </Item>
                <Item name='testingOrganName'>
                    <Input placeholder='检测单位' />
                </Item>
                <Item name='haveResult' className='w-48'>
                    <Select
                        allowClear
                        placeholder='检测结果'
                        options={[
                            { value: true, label: '已上传' },
                            { value: false, label: '待上传' },
                        ]}
                    />
                </Item>
                <Item name='shouYangTime'>
                    <DatePicker.RangePicker placeholder={['收样日期', '始止时间']} />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                    showSizeChanger: false,
                }}
            />
        </Wrap>
    )
}
