/*
 * @LastEditTime: 2022-10-12 14:39:19
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\Certificate\Batch\Create.js
 */
import { Form, Select, DatePicker, Input, Radio, message } from 'antd'
import { isNil } from 'ramda'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDebounce } from 'react-use'

import { batchNo, fishPond, fryType, user } from 'service'

const { Item, useForm, useWatch } = Form

export default function Main({ onClose }) {
    const [form] = useForm()
    const fisherId = useWatch('fisherId', form)
    const [val, setVal] = useState('')
    const [debounce, setDebounce] = useState('')
    const queryClient = useQueryClient()

    useDebounce(() => setDebounce(val), 800, [val])

    const { data: options, isFetching } = useQuery(
        ['user', 'search', debounce],
        () => user.search({ kw: debounce, type: 'fisher' }),
        {
            placeholderData: [],
            enabled: !!debounce,
            select: (data) => data.map((x) => ({ label: `${x.name}(${x.mobile})`, value: x.id })),
        }
    )

    const { data: fishPonds } = useQuery(
        ['fish-pond', 'list', fisherId],
        () => fishPond.list({ actualFarmersId: fisherId }),
        {
            placeholderData: [],
            enabled: !!fisherId,
            select: (data) => data.map((x) => ({ label: x.pondName, value: x.id })),
        }
    )

    const { data: fryTypes } = useQuery(['fry-type', 'list'], () => fryType.list(), {
        placeholderData: [],
        select: (data) => data.map((x) => ({ label: x.fryType, value: x.id })),
    })

    const createMutation = useMutation(batchNo.create, {
        onSuccess: () => {
            message.success('新增成功')
            queryClient.invalidateQueries('batchNo')
            onClose()
        },
    })

    const onSubmit = (values) =>
        createMutation.mutate({
            ...values,
            planHarvestTime: values.planHarvestTime.format('YYYY-MM-DD'),
            seedlingsDate: values.seedlingsDate.format('YYYY-MM-DD'),
        })

    return (
        <Form layout='vertical' form={form} name='batch-no-form' onFinish={onSubmit}>
            <Item name='fisherId' label='养殖户' rules={[{ required: true, message: '必填' }]}>
                <Select
                    placeholder='请输入养殖户姓名或者电话号码'
                    showSearch
                    loading={isFetching}
                    optionFilterProp='label'
                    options={options}
                    onSearch={(value) => setVal(value)}
                    onSelect={(_, option) => form.setFieldValue('fisher', option.label)}
                />
            </Item>
            <Item name='fisher' hidden>
                <Input />
            </Item>
            <Item name='fishPondId' label='鱼塘' rules={[{ required: true, message: '必填' }]}>
                <Select disabled={isNil(fisherId)} options={fishPonds} />
            </Item>
            <Item name='fryTypeId' label='鱼种' rules={[{ required: true, message: '必填' }]}>
                <Select options={fryTypes} />
            </Item>
            <Item name='seedlingsDate' label='投苗时间' rules={[{ required: true, message: '必填' }]}>
                <DatePicker className='w-full' />
            </Item>
            <Item name='seedlingsNumber' label='投苗数量' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='斤' />
            </Item>
            <Item name='seedlingSpecifications' label='投苗规格' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='口斤' />
            </Item>
            <Item name='target' label='目标规格' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='斤' />
            </Item>
            <Item name='planHarvestTime' label='预计收成时间' rules={[{ required: true, message: '必填' }]}>
                <DatePicker className='w-full' />
            </Item>
            <Item name='marketPrice' label='目标市场价' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='元/斤' />
            </Item>
            <Item name='expired' label='状态' initialValue={false} rules={[{ required: true, message: '必填' }]}>
                <Radio.Group>
                    <Radio value={false}>可用</Radio>
                    <Radio value={true}>结束</Radio>
                </Radio.Group>
            </Item>
        </Form>
    )
}
