/*
 * @LastEditTime: 2022-07-22 11:36:09
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/utils/encrypt.js
 */
import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse('QAZPLMKIDG190090')
const iv = CryptoJS.enc.Utf8.parse('198821HSIALNSJCX')

// 解密方法
export function Decrypt(word) {
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word)
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
}

// 加密方法
export function Enscrypt(word) {
    let srcs = CryptoJS.enc.Utf8.parse(word)
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    return encrypted.ciphertext.toString().toUpperCase()
}
