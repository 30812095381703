/*
 * @LastEditTime: 2022-10-26 09:36:57
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Certificate/Record/InTest.js
 */
import { Descriptions, Divider, Empty } from 'antd'
import { isEmpty } from 'ramda'
import { useQueries } from 'react-query'

import { testingRecord } from 'service'

const { Item } = Descriptions

export default function InTest({ current }) {
    const [{ data }, { data: outData }] = useQueries([
        {
            queryKey: ['certificate-pond-record', current.batchNo],
            queryFn: () =>
                testingRecord.testingCertificateRrecord({ batchNo: current.batchNo, testingType: 'seeding' }),
            placeholderData: [],
        },
        {
            queryKey: ['certificate-id'],
            queryFn: () => testingRecord.one(current.testingRecordId),
            placeholderData: undefined,
        },
    ])

    return (
        <div>
            <Divider>入苗检测</Divider>
            {isEmpty(data) && <Empty />}
            {data &&
                data.map((item) => (
                    <Descriptions key={item.id} className='mt-4' column={2} bordered labelStyle={{ width: '200px' }}>
                        <Item label='检测编号'>{item.code}</Item>
                        <Item label='检测类型'>入苗检测</Item>
                        <Item label='检测单位'>{item.testingOrganName}</Item>
                        <Item label='检测日期'> {item?.testingResultVO?.testTime}</Item>
                        <Item label='样品名称'> {item?.fryTypeName}</Item>
                        <Item label='检测结论'>{item?.testingResultVO?.testConclusion}</Item>
                        <Item label='检测结果'>合格</Item>
                    </Descriptions>
                ))}
            <Divider>出鱼检测</Divider>
            {isEmpty(outData) && <Empty />}
            {outData && (
                <Descriptions column={2} bordered labelStyle={{ width: '200px' }}>
                    <Item label='检测编号'>{outData?.code}</Item>
                    <Item label='检测类型'>出鱼检测</Item>
                    <Item label='检测单位'>{outData?.testingOrganName}</Item>
                    <Item label='检测日期'>{outData?.testingResultVO?.testTime}</Item>
                    <Item label='检测项目'>
                        {outData.capabilities.map((e) => (
                            <div key={e.id}>
                                {e.name}
                                {e.affectPass ? '-(合格)' : '-(不合格)'}
                            </div>
                        ))}
                    </Item>

                    <Item label='检测结论'>{outData?.testingResultVO?.testConclusion}</Item>
                    <Item label='检测结果'>合格</Item>
                </Descriptions>
            )}
        </div>
    )
}
