/*
 * @LastEditTime: 2022-08-17 14:51:25
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics/Indentity.js
 */

export default function Main({ userInfo }) {
    return (
        <div className='w-full h-full flex flex-col items-center justify-center '>
            <div className='w-60 text-[#64e5ce] text-3xl text-right'>{userInfo.areaName}</div>
            <div className='w-60 text-[#daf4ef] text-xl text-right'>{userInfo.type}</div>
        </div>
    )
}
