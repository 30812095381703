/**
 * 编辑
 */

import { useState } from 'react'
import tw from 'twin.macro'
import { Button, Form, Input, Select, Upload, Space, Image, Spin, message, Divider } from 'antd'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { find, isEmpty, isNil, map } from 'ramda'
import { useMutation, useQueryClient, useQueries } from 'react-query'

import { FILE_URL } from 'utils/api'
import { useDisclosure } from 'utils/useDisclosure'

import { symptomKn, symptomCatalog, fryType } from 'service'

const { Item, useWatch, useForm } = Form
const { Option } = Select
const { TextArea } = Input
const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b sticky top-0 bg-white z-10`
const Content = tw.div`p-4 space-y-4`

export default function DrugUpdate({ current, onClose }) {
    const [form] = useForm()
    const imgLen = useWatch('attachmentVOList', form)

    const { isOpen: imgIsOpen, onOpen: imgOnOpen, onClose: imgOnClose } = useDisclosure()
    const [previewImg, setPreviewImg] = useState('')

    const queryClient = useQueryClient()

    const [{ data: options }, { data: fryTypes }, { data, isLoading }] = useQueries([
        {
            queryKey: ['symptom-catalog', 'list'],
            queryFn: () => symptomCatalog.list(),
            placeholderData: [],
            select: (data) => data.map((x) => ({ label: x.name, value: x.id })),
        },
        {
            queryKey: ['fry-type', 'list'],
            queryFn: () => fryType.list(),
            placeholder: [],
            select: (data) => data.map((x) => ({ label: x.fryType, value: x.id })),
        },
        {
            queryKey: ['symptom-kn', 'one', current],
            queryFn: () => symptomKn.one(current),
        },
    ])

    const updateMutation = useMutation(symptomKn.update, {
        onSuccess: () => {
            message.success('编辑成功')
            queryClient.invalidateQueries(['symptom-kn', 'page'])
            queryClient.invalidateQueries(['symptom-kn', 'one', current])
            onClose()
        },
    })

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const handlePreview = (file) => {
        setPreviewImg(file.response?.body.url || file.url)
        imgOnOpen()
    }

    const onSubmit = (values) => {
        updateMutation.mutate({
            ...data,
            ...values,
            treatPlan: undefined,
            treatType: undefined,
            fryTypeName: find((x) => x.value === values.fryTypeId, fryTypes).label,
            treatVO: {
                ...data.treatVO,
                treatPlan: values.treatPlan,
                treatType: values.treatType,
            },
            attachmentVOList: map((item) => (item.response ? item.response.body : item), values.attachmentVOList),
        })
    }

    if (isLoading) {
        return <Spin />
    }

    return (
        <Wrap>
            <Header>
                <Space>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={onClose} />
                    <span className='text-xl font-bold'>编辑病例</span>
                </Space>
                <Space>
                    <Button
                        loading={updateMutation.isLoading}
                        form='update-illness-form'
                        htmlType='submit'
                        type='primary'
                    >
                        提交
                    </Button>
                </Space>
            </Header>
            <Content>
                <Form
                    form={form}
                    name='update-illness-form'
                    onFinish={onSubmit}
                    autoComplete='off'
                    layout='vertical'
                    initialValues={{
                        ...data,
                        treatPlan: data.treatVO?.treatPlan,
                        treatType: data.treatVO?.treatType,
                    }}
                >
                    <Divider>病例信息</Divider>
                    <Item label='病例名称' name='name' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item name='code' label='病例编号' rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder='自动添加' readOnly />
                    </Item>

                    <Item label='鱼病分类' name='catalogId' rules={[{ required: true, message: '必填' }]}>
                        <Select allowClear placeholder='病例分类' options={options} />
                    </Item>
                    <Item label='患病鱼种' name='fryTypeId' rules={[{ required: true, message: '必填' }]}>
                        <Select allowClear placeholder='患病鱼种' options={fryTypes} />
                    </Item>
                    <Item
                        label='图片'
                        name='attachmentVOList'
                        getValueFromEvent={normFile}
                        valuePropName='fileList'
                        rules={[{ required: true, message: '必填' }]}
                        help='建议尺寸120*90像素，大小不要超过1M，最多6张'
                    >
                        <Upload
                            onPreview={handlePreview}
                            action={FILE_URL}
                            accept='image/*'
                            headers={{
                                auth: localStorage.getItem('Authorization'),
                            }}
                            name='file'
                            listType='picture-card'
                            data={{ bucketName: 'img' }}
                        >
                            {(isNil(imgLen) || isEmpty(imgLen) || imgLen.length < 6) && (
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            )}
                        </Upload>
                    </Item>
                    <Item label='病情描述' name='mainSymptom' rules={[{ required: true, message: '必填' }]}>
                        <TextArea rows={4} />
                    </Item>
                    <Divider>治疗方案</Divider>
                    <Item label='治疗方案' name='treatType'>
                        <Select placeholder='请填写'>
                            <Option value='t1'>消毒+治疗</Option>
                            <Option value='t2'>消毒+治疗+补充营养</Option>
                            <Option value='t3'>消毒+治疗+补充营养+隔离</Option>
                        </Select>
                    </Item>
                    <Item label='添加治疗方案' name='treatPlan'>
                        <TextArea rows={4} />
                    </Item>
                </Form>
            </Content>
            <Image
                width={200}
                style={{
                    display: 'none',
                }}
                preview={{
                    visible: imgIsOpen,
                    src: previewImg,
                    onVisibleChange: imgOnClose,
                }}
            />
        </Wrap>
    )
}
