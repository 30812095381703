import tw from 'twin.macro'
import { useState } from 'react'
import { Popover, Image, Button, Space, Table, message, Form, Input, DatePicker, Select, Drawer } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDisclosure } from 'utils/useDisclosure'
import { testingOrgan } from 'service'
import { findLast, isNil, propEq } from 'ramda'

import Create from './Create'
import Update from './Update'
import TestingOrganInfo from './TestingOrganInfo/index'
import ConfirmButton from 'components/ConfirmButton'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import axios from 'axios'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form
const { RangePicker } = DatePicker

const statusOptions = [
    { label: '启用', value: 'enable' },
    { label: '禁用', value: 'disable' },
]

const businessOptions = [
    { label: '在业', value: 'industry' },
    { label: '暂停营业', value: 'suspend_industry' },
    { label: '停业', value: 'close_down' },
]

const Publish = ({ current, onClose }) => {
    return <TestingOrganInfo current={current} onClose={onClose} />
}

export default function TestingOrgan() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenInfo, onOpen: onOpenInfo, onClose: onCloseInfo } = useDisclosure()
    const [qrcode, setqrcode] = useState(undefined)

    const [query, setQuery] = useQueryParams({
        name: StringParam,
        code: StringParam,
        businessStatus: StringParam,
        state: StringParam,
        createTimeFrom: StringParam,
        createTimeTo: StringParam,
        time: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(ArrayParam, ['createTime,desc']),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const { data, isFetching } = useQuery(['testing-organ', 'page', query], () => testingOrgan.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const deleteMutation = useMutation(testingOrgan.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries('medicine')
            message.success('删除成功')
        },
    })
    const qrCodeMutation = useMutation(testingOrgan.qrCode, {
        onSuccess: (data) => {
            setqrcode(data)
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const handleManager = (item) => {
        setCurrent(item)
        onOpenInfo()
    }

    const handleDownload = async (id, name) => {
        console.log(id)
        //下载url
        const { data } = await axios.get(`/api/pharmacy/testing-organ/${id}/qr`, { responseType: 'blob' })
        saveAs(data, `${name}`, { type: data.type })
    }

    const columns = [
        {
            title: '机构编号',
            dataIndex: 'code',
        },
        {
            title: '机构名称',
            dataIndex: 'name',
        },
        {
            title: '机构评级',
            dataIndex: 'score',
        },
        {
            title: '经营状态',
            key: 'prescription',
            render: (r) => findLast(propEq('value', r.businessStatus))(businessOptions)?.label ?? '',
            // render: (r) => '',
        },
        {
            title: '机构负责人',
            dataIndex: 'nameInCharge',
        },
        {
            title: '机构电话',
            dataIndex: 'phoneInCharge',
        },
        {
            title: '状态',
            key: 'switch',
            width: 60,
            align: 'center',
            render: (r) => <Space>{r.state ? '启用' : '禁用'}</Space>,
        },
        {
            title: '二维码',
            align: 'center',
            render: (item) => (
                <Space>
                    <Popover content={<Image src={item.qrcode ?? qrcode} preview={false} />} trigger='click'>
                        <Button
                            size='small'
                            type='link'
                            onClick={() => {
                                if (isNil(item.qrCode)) {
                                    qrCodeMutation.mutate(item.id)
                                }
                            }}
                        >
                            查看
                        </Button>
                    </Popover>
                    <Button size='small' type='link' onClick={() => handleDownload(item.id, item.name)}>
                        下载
                    </Button>
                </Space>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                    <Button type='link' size='small' onClick={() => handleManager(r)}>
                        管理账号
                    </Button>
                </Space>
            ),
        },
    ]

    return isOpenInfo ? (
        <Publish current={current} onClose={onCloseInfo} />
    ) : (
        <Wrap>
            <Form
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        createTimeFrom: value.time ? value.time[0]?.format('YYYY-MM-DD hh:mm:ss') : undefined,
                        createTimeTo: value.time ? value.time[1]?.format('YYYY-MM-DD hh:mm:ss') : undefined,
                        time: undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    time:
                        query.createTimeFrom && query.createTimeTo
                            ? [dayjs(query.createTimeFrom), dayjs(query.createTimeTo)]
                            : undefined,
                }}
                className='gap-2'
            >
                <Item name='name' className='w-48'>
                    <Input placeholder='机构名称' />
                </Item>
                <Item name='code' className='w-48'>
                    <Input placeholder='机构编号' />
                </Item>
                <Item name='businessStatus' className='w-48'>
                    <Select allowClear placeholder='经营状态' options={businessOptions} />
                </Item>
                <Item name='state' className='w-48'>
                    <Select allowClear placeholder='机构状态' options={statusOptions} />
                </Item>
                <Form.Item name='time'>
                    <RangePicker />
                </Form.Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '添加检测机构' : '修改检测机构'}
                onClose={onClose}
                extra={
                    <Button form='testingOrgan-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
