/*
 * @LastEditTime: 2022-10-17 19:10:41
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\Task\TaskStoreList\index.js
 */
import tw from 'twin.macro'
import { Button, Space, Select, Table, Form, Input, DatePicker, Result, Tag, Drawer } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { keys } from 'ramda'

import { pharmacyTask } from 'service'
import { Enscrypt } from 'utils/encrypt'
import { useDisclosure } from 'utils/useDisclosure'

import TaskSettings from './TaskSettings'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

const taskTypeMap = {
    plan: '计划任务',
    rectification: '整改任务',
    unplanned: '非计划任务',
}

export default function TaskList() {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        code: StringParam,
        state: NumberParam,
        overdue: StringParam,
        taskType: StringParam,
        patrollerName: StringParam,
        endTimeFrom: StringParam,
        endTimeTo: StringParam,
        pharmacyName: StringParam,
        taskTargetCode: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
        // sort: withDefault(StringParam, 'completeTime,desc'),
    })

    const { data, isFetching, isError } = useQuery(['patroller-task', 'page', query], () => pharmacyTask.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const handleOpenDetail = (item) => {
        const id = Enscrypt(item.id)
        navigate(`/pharmacy-task/detail/${id}`)
    }

    const columns = [
        {
            title: '任务编号',
            dataIndex: 'code',
        },
        {
            title: '任务名称',
            dataIndex: 'name',
        },
        {
            title: '类型',
            key: 'taskType',
            render: (r) => taskTypeMap[r.taskType],
        },
        {
            title: '任务药店',
            dataIndex: 'pharmacyName',
        },
        {
            title: '巡店员',
            dataIndex: 'patrollerName',
        },
        {
            title: '任务截止日期',
            dataIndex: 'endTime',
        },
        {
            title: '完成情况',
            key: 'state',
            render: (r) => <Tag color={r.state === 1 ? 'blue' : 'green'}>{r.state === 1 ? '待完成' : '已完成'}</Tag>,
        },
        {
            title: '是否超期',
            key: 'overdue',
            render: (r) => (
                <span className={r.overdue ? 'text-red-500' : 'text-blue-500'}>{r.overdue ? '超期' : '未超期'}</span>
            ),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleOpenDetail(r)}>
                        详情
                    </Button>
                    {/* <ConfirmButton buttonProps={{ size: 'small', type: 'link' }} /> */}
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        endTimeFrom: value.time ? value.time[0].format('YYYY-MM-DD') : undefined,
                        endTimeTo: value.time ? value.time[1].format('YYYY-MM-DD') : undefined,
                        time: undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    time: query.endTimeFrom && query.endTimeTo && [dayjs(query.endTimeFrom), dayjs(query.endTimeto)],
                }}
            >
                <Item name='code' className='w-48'>
                    <Input placeholder='任务编号' allowClear />
                </Item>
                <Item name='state' className='w-48'>
                    <Select
                        placeholder='任务状态'
                        allowClear
                        options={[
                            { label: '待完成', value: 1 },
                            { label: '已完成', value: 2 },
                        ]}
                    />
                </Item>
                <Item name='overdue' className='w-48'>
                    <Select
                        placeholder='是否超期'
                        allowClear
                        options={[
                            { label: '超期', value: true },
                            { label: '未超期', value: false },
                        ]}
                    />
                </Item>
                <Item name='pharmacyName' className='w-48'>
                    <Input placeholder='任务药店' allowClear />
                </Item>
                <Item name='taskType' className='w-48'>
                    <Select
                        placeholder='任务类型'
                        allowClear
                        options={keys(taskTypeMap).map((x) => ({ label: taskTypeMap[x], value: x }))}
                    />
                </Item>
                <Item name='patrollerName' className='w-48'>
                    <Input placeholder='执行人员' allowClear />
                </Item>
                <Item name='taskTargetCode' className='w-48'>
                    <Input placeholder='任务目标编号' allowClear />
                </Item>
                <Item name='time' className='w-60'>
                    <DatePicker.RangePicker className='w-full' placeholder={['任务截止', '始止时间']} />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                    <Button type='primary' className='ml-3' onClick={onOpen}>
                        任务设置
                    </Button>
                </Item>
            </Form>
            {isError ? (
                <Result status='error' subTitle='发生一些意外，可能是因为服务器出现了故障，请重试！' />
            ) : (
                <Table
                    rowKey='id'
                    dataSource={data.content}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                        total: data?.totalElements ?? 0,
                        pageSize: query.size,
                        current: query.page + 1,
                        showSizeChanger: false,
                        onChange: (page) => setQuery({ page: page - 1 }),
                    }}
                />
            )}

            <Drawer
                width={520}
                visible={isOpen}
                title='任务设置'
                onClose={onClose}
                extra={
                    <Button form='task-set-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                <TaskSettings onClose={onClose} />
            </Drawer>
        </Wrap>
    )
}
