/*
 * @LastEditTime: 2022-08-30 17:18:42
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/BasicData/FishType/Update.js
 */
import tw from 'twin.macro'
import { Form, Input, InputNumber } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { fryType } from 'service'
import { mergeLeft } from 'ramda'

const { Item, useForm } = Form
const Wrap = tw.div`w-full h-full relative space-y-4`

export default function FishTypeUpdate({ current, onClose }) {
    const [form] = useForm()

    const queryClient = useQueryClient()

    const createMutation = useMutation(fryType.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('fish-type')
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate({ ...mergeLeft({ ...values }, { ...current }), id: current.id })
    }

    return (
        <>
            <Wrap>
                <Form
                    form={form}
                    className='max-w-[500px]'
                    name='fish-type-form'
                    onFinish={onSubmit}
                    autoComplete='off'
                    initialValues={current}
                    layout='vertical'
                >
                    <Item label='鱼种名称' name='fryType' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item
                        className='max-w-[500px]'
                        label='养殖周期（天）'
                        name='breedingCycle'
                        rules={[{ required: true, message: '必填' }]}
                    >
                        <InputNumber min={1} max={1000} className='!w-full' />
                    </Item>
                    <Item
                        className='max-w-[500px]'
                        label='巡塘周期（天）'
                        name='inspectCycle'
                        rules={[{ required: true, message: '必填' }]}
                    >
                        <InputNumber min={1} max={1000} className='!w-full' />
                    </Item>
                </Form>
            </Wrap>
        </>
    )
}
