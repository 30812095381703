/*
 * @LastEditTime: 2022-09-27 15:44:30
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\FullStatistics2\Patrol\PharmacyList.js
 */
import { styled } from 'twin.macro'
import { TreeSelect } from 'antd'

const SelectWrap = styled.div`
    .ant-select-selector {
        background-color: rgba(4, 27, 65, 0.7) !important;
        border: 1px solid #05cde7 !important;
        color: #05cde7;
    }
    .ant-select-arrow {
        color: #05cde7;
    }
`

export default function Main({ data, options, onPharmacyClick, onSelect }) {
    return (
        <div className='w-full h-full overflow-y-auto space-y-2'>
            <SelectWrap>
                <TreeSelect
                    className='w-48'
                    placeholder='选择镇区'
                    treeData={options}
                    onChange={(value) => onSelect(value)}
                    allowClear
                    getPopupContainer={(e) => e.parentNode}
                />
            </SelectWrap>
            <div className='grid grid-cols-2 gap-2'>
                {data?.map((item) => (
                    <div
                        key={item.id}
                        className='border border-transparent hover:border-[#17cee8] hover:bg-[rgba(4,26,64,0.7)] transition-all p-2 cursor-pointer'
                        onClick={() => onPharmacyClick(item)}
                    >
                        <div className='text-white'>{item.name}</div>
                        <div className='text-[#17cee8]'>{item.address}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
