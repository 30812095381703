/*
 * @LastEditTime: 2022-08-18 15:50:43
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/BasicData/TestingCapability/Update.js
 */
import { Form, Input, Radio } from 'antd'
import { mergeLeft } from 'ramda'
import { useMutation, useQueryClient } from 'react-query'

import { testingCap } from 'service'

const { Item } = Form

export default function FishTypeCreate({ current, onClose }) {
    const queryClient = useQueryClient()

    const updateMutation = useMutation(testingCap.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('testing-capability')
            onClose()
        },
    })

    const onSubmit = (values) => {
        updateMutation.mutate({ ...mergeLeft({ ...values }, { ...current }) })
    }

    return (
        <Form
            name='testing-capability-form'
            onFinish={onSubmit}
            autoComplete='off'
            layout='vertical'
            initialValues={current}
        >
            <Item hidden name='id'>
                <Input />
            </Item>
            <Item label='检测能力名称' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='残留限值' name='value' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='是否影响检测单及格' name='affectPass' rules={[{ required: true, message: '必填' }]}>
                <Radio.Group>
                    <Radio.Button value={true}>是</Radio.Button>
                    <Radio.Button value={false}>否</Radio.Button>
                </Radio.Group>
            </Item>
        </Form>
    )
}
