/*
 * @LastEditTime: 2022-09-20 10:23:40
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/PondUnplannedRate.js
 * @FileName: 出勤率
 */
import { Loading } from '@jiaminghi/data-view-react'
import { useQuery } from 'react-query'
import { isNil } from 'ramda'
import { Line } from '@ant-design/plots'
import dayjs from 'dayjs'

import { task } from 'service'

export default function Main({ pondId }) {
    const { data, isFetching, isError } = useQuery(
        ['full-statistics', 'unplanPond', pondId],
        () =>
            task.unplanPond({
                pondId,
                period: [dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
            }),
        {
            select: (data) => data.map((x) => ({ day: x.day, rate: (x.finished / x.total) * 100 })),
            enabled: !!pondId,
            staleTime: 0,
        }
    )

    return (
        <div className='h-full flex flex-col'>
            <div className='h-full'>
                {isNil(pondId) ? (
                    <div className='text-white h-full fond-bold flex items-center justify-center text-base'>
                        请先选择鱼塘
                    </div>
                ) : isError ? (
                    <div className='w-full h-full flex items-center justify-center text-[#64e5ce] text-base'>
                        无法获取数据
                    </div>
                ) : isFetching ? (
                    <Loading />
                ) : (
                    <Line
                        padding={50}
                        xField='day'
                        yField='rate'
                        data={data || []}
                        label={{ formatter: (text) => `${text.rate.toFixed(2)}%`, style: { fill: '#fff' } }}
                        yAxis={{ label: { style: { fill: '#fff' } } }}
                        xAxis={{ type: 'time', label: { style: { fill: '#fff' } } }}
                        tooltip={{
                            formatter: (datum) => ({
                                name: '非计划任务完成率',
                                value: `${datum.rate.toFixed(2)}%`,
                            }),
                        }}
                    />
                )}
            </div>
        </div>
    )
}
