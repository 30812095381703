/*
 * @LastEditTime: 2022-08-03 17:14:24
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/List/Picture.js
 */
import { Descriptions, Image } from 'antd'

const { Item } = Descriptions

export default function Main({ punchTime, location, attachmentsVOList }) {
    return (
        <Descriptions bordered column={2} labelStyle={{ width: '300px' }}>
            <Item label='打卡时间'>{punchTime}</Item>
            <Item label='打卡地点'>{location}</Item>
            <Item label='任务照片'>
                {attachmentsVOList &&
                    attachmentsVOList.map((item) => <Image key={item.key} width={150} src={item.url} />)}
            </Item>
        </Descriptions>
    )
}
