/*
 * @LastEditTime: 2022-08-30 17:22:51
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/ShopInspector/index.js
 */
import tw from 'twin.macro'

import FishShop from './FishShop'
import ShopInspector from './ShopInspector'

const Wrap = tw.div`flex flex-col p-4 space-y-4`

export default function PondInspector() {
    return (
        <Wrap>
            <ShopInspector />
            <FishShop />
        </Wrap>
    )
}
