/*
 * @LastEditTime: 2022-08-30 08:51:41
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/Drugs/StockStatis.js
 */
import { Column } from '@ant-design/plots'
import { Card, Empty, Form, TreeSelect, Spin } from 'antd'

import { useState } from 'react'
import { useQueries } from 'react-query'

import { medicine, area } from 'service'
import { isEmpty } from 'ramda'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function StockStatis() {
    const [areaPaths, setAreaPaths] = useState(null)

    const [{ data: areaTree }, { data, isFetching }] = useQueries([
        {
            queryKey: ['area-tree', 'street'],
            queryFn: () => area.tree({ level: 'street' }),
            placeholderData: [],
        },
        {
            queryKey: ['medicine-statis-catalog', areaPaths],
            queryFn: () => medicine.statisByCataLog({ areaPaths }),
            placeholderData: [],
            enabled: !!areaPaths,
            select: (data) =>
                data.map((x) => ({
                    label: x.catalogName,
                    value: x.stock,
                })),
        },
    ])

    return (
        <Card title='药品库存合计' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={isFetching}>
                <Form autoComplete='off' layout='inline' className='justify-end'>
                    <Item className='w-60'>
                        <TreeSelect
                            allowClear
                            placeholder='区域'
                            onChange={(value) => setAreaPaths(value)}
                            treeNodeFilterProp='filter'
                            showSearch
                        >
                            {renderTreeNode(areaTree, 'street')}
                        </TreeSelect>
                    </Item>
                </Form>
                {isEmpty(data) ? (
                    <Empty className='h-60 flex flex-col items-center justify-center' />
                ) : (
                    <Column data={data} xField='label' yField='value' />
                )}
            </Spin>
        </Card>
    )
}
