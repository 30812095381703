import { Line } from '@ant-design/plots'
import { Form, DatePicker, TreeSelect, Select, Empty, Card, Spin } from 'antd'
import { useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { useQueries } from 'react-query'
import dayjs from 'dayjs'

import { pharmacyTask, user, area } from 'service'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form
const { RangePicker } = DatePicker

export default function ShopInspector() {
    const [query, setQuery] = useState({
        period: [dayjs().subtract(30, 'day'), dayjs()],
        areaPaths: '',
        patrollerId: '',
    })

    const [{ data: areaTree }, chuqinlv, patroller] = useQueries([
        {
            queryKey: ['area-tree', 'street'],
            queryFn: () => area.tree({ level: 'street' }),
            placeholderData: [],
        },
        {
            queryKey: ['pharmacyTask', query],
            queryFn: () => pharmacyTask.chuqinlv({ ...query, period: query.period.map((x) => x.format('YYYY-MM-DD')) }),
            placeholderData: [],
            keepPreviousData: true,
            enabled: !!query.period,
            select: (data) =>
                data.map((x) => ({
                    day: x.day,
                    scales: x.total > 0 ? Number(((x.completedInTime / x.total) * 100).toFixed(1)) : 0,
                })),
        },
        {
            queryKey: ['patroller', 'list', query.areaPaths],
            queryFn: () => user.getAreaUser('patroller', query.areaPaths),
            enabled: !!query.areaPaths,
        },
    ])

    const config = useMemo(
        () => ({
            data: chuqinlv.data,
            padding: 'auto',
            xField: 'day',
            yField: 'scales',
            yAxis: {
                min: 0,
                max: 100,
            },
            label: {},
            point: {
                size: 5,
                shape: 'diamond',
                style: {
                    fill: 'white',
                    stroke: '#5B8FF9',
                    lineWidth: 2,
                },
            },
            tooltip: {
                showMarkers: false,
            },
            interactions: [
                {
                    type: 'marker-active',
                },
            ],
            state: {
                active: {
                    style: {
                        shadowBlur: 4,
                        stroke: '#000',
                        fill: 'red',
                    },
                },
            },
        }),
        [chuqinlv]
    )

    return (
        <Card title='巡店平均出勤率' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={chuqinlv.isFetching}>
                <Form autoComplete='off' layout='inline' className='gap-2 justify-end'>
                    <Form.Item>
                        <RangePicker
                            value={query.period}
                            onChange={(dates) => setQuery({ ...query, period: dates })}
                            allowClear={false}
                        />
                    </Form.Item>
                    <Item className='w-48'>
                        <TreeSelect
                            allowClear
                            placeholder='镇区'
                            onChange={(areaPaths) => setQuery({ ...query, areaPaths })}
                            showSearch
                            treeNodeFilterProp='filter'
                        >
                            {renderTreeNode(areaTree, 'street')}
                        </TreeSelect>
                    </Item>
                    <Item className='w-36'>
                        <Select
                            loading={patroller.isFetching}
                            fieldNames={{ label: 'name', value: 'id' }}
                            placeholder='巡店员'
                            allowClear
                            options={patroller.data}
                            onChange={(patrollerId) => setQuery({ ...query, patrollerId })}
                            onClear={() => setQuery({ ...query, patrollerId: undefined })}
                        />
                    </Item>
                </Form>
                {isEmpty(chuqinlv) ? (
                    <Empty className='h-60 flex flex-col items-center justify-center' />
                ) : (
                    <Line {...config} />
                )}
            </Spin>
        </Card>
    )
}
