/*
 * @LastEditTime: 2022-08-30 15:53:16
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/FishIll/Case.js
 */
import { Card, Form, Select, Radio, Empty, Spin } from 'antd'
import { useState, useMemo } from 'react'
import { useQueries } from 'react-query'
import { isEmpty, isNil } from 'ramda'
import { Bar } from '@ant-design/plots'

import { fryType, symptom } from 'service'

const { Item } = Form

export default function Case() {
    const [query, setQuery] = useState({
        fryTypeId: undefined,
        period: 'fifteen',
    })

    const [options, symptomData] = useQueries([
        {
            queryKey: ['fry-type', 'list'],
            queryFn: () => fryType.list(),
            placeholderData: [],
        },
        {
            queryKey: ['symptom-statis', query],
            queryFn: () => symptom.statisByFish(query),
            placeholderData: [],
            keepPreviousData: true,
            enabled: !isNil(query.period) && !isEmpty(query.period),
            select: (data) =>
                data.map((x) => ({
                    label: x.period,
                    总数: x.count,
                })),
        },
    ])

    const config = useMemo(
        () => ({
            data: symptomData.data,
            xField: '总数',
            yField: 'label',
            minBarWidth: 20,
            maxBarWidth: 20,
        }),
        [symptomData.data]
    )

    return (
        <Card title='鱼种病例统计' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={symptomData.isLoading}>
                <Form autoComplete='off' layout='inline' className='gap-2 justify-end'>
                    <Item className='w-48'>
                        <Select
                            value={query.fryTypeId}
                            fieldNames={{ label: 'fryType', value: 'id' }}
                            placeholder='全部鱼种'
                            allowClear
                            options={options.data}
                            onChange={(value) => setQuery({ ...query, fryTypeId: value })}
                        />
                    </Item>
                    <Item>
                        <Radio.Group
                            value={query.period}
                            onChange={({ target }) => setQuery({ ...query, period: target.value })}
                        >
                            <Radio.Button value='fifteen'>近15天</Radio.Button>
                            <Radio.Button value='thirty'>近30天</Radio.Button>
                            <Radio.Button value='bymonth'>近一年</Radio.Button>
                        </Radio.Group>
                    </Item>
                </Form>
                {isEmpty(symptomData.data) ? (
                    <Empty className='h-60 flex flex-col items-center justify-center' />
                ) : (
                    <Bar {...config} />
                )}
            </Spin>
        </Card>
    )
}
