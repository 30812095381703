/*
 * @LastEditTime: 2022-09-15 16:30:36
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/User/Fisher/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { DatePicker, Button, Space, Table, Drawer, Form, Input, message, Tag, Select, Modal } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { isNil, isEmpty } from 'ramda'
import { useMutation, useQuery } from 'react-query'
import { user } from 'service'
import dayjs from 'dayjs'

import { useDisclosure } from 'utils/useDisclosure'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'
import PondList from './PondList'

const { Item, useForm } = Form
const { RangePicker } = DatePicker

const Wrap = tw.div`w-full h-full space-y-4 p-4`

export default function PatrolMain() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: modalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

    const [form] = useForm()

    const [query, setQuery] = useQueryParams({
        name: StringParam,
        mobile: StringParam,
        onWork: StringParam,
        enabled: StringParam,
        period: ArrayParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        type: withDefault(StringParam, 'fisher'),
        sort: withDefault(ArrayParam, ['createdTime,desc']),
    })
    const [current, setCurrent] = useState(null)

    const { data, isFetching, refetch } = useQuery(['fisher', 'page', query], () => user.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const deleteMutation = useMutation(user.delete, {
        onSuccess: () => {
            refetch()
            message.success('删除成功')
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const handlePond = (item) => {
        setCurrent(item)
        onModalOpen()
    }

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '手机号码',
            dataIndex: 'mobile',
        },
        {
            title: '账号状态',
            key: 'enabled',
            render: (r) => (r.enabled ? <Tag color='success'>可用</Tag> : <Tag color='error'>禁用</Tag>),
        },
        {
            title: '创建人',
            dataIndex: 'creater',
        },
        {
            title: '创建时间',
            dataIndex: 'createdTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <Button type='link' size='small' onClick={() => handlePond(r)}>
                        查看名下鱼塘
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link' }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]
    return (
        <Wrap>
            <Form
                form={form}
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        period: !isEmpty(value.period)
                            ? [value.period[0].format('YYYY-MM-DD'), value.period[1].format('YYYY-MM-DD')]
                            : undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    period: query.period ? [dayjs(query.period[0]), dayjs(query.period[1])] : [],
                }}
            >
                <Item name='name'>
                    <Input placeholder='养殖户名称' />
                </Item>
                <Item name='mobile'>
                    <Input placeholder='手机号码' />
                </Item>
                <Item name='enabled' className='w-48'>
                    <Select
                        placeholder='账号状态'
                        allowClear
                        options={[
                            { label: '可用', value: 'true' },
                            { label: '禁用', value: 'false' },
                        ]}
                    />
                </Item>
                <Form.Item name='period'>
                    <RangePicker placeholder={['创建时间', '时间范围']} />
                </Form.Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                    showSizeChanger: false,
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建养殖户' : '养殖户详情'}
                onClose={onClose}
                extra={
                    <Button form='patrol-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
            <Modal
                width='1200px'
                title='鱼塘信息'
                visible={modalOpen}
                onCancel={onModalClose}
                destroyOnClose
                footer={null}
            >
                <PondList actualFarmersId={current?.id} />
            </Modal>
        </Wrap>
    )
}
