/*
 * @LastEditTime: 2022-09-22 10:19:26
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/System/Account/Create.js
 */
import { Form, Input, Select, TreeSelect, Switch } from 'antd'
import { isEmpty } from 'ramda'
import { useMutation, useQueries, useQueryClient } from 'react-query'

import { account, role, area } from 'service'

const { Item, useForm } = Form

export default function AccountCreate({ appid, onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()

    const [{ data }, { data: treeData }] = useQueries([
        {
            queryKey: ['role', 'list', appid],
            queryFn: () => role.list({ appid }),
            placeholderData: [],
        },
        {
            queryKey: ['area-tree'],
            queryFn: () => area.tree(),
            placeholderData: [],
        },
    ])

    const handleSelectChange = (_, values) => {
        return isEmpty(values) ? [] : values.map((x) => x.children.props.value)
    }

    const createMutation = useMutation(account.create, {
        onSuccess: () => {
            queryClient.invalidateQueries('account')
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate({ ...values, pwd: '123456' })
    }

    return (
        <Form form={form} name='account-drawer-form' onFinish={onSubmit} layout='vertical' autoComplete='off'>
            <Item hidden label='是否可用' name='enabled' initialValue={true}>
                <Input />
            </Item>
            <Item label='帐号' name='account' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item
                label='手机号码'
                name='mobile'
                rules={[
                    { required: true, message: '请输入' },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                    },
                ]}
                validateTrigger='onBlur'
            >
                <Input maxLength={11} />
            </Item>
            <Item label='姓名' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item
                label='角色'
                name='roleVOList'
                rules={[{ required: true, message: '必填' }]}
                getValueFromEvent={handleSelectChange}
            >
                <Select mode='multiple'>
                    {data.map((x) => (
                        <Select.Option key={x.id} value={x.id}>
                            <div value={{ id: x.id, value: x.id, name: x.name }}>{x.name}</div>
                        </Select.Option>
                    ))}
                </Select>
            </Item>
            <Item label='所属区域组织' name='areaId' rules={[{ required: true, message: '请选择' }]}>
                <TreeSelect treeData={treeData} fieldNames={{ value: 'id' }} />
            </Item>
            <Item label='areaName' hidden name='areaName'>
                <Input />
            </Item>
            <Item label='areaPaths' hidden name='areaPaths'>
                <Input />
            </Item>
            <Item
                label='账号状态'
                name='enabled'
                valuePropName='checked'
                rules={[{ required: true, message: '请输入选择类型' }]}
            >
                <Switch />
            </Item>
        </Form>
    )
}
