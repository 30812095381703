/*
 * @LastEditTime: 2022-07-22 09:18:46
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Drugs/Type/Create.js
 */

import { Form, Input, Select, Upload, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useMutation, useQueryClient, useQueries } from 'react-query'
import { isEmpty, isNil } from 'ramda'

import { medicine, medicineCatalog } from 'service'
import { FILE_URL } from 'utils/api'

const { Item, useWatch, useForm } = Form

export default function InformationCreate({ onClose }) {
    const [form] = useForm()
    const imgLen = useWatch('url', form)
    const imgLen1 = useWatch('url1', form)
    const imgLen2 = useWatch('url2', form)

    const queryClient = useQueryClient()

    const [{ data: options }] = useQueries([
        {
            queryKey: ['medicine-type', 'list'],
            queryFn: () => medicineCatalog.list(),
            placeholderData: [],
            select: (data) => data.map((x) => ({ label: x.name, value: x.id })),
        },
    ])

    const createMutation = useMutation(medicine.create, {
        onSuccess: () => {
            queryClient.invalidateQueries('medicine')
            onClose()
        },
    })

    const onSubmit = (values) => {
        //createMutation.mutate(values)
        if (values.url) {
            values.attachmentsVOList = values.url.map((x) => ({
                name: x.response.body.name,
                url: x.response.body.url,
            }))
            values.url = null
        }
        if (values.url1) {
            values.bannersVOList = values.url1.map((x) => ({
                name: x.response.body.name,
                url: x.response.body.url,
            }))
            values.url1 = null
        }
        if (values.url2) {
            values.manualsVOList = values.url2.map((x) => ({
                name: x.response.body.name,
                url: x.response.body.url,
            }))
            values.url2 = null
        }
        createMutation.mutate({ ...values })
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    return (
        <Form name='medicine-drawer-form' form={form} onFinish={onSubmit} autoComplete='off' layout='vertical'>
            {/* <Item label='药品编号' name='code' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item> */}
            <Item label='条形编码' name='barCoding' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='药品名称' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='药品分类' name='catalogId' rules={[{ required: true, message: '必填' }]}>
                <Select allowClear placeholder='药品分类' options={options} />
            </Item>
            <Form.Item name='specification' label='规格' rules={[{ required: true, message: '规格不能为空' }]}>
                <Input placeholder='规格' />
            </Form.Item>
            <Item label='症状' name='symptom' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='批准文号' name='approvalNo' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='主治功能' name='indicationFunction' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>

            <Item label='用法用量' name='dosage' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='药企' name='company' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item name='prescription' label='是否处方药' rules={[{ required: true, message: '必填' }]}>
                <Radio.Group>
                    <Radio value='true'>是</Radio>
                    <Radio value='false'>否</Radio>
                </Radio.Group>
            </Item>
            <Item name='published' label='状态' rules={[{ required: true, message: '必填' }]}>
                <Radio.Group>
                    <Radio value='true'>上架</Radio>
                    <Radio value='false'>下架</Radio>
                </Radio.Group>
            </Item>
            <Item
                label='药品图片'
                name='url'
                getValueFromEvent={normFile}
                valuePropName='fileList'
                rules={[{ required: true, message: '必填' }]}
            >
                <Upload
                    action={FILE_URL}
                    accept='image/*'
                    headers={{
                        auth: localStorage.getItem('Authorization'),
                    }}
                    name='file'
                    listType='picture-card'
                    data={{ bucketName: 'img' }}
                >
                    {(isNil(imgLen) || isEmpty(imgLen)) && (
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Upload
                            </div>
                        </div>
                    )}
                </Upload>
            </Item>

            {/* <Form.List name='specificationPrices'>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'specification']}
                                    rules={[{ required: true, message: '规格不能为空' }]}
                                >
                                    <Input placeholder='规格' />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    getValueFromEvent={numberInput}
                                    name={[name, 'price']}
                                    rules={[{ required: true, message: '价格不能为空' }]}
                                >
                                    <Input placeholder='价格' />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        className='dynamic-delete-button'
                                        onClick={() => remove(name)}
                                    />
                                ) : null}
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                添加
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List> */}

            <Item
                label='轮播图（建议大小不超过1m，最多4张）'
                name='url1'
                getValueFromEvent={normFile}
                valuePropName='fileList'
            >
                <Upload
                    action={FILE_URL}
                    accept='image/*'
                    headers={{
                        auth: localStorage.getItem('Authorization'),
                    }}
                    name='file'
                    listType='picture-card'
                    data={{ bucketName: 'img' }}
                >
                    {(isNil(imgLen1) || isEmpty(imgLen1) || imgLen1.length < 4) && (
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Upload
                            </div>
                        </div>
                    )}
                </Upload>
            </Item>
            <Item
                label='说明书（建议大小不超过1m，最多4张）'
                name='url2'
                getValueFromEvent={normFile}
                valuePropName='fileList'
                rules={[{ required: true, message: '必填' }]}
            >
                <Upload
                    action={FILE_URL}
                    accept='image/*'
                    headers={{
                        auth: localStorage.getItem('Authorization'),
                    }}
                    name='file'
                    listType='picture-card'
                    data={{ bucketName: 'img' }}
                >
                    {(isNil(imgLen2) || isEmpty(imgLen2) || imgLen2.length < 4) && (
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Upload
                            </div>
                        </div>
                    )}
                </Upload>
            </Item>
            <Item label='内容说明' name='content' rules={[{ required: true, message: '必填' }]}>
                <Input.TextArea />
            </Item>
        </Form>
    )
}
