/*
 * @LastEditTime: 2022-10-12 09:40:59
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\Certificate\Batch\Update.js
 */
import { Form, DatePicker, Input, Radio, message } from 'antd'
import dayjs from 'dayjs'
import { mergeRight } from 'ramda'
import { useMutation, useQueryClient } from 'react-query'

import { batchNo } from 'service'

const { Item, useForm } = Form

export default function Main({ current, onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()

    const updateMutation = useMutation(batchNo.update, {
        onSuccess: () => {
            message.success('修改成功')
            queryClient.invalidateQueries('batchNo')
            onClose()
        },
    })

    const onSubmit = (values) =>
        updateMutation.mutate(
            mergeRight(current, {
                ...values,
                planHarvestTime: values.planHarvestTime.format('YYYY-MM-DD'),
                seedlingsDate: values.seedlingsDate.format('YYYY-MM-DD'),
            })
        )

    return (
        <Form
            layout='vertical'
            form={form}
            name='batch-no-form'
            onFinish={onSubmit}
            initialValues={{
                ...current,
                seedlingsDate: dayjs(current.seedlingsDate),
                planHarvestTime: dayjs(current.planHarvestTime),
            }}
        >
            <Item name='fisher' label='养殖户'>
                <Input disabled />
            </Item>
            <Item name='fishPondName' label='鱼塘' rules={[{ required: true, message: '必填' }]}>
                <Input disabled />
            </Item>
            <Item name='fryTypeName' label='鱼种' rules={[{ required: true, message: '必填' }]}>
                <Input disabled />
            </Item>
            <Item name='seedlingsDate' label='投苗时间' rules={[{ required: true, message: '必填' }]}>
                <DatePicker className='w-full' />
            </Item>
            <Item name='seedlingsNumber' label='投苗数量' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='斤' />
            </Item>
            <Item name='seedlingSpecifications' label='投苗规格' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='口斤' />
            </Item>
            <Item name='target' label='目标规格' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='斤' />
            </Item>
            <Item name='planHarvestTime' label='预计收成时间' rules={[{ required: true, message: '必填' }]}>
                <DatePicker className='w-full' />
            </Item>
            <Item name='marketPrice' label='目标市场价' rules={[{ required: true, message: '必填' }]}>
                <Input suffix='元/斤' />
            </Item>
            <Item name='expired' label='状态' rules={[{ required: true, message: '必填' }]}>
                <Radio.Group>
                    <Radio value={false}>可用</Radio>
                    <Radio value={true}>结束</Radio>
                </Radio.Group>
            </Item>
        </Form>
    )
}
