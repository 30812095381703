/*
 * @LastEditTime: 2022-08-30 08:48:29
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/components/TreeNode/index.js
 */
import { TreeSelect } from 'antd'

const { TreeNode } = TreeSelect

export const renderTreeNode = (list, type) => {
    return list.map((node) => {
        if (node.children) {
            return (
                <TreeNode
                    key={node.value}
                    value={node.value}
                    selectable={node.type === type}
                    filter={node.label}
                    title={
                        <div
                            className='flex items-center'
                            value={{
                                areaId: node.id,
                                areaName: node.label,
                                areaPaths: node.value,
                                value: node.value,
                            }}
                        >
                            {node.label}
                        </div>
                    }
                >
                    {renderTreeNode(node.children, type)}
                </TreeNode>
            )
        } else {
            return (
                <TreeNode
                    key={node.value}
                    value={node.value}
                    selectable={node.type === type}
                    filter={node.label}
                    title={
                        <div
                            className='flex items-center'
                            value={{
                                areaId: node.id,
                                areaName: node.label,
                                areaPaths: node.value,
                                value: node.value,
                            }}
                        >
                            {node.label}
                        </div>
                    }
                />
            )
        }
    })
}

export const renderCheckboxTreeNode = (list) => {
    return list.map((node) => {
        if (node.children) {
            return (
                <TreeNode
                    key={node.id}
                    value={node.id}
                    title={
                        <div
                            className='flex items-center'
                            value={{
                                id: node.id,
                                name: node.label,
                                paths: node.value,
                                value: node.id,
                            }}
                        >
                            {node.label}
                        </div>
                    }
                    filter={node.label}
                >
                    {renderCheckboxTreeNode(node.children)}
                </TreeNode>
            )
        } else {
            return (
                <TreeNode
                    key={node.id}
                    value={node.id}
                    title={
                        <div
                            className='flex items-center'
                            value={{
                                id: node.id,
                                name: node.label,
                                paths: node.value,
                                value: node.id,
                            }}
                        >
                            {node.label}
                        </div>
                    }
                    filter={node.label}
                />
            )
        }
    })
}
