import { Button, Space, Table, Form, Input, message, Drawer } from 'antd'
import tw from 'twin.macro'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'

import { testingCap } from 'service'
import { useDisclosure } from 'utils/useDisclosure'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'

const { Item } = Form

const Wrap = tw.div`w-full h-full space-y-4 p-4`

export default function FishType() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [current, setCurrent] = useState(null)

    const [query, setQuery] = useQueryParams({
        name: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(ArrayParam, ['createTime,desc']),
    })
    const { data, isFetching, refetch } = useQuery(
        ['testing-capability', 'page', query],
        () => testingCap.page(query),
        {
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        }
    )

    const deleteMutation = useMutation(testingCap.delete, {
        onSuccess: () => {
            message.success('删除成功')
            if (data.content.length === 1 && query.page !== 0) {
                setQuery({ ...query, page: query.page - 1 })
            } else {
                refetch()
            }
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '检测能力名称',
            dataIndex: 'name',
        },
        {
            title: '残留限值',
            dataIndex: 'value',
        },
        {
            title: '是否影响检测单及格',
            key: 'affectPass',
            render: (r) => <span>{r.affectPass ? '是' : '否'}</span>,
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) => setQuery({ ...value, page: 0 })}
                initialValues={query}
            >
                <Item name='name'>
                    <Input placeholder='检测能力名称' />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>

            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                    showSizeChanger: false,
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建检测能力' : '修改检测能力'}
                onClose={onClose}
                extra={
                    <Button form='testing-capability-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
