/*
 * @LastEditTime: 2022-09-06 12:14:26
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/List/Detail.js
 */
import tw from 'twin.macro'
import { Descriptions, Spin, Button, Divider, Tag, Segmented, Empty, Select, message } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { isNil } from 'ramda'

import { task, user } from 'service'
import { Decrypt } from 'utils/encrypt'

import FishPond from './FishPond'
import Agriculture from './Agriculture'
import TaskRecord from './TaskRecord'
import Picture from './Picture'
import { useState } from 'react'

const Wrap = tw.div`w-full h-full relative overflow-y-auto`
const Header = tw.div`h-14 px-2 flex items-center border-b bg-white sticky top-0 text-xl gap-2 z-10`
const Content = tw.div`p-4 space-y-4`
const { Item } = Descriptions

const taskTypeMap = {
    plan: '计划任务',
    rectification: '整改任务',
    fish: '鱼类抽查（非计划任务）',
    water: '水质抽查（非计划任务）',
    storehouse: '仓库检查（非计划任务）',
    log: '日志检查（非计划任务）',
}

const CustomForm = ({
    agricultureRecordVOList,
    fishPondRecordVOList,
    taskRecordVOList,
    taskType,
    punchTime,
    location,
    attachmentsVOList,
}) => {
    const [view, setView] = useState('agriculture')
    return isNil(agricultureRecordVOList) && isNil(fishPondRecordVOList) && isNil(taskRecordVOList) ? (
        <Empty />
    ) : taskType === 'plan' || taskType === 'rectification' ? (
        <>
            <div className='text-center'>
                <Segmented
                    value={view}
                    options={[
                        { label: '农产品质量检查记录表', value: 'agriculture' },
                        { label: '鱼塘巡查信息记录表', value: 'fishpond' },
                        { label: '打卡照片', value: 'picture' },
                    ]}
                    onChange={(value) => setView(value)}
                />
            </div>
            {view === 'agriculture' ? (
                <Agriculture data={agricultureRecordVOList} />
            ) : view === 'picture' ? (
                <Picture punchTime={punchTime} location={location} attachmentsVOList={attachmentsVOList} />
            ) : (
                <FishPond data={fishPondRecordVOList} />
            )}
        </>
    ) : (
        <TaskRecord data={taskRecordVOList} />
    )
}

export default function TaskDetail() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { taskId } = useParams()
    const { data, isFetching } = useQuery(['task', 'one', taskId], () => task.one(Decrypt(taskId)), {
        placeholderData: {},
    })

    const { data: inspector } = useQuery(['inspector-list', data.areaId], () => user.getInspectors(data.areaId), {
        placeholderData: [],
        enabled: !isNil(data?.areaId),
    })

    const updateMutation = useMutation(task.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('task')
            message.success('操作成功')
        },
    })

    return (
        <Spin spinning={isFetching}>
            <Wrap>
                <Header>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
                    {data.fishPondName}
                </Header>
                <Content>
                    <Descriptions bordered column={3}>
                        <Item label='任务编号'>{data.code}</Item>
                        <Item label='任务类型'>{taskTypeMap[data.taskType]}</Item>
                        <Item label='任务状态'>
                            <Tag color={data.state === 1 ? 'blue' : 'green'}>
                                {data.state === 1 ? '待完成' : '已完成'}
                            </Tag>
                        </Item>
                        <Item label='发布时间'>{data.createTime}</Item>
                        <Item label='截止时间'>{data.endTime}</Item>
                        <Item label='完成时间'>{data.completeTime}</Item>
                        <Item label='鱼种信息'>{data.fryTypeName}</Item>
                        <Item label='巡塘员'>
                            {data.state === 1 ? (
                                <Select
                                    className='w-full'
                                    value={data.inspectUserName}
                                    fieldNames={{ label: 'name', value: 'id' }}
                                    options={inspector}
                                    onSelect={(_, node) =>
                                        updateMutation.mutate({
                                            ...data,
                                            inspectUserId: node.id,
                                            inspectUserName: node.name,
                                        })
                                    }
                                />
                            ) : (
                                data.inspectUserName
                            )}
                        </Item>
                        <Item label='是否超期'>
                            <span className={data.overdue ? 'text-red-500' : 'text-blue-500'}>
                                {data.overdue ? '超期' : '未超期'}
                            </span>
                        </Item>
                        <Item label='鱼塘位置' span={3}>
                            {data.location}
                        </Item>
                    </Descriptions>
                    <Divider>任务相关表单</Divider>
                    <CustomForm {...data} />
                </Content>
            </Wrap>
        </Spin>
    )
}
