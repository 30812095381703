import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Form, Input, DatePicker, Select, Modal } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { useQuery } from 'react-query'
import { useDisclosure } from 'utils/useDisclosure'
import { pharmacyTestingOrganComment } from 'service'

import Detail from './DetailTestOrgan'
import { isEmpty, isNil } from 'ramda'
import dayjs from 'dayjs'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form
const { RangePicker } = DatePicker

const businessOptions = [
    { label: '鱼药店', value: 'ops' },
    { label: '养殖户', value: 'fisher' },
    { label: '专家', value: 'expertAssess' },
]

export const userType = {
    inspector: '巡塘员',
    patroller: '巡店员',
    expertAssess: '专家',
    expertMedicine: '专家',
    fisher: '养殖户',
    ops: '鱼药店',
}

export default function TestOrgan() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [query, setQuery] = useQueryParams({
        pharmacyName: StringParam,
        userName: StringParam,
        userType: StringParam,
        startTime: StringParam,
        endTime: StringParam,
        time: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createdTime,desc'),
    })
    const [current, setCurrent] = useState(null)

    const { data, isFetching } = useQuery(
        ['pharmacy-testing-organ-comment', 'page', query],
        () => pharmacyTestingOrganComment.page(query),
        {
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        }
    )

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '评级对象',
            key: 'pharmacyName',
            render: (r) => r?.testingOrganVO?.name,
        },
        {
            title: '评级分数',
            dataIndex: 'score',
        },
        {
            title: '评级者身份',
            key: 'userType',
            render: (r) => userType[r.userType],
        },
        {
            title: '评级者',
            dataIndex: 'userName',
        },
        {
            title: '评级时间',
            dataIndex: 'createdTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        查看
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        startTime:
                            !isNil(value.time) && !isEmpty(value.time) ? value.time[0].format('YYYY-MM-DD') : undefined,
                        endTime:
                            !isNil(value.time) && !isEmpty(value.time) ? value.time[1].format('YYYY-MM-DD') : undefined,
                        time: undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    startTime: query.startTime ? dayjs(query.startTime) : undefined,
                    endTime: query.endTime ? dayjs(query.endTime) : undefined,
                }}
                className='gap-2'
            >
                <Item name='pharmacyName' className='w-48'>
                    <Input placeholder='评级对象' />
                </Item>
                <Item name='userName' className='w-48'>
                    <Input placeholder='评级者' />
                </Item>
                <Item name='userType' className='w-48'>
                    <Select allowClear placeholder='评级者身份' options={businessOptions} />
                </Item>
                <Form.Item name='time'>
                    <RangePicker />
                </Form.Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Modal
                title='评级详情'
                visible={isOpen}
                onCancel={onClose}
                width='60%'
                footer={<Button onClick={onClose}>关闭</Button>}
            >
                <Detail data={current} onClose={onClose} />
            </Modal>
        </Wrap>
    )
}
