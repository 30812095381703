/*
 * @LastEditTime: 2022-07-22 09:18:46
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Drugs/Type/Create.js
 */
import { Form, Input } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { medicineCatalog } from 'service'

const { Item } = Form

export default function InformationCreate({ onClose }) {
    const queryClient = useQueryClient()

    const createMutation = useMutation(medicineCatalog.create, {
        onSuccess: () => {
            queryClient.invalidateQueries('medicine-type')
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate(values)
    }

    return (
        <Form name='medicine-type-drawer-form' onFinish={onSubmit} autoComplete='off' layout='vertical'>
            <Item label='分类名称' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='分类说明' name='desr'>
                <Input.TextArea allowClear showCount />
            </Item>
        </Form>
    )
}
