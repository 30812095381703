/*
 * @LastEditTime: 2022-08-30 17:23:52
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/Drugs/index.js
 */
import tw from 'twin.macro'
import React from 'react'

import StockStatis from './StockStatis'
import StockOutStatis from './StockOutStatis'

const Wrap = tw.div`flex flex-col space-y-4 p-4`

export default function PondInspector() {
    return (
        <Wrap>
            <StockStatis />
            <StockOutStatis />
        </Wrap>
    )
}
