/*
 * @LastEditTime: 2022-09-21 17:58:31
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics/index.js
 */
import tw, { styled } from 'twin.macro'
import { useRef } from 'react'
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import { Row, Col } from 'antd'
import { ExportOutlined, FullscreenOutlined, FullscreenExitOutlined, SwapOutlined } from '@ant-design/icons'
import { useRecoilValue } from 'recoil'
import { userInfoAtom } from 'store/auth'
import { useNavigate } from 'react-router-dom'
import { useFullscreen, useToggle } from 'react-use'
import HeaderBg from 'assets/fullscreen-title.png'

import Date from './Date'
import Indentity from './Indentity'
import Attendance from './Attendance'
import UnplannedRate from './UnplannedRate'
import InspectionTrend from './InspectionTrend'
import TaskRatio from './TaskRatio'
import PondUnplannedRate from './PondUnplannedRate'

const Wrap = styled.div`
    font-family: 'SuCaiJiShiKuFangTi';
`

const Header = styled.div`
    ${tw`flex flex-shrink-0 h-[10%] items-center justify-between bg-center bg-contain bg-no-repeat px-4 py-2`};
    background-image: url(${HeaderBg});
`
const ButtonWrap = tw.div`w-[60px] h-[60px] bg-[#041a40] cursor-pointer hover:text-white hover:border-white flex-shrink-0 bg-opacity-70 rounded-full border border-[#02cde6] text-[#02cde6] shadow-md flex flex-col items-center justify-center gap-2 text-sm`

export default function Main() {
    const [show, toggle] = useToggle(false)
    const userInfo = useRecoilValue(userInfoAtom)
    const navigate = useNavigate()
    const fullRef = useRef(null)
    const isFullscreen = useFullscreen(fullRef, show, { onClose: () => toggle(false) })

    return (
        <Wrap ref={fullRef}>
            <FullScreenContainer className='bg-[rgba(80,118,150,0.15)]'>
                <Header>
                    <div className='h-full flex items-center gap-4'>
                        <div className='p-4 text-white text-2xl flex-shrink-0 flex items-center justify-center border border-[#6398cf] shadow-md'>
                            顺德数字水产综合服务平台
                        </div>
                        <Date />
                    </div>
                    <div className='h-full flex items-center gap-4'>
                        <Indentity userInfo={userInfo} />
                        <ButtonWrap onClick={() => toggle(!isFullscreen)}>
                            {isFullscreen ? (
                                <>
                                    <FullscreenExitOutlined style={{ fontSize: 18 }} />
                                    <span className='text-xs'>退出全屏</span>
                                </>
                            ) : (
                                <>
                                    <FullscreenOutlined style={{ fontSize: 18 }} />
                                    <span>全屏</span>
                                </>
                            )}
                        </ButtonWrap>
                        <ButtonWrap onClick={() => navigate('/full-statistics2', { replace: true })}>
                            <SwapOutlined style={{ fontSize: 18 }} />
                            <span>切换</span>
                        </ButtonWrap>
                        <ButtonWrap onClick={() => navigate(-1)}>
                            <ExportOutlined style={{ fontSize: 18 }} />
                            <span>退出</span>
                        </ButtonWrap>
                    </div>
                </Header>
                <Row className='h-[45%]'>
                    <Col span={9}>
                        <Attendance areaPaths={userInfo.areaPaths} />
                    </Col>
                    <Col span={9}>
                        <UnplannedRate areaPaths={userInfo.areaPaths} />
                    </Col>
                    <Col span={6}>
                        <TaskRatio areaPaths={userInfo.areaPaths} />
                    </Col>
                </Row>
                <Row className='h-[45%]'>
                    <Col span={12}>
                        <InspectionTrend areaPaths={userInfo.areaPaths} />
                    </Col>
                    <Col span={12}>
                        <PondUnplannedRate areaPaths={userInfo.areaPaths} />
                    </Col>
                </Row>
            </FullScreenContainer>
        </Wrap>
    )
}
