import tw from 'twin.macro'
import { Form, Input } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { area } from 'service'

const { Item, useForm } = Form
const Wrap = tw.div`w-full h-full relative space-y-4`

export default function AreaCreate({ current, onClose }) {
    const [form] = useForm()

    const queryClient = useQueryClient()

    const createMutation = useMutation(area.create, {
        onSuccess: () => {
            queryClient.invalidateQueries('area-tree')
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate({ ...values, parentId: current.id })
    }

    return (
        <>
            <Wrap>
                <Form
                    form={form}
                    className='max-w-[500px]'
                    name='area-form'
                    onFinish={onSubmit}
                    autoComplete='off'
                    layout='vertical'
                >
                    <Item label='组织名称' name='name' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='行政编码' name='code' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                </Form>
            </Wrap>
        </>
    )
}
