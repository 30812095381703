/*
 * @LastEditTime: 2022-09-06 13:58:36
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Main/Header.js
 */
import { useMemo } from 'react'
import { Layout, Button, Dropdown, Menu, Modal, Breadcrumb, Image } from 'antd'
import { UserOutlined, LineChartOutlined } from '@ant-design/icons'
import { useDisclosure } from 'utils/useDisclosure'
import { useRecoilValue } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import { length } from 'ramda'
import { notify } from 'service'

import UpdatePwd from './UpdatePwdForm'

import { purviewTreeAtom, userInfoAtom, purviewsAtom } from 'store/auth'
import { getPaths } from 'utils/getTreeLen'
import { useQuery } from 'react-query'

const { Header } = Layout

export default function HeaderMain() {
    const navigate = useNavigate()
    const location = useLocation()
    const userInfo = useRecoilValue(userInfoAtom)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: qrCodeIsOpen, onOpen: qrCodeOnOpen, onClose: qrCodeClose } = useDisclosure()

    const purviewTree = useRecoilValue(purviewTreeAtom)
    const purviews = useRecoilValue(purviewsAtom)

    const breads = useMemo(
        () => getPaths(purviewTree, location.pathname),
        // eslint-disable-next-line
        [location]
    )

    const handleClick = ({ key }) => {
        if (key === 'logout') {
            localStorage.removeItem('Authorization')
            window.location.href = '/login'
        }
        if (key === 'pwd') {
            onOpen()
        }
        if (key === 'qrCode') {
            qrCodeOnOpen()
        }
    }

    const { data: qrcode } = useQuery(['qrcode'], () => notify.getQrCode(), {
        refetchInterval: 1000 * 55,
        enabled: qrCodeIsOpen,
    })

    return (
        <Header className='!px-4 flex items-center justify-between'>
            <Breadcrumb separator={<span className='!text-white'>/</span>}>
                {breads.map((item, index) => (
                    <Breadcrumb.Item
                        key={item.path}
                        className={index === length(breads) - 1 ? '!text-white' : '!text-gray-400'}
                    >
                        {item.label}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
            <div className='flex items-center text-white'>
                {purviews.includes('/full-statistics') && (
                    <Button
                        type='text'
                        className='!text-white'
                        icon={<LineChartOutlined />}
                        onClick={() => navigate('/full-statistics')}
                    >
                        数据大屏
                    </Button>
                )}
                <Dropdown
                    overlay={
                        <Menu
                            items={[
                                { label: '修改密码', key: 'pwd' },
                                { label: '二维码', key: 'qrCode' },
                                { label: '退出登录', key: 'logout' },
                            ]}
                            onClick={handleClick}
                        />
                    }
                >
                    <Button type='text' className='!text-white' icon={<UserOutlined />} />
                </Dropdown>
                <div>{userInfo?.name}</div>
            </div>
            <Modal
                visible={isOpen}
                title='修改密码'
                onCancel={onClose}
                okButtonProps={{ form: 'update-password', htmlType: 'submit' }}
                destroyOnClose
            >
                <UpdatePwd onClose={onClose} />
            </Modal>
            {/* 二维码 */}
            <Modal visible={qrCodeIsOpen} title='公众号二维码' onCancel={qrCodeClose} destroyOnClose>
                <div className='flex justify-center items-center w-full flex-col'>
                    <p>关注公众号获取消息通知</p>
                    <Image key={'qrcode'} width={150} src={qrcode} />
                </div>
            </Modal>
        </Header>
    )
}
