import { Line } from '@ant-design/plots'
import { Form, Radio, Select, Card, Empty, Spin } from 'antd'

import React, { useMemo, useState } from 'react'
import { useQueries } from 'react-query'

import { testingRecord, fryType } from 'service'
import { isEmpty } from 'ramda'

const { Item } = Form

export default function TestingFish() {
    const [query, setQuery] = useState({
        fryTypeId: undefined,
        period: 'seven',
    })

    const [{ data: options }, { data, isFetching }] = useQueries([
        {
            queryKey: ['fry-type', 'list'],
            queryFn: () => fryType.list(),
            placeholderData: [],
        },
        {
            queryKey: ['testing-statis-fish', query],
            queryFn: () => testingRecord.statisTestingByFish(query),
            placeholderData: [],
            keepPreviousData: true,
        },
    ])

    const config = useMemo(
        () => ({
            data: data || [],
            xField: 'period',
            yField: 'count',
            label: {},
            xAxis: {
                type: 'time',
            },
            point: {
                size: 5,
                shape: 'diamond',
                style: {
                    fill: 'white',
                    stroke: '#5B8FF9',
                    lineWidth: 2,
                },
            },
            tooltip: {
                showMarkers: false,
            },
            interactions: [
                {
                    type: 'marker-active',
                },
            ],
            state: {
                active: {
                    style: {
                        shadowBlur: 4,
                        stroke: '#000',
                        fill: 'red',
                    },
                },
            },
        }),
        [data]
    )

    return (
        <Card title='检测鱼种趋势' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={isFetching}>
                <Form autoComplete='off' layout='inline' className='gap-2 justify-end'>
                    <Item className='w-48'>
                        <Select
                            value={query.fryTypeId}
                            fieldNames={{ label: 'fryType', value: 'id' }}
                            placeholder='全部鱼种'
                            allowClear
                            options={options}
                            onChange={(fryTypeId) => setQuery({ ...query, fryTypeId })}
                        />
                    </Item>
                    <Item>
                        <Radio.Group
                            value={query.period}
                            onChange={({ target }) => setQuery({ ...query, period: target.value })}
                        >
                            <Radio.Button value='seven'>近7天</Radio.Button>
                            <Radio.Button value='thirty'>近30天</Radio.Button>
                            <Radio.Button value='bymonth'>按年</Radio.Button>
                        </Radio.Group>
                    </Item>
                </Form>
                <div className='mt-4'>
                    {!isEmpty(data) ? (
                        <Line {...config} />
                    ) : (
                        <Empty className='h-60 flex flex-col items-center justify-center' />
                    )}
                </div>
            </Spin>
        </Card>
    )
}
