/*
 * @LastEditTime: 2022-10-08 07:53:50
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\service\index.js
 */

import { createService } from 'utils/createService'
import { api } from 'utils/api'
import axios from 'axios'

// 帐号管理
export const account = createService({
    service: 'upms',
    modules: 'account',
    others: {
        login: (payload) => api.post('/upms/account/login', payload),
        modifyPwd: (payload) => api.post('/upms/account/modify/pwd', payload),
        resetPwd: (id) => api.post(`/upms/account/${id}/reset-pwd`),
    },
})

// 用户管理
export const user = createService({
    service: 'upms',
    modules: 'user',
    others: {
        getCurrent: () => api.get('/upms/user/current'),
        getInspectors: (areaId) => api.get(`/upms/user/inspectors/of/${areaId}`),
        search: (params) => api.get('/upms/user/search', { params }),
        // 获取某区域下面的人员
        getAreaUser: (type, path) => api.get(`/upms/user/${type}/in/${path}`),

        getpatrollers: (areaId) => api.get(`/upms/user/patrollers/of/${areaId}`),
    },
})

// 角色管理
export const role = createService({ service: 'upms', modules: 'role' })

// 权限管理
export const purview = createService({
    service: 'upms',
    modules: 'purview',
    others: { tree: (params) => api.get('/upms/purview/tree', { params }) },
})

// 区域管理
export const area = createService({
    service: 'upms',
    modules: 'area',
    others: {
        tree: (params) => api.get('/upms/area/tree', { params }),
    },
})

// 资讯类型
export const cmsType = createService({
    service: 'notify',
    modules: 'cms-type',
    others: {
        down: ({ sourceId, targetId }) => api.put(`/notify/cms-type/${sourceId}/down/${targetId}`),
        up: ({ sourceId, targetId }) => api.put(`/notify/cms-type/${sourceId}/up/${targetId}`),
    },
})

// 资讯内容
export const cmsContent = createService({ service: 'notify', modules: 'cms-content' })

// 任务目标
export const target = createService({
    service: 'inspect',
    modules: 'task-target',
    others: {
        publish: (id) => api.post(`/inspect/task-target/${id}/publish`),
        invalid: (id) => api.post(`/inspect/task-target/${id}/invalid`),
    },
})

// 巡塘任务
export const task = createService({
    service: 'inspect',
    modules: 'task',
    others: {
        // 巡塘员出勤率
        chuqinlv: (params) => api.get('/inspect/task/statis/chuqinlv/by-inspector', { params }),
        // 巡塘员非计划率
        unplanlv: (params) => api.get('/inspect/task/statis/unplanlv/by-inspector', { params }),
        // 巡塘员任务占比
        radio: (params) => api.get('/inspect/task/statis/by-type', { params }),
        // 鱼塘巡查趋势
        pond: (params) => api.get('/inspect/task/statis/by-pond', { params }),
        // 鱼塘非计划任务完成率
        unplanPond: (params) => api.get('/inspect/task/statis/unplanlv/by-pond', { params }),
        // 按照时间区间来获取巡塘任务
        certificatePondRecord: (params) => api.get('/inspect/task/certificate/record', { params }),
        // 根据鱼塘ID获取历史巡塘任务
        his: (params) => api.get('/inspect/task/his', { params }),
    },
})

// 鱼（鱼苗）类型
export const fryType = createService({ service: 'inspect', modules: 'fry-type' })

//鱼塘
export const fishPond = createService({
    service: 'inspect',
    modules: 'fish-pond',
    others: {
        initQrCode: (id) => api.get(`/inspect/fish-pond/${id}/qr`),
        statis: (params) => api.get('/inspect/fish-pond/statis/fry-type', { params }),
    },
})

// 药品种类管理
export const medicineCatalog = createService({
    service: 'pharmacy',
    modules: 'medicine-catalog',
    others: {
        move: ({ sourceId, targetId }) => api.put(`/pharmacy/medicine-catalog/${sourceId}/up/${targetId}`),
    },
})

// 药品出入明细 /api/pharmacy/medicine-stock-his-detail/statis/by-catalog
export const medicineStock = createService({
    service: 'pharmacy',
    modules: 'medicine-stock-his-detail',
    others: {
        statisStockByCatalog: (params) => api.get(`/pharmacy/medicine-stock-his-detail/statis/by-catalog`, { params }),
        statisStockByMedicine: (params) => api.get(`/pharmacy/medicine-stock-his-detail/top10/by-medicine`, { params }),
    },
})

// 药品管理
export const medicine = createService({
    service: 'pharmacy',
    modules: 'medicine',
    others: {
        //及格率
        statisByCataLog: (params) => api.get('/pharmacy/medicine-stock/statis/by-catalog', { params }),
    },
})

//消息中心
// export const message = createService({ service: 'pharmacy', modules: 'medicine' })

//药店管理
export const pharmacy = createService({
    service: 'pharmacy',
    modules: 'pharmacy',
    others: {
        qrCode: (id) => api.get(`/pharmacy/pharmacy/${id}/qr`),
    },
})

//检测机构管理
export const testingOrgan = createService({
    service: 'pharmacy',
    modules: 'testing-organ',
    others: {
        qrCode: (id) => api.get(`/pharmacy/testing-organ/${id}/qr`),
    },
})

//检测记录
export const testingRecord = createService({
    service: 'pharmacy',
    modules: 'testing-record',
    others: {
        //检测项目统计
        statisTestingByCapability: (params) => api.get('/pharmacy/testing-record/statis/by-capability', { params }),
        //检测鱼种统计
        statisTestingByFish: (params) => api.get('/pharmacy/testing-record/statis/by-fish', { params }),
        //检测种类统计
        statisTestingByType: (params) => api.get('/pharmacy/testing-record/statis/by-type', { params }),
        //合格证相关检测记录
        testingCertificateRrecord: (params) => api.get('/pharmacy/testing-record/certificate/record', { params }),
    },
})

//检测能力
export const testingCapability = createService({ service: 'pharmacy', modules: 'testing-capability' })

//病例分类管理
export const symptomCatalog = createService({ service: 'pharmacy', modules: 'symptom-catalog' })

//病例库-病例管理-鱼药店病例库
export const symptom = createService({
    service: 'pharmacy',
    modules: 'symptom',
    others: {
        addsk: (id) => api.put(`/pharmacy/symptom/add/${id}/knowledge`),
        //统计鱼种病例数
        statisByFish: (params) => api.get('/pharmacy/symptom/statis/by-fish', { params }),
        //统计鱼塘病例数
        statisByPond: (params) => api.get('/pharmacy/symptom/statis/by-pond', { params }),
        // 统计病例录入趋势
        statisByPharmacy: (params) => api.get('/pharmacy/symptom/statis/by-pharmacy', { params }),
    },
})

//药方记录
export const symptomManifest = createService({
    service: 'pharmacy',
    modules: 'symptom-manifest',
    others: {
        certificateRecord: (params) => api.get('/pharmacy/symptom-manifest/certificate/record', { params }),
        // 统计药方开具趋势
        statisByPharmacy: (params) => api.get('/pharmacy/symptom-manifest/statis/by-pharmacy', { params }),
    },
})
//检测机构管理
export const leave = createService({
    service: 'upms',
    modules: 'leave',
    others: {
        approval: ({ id, payload }) => api.put(`/upms/leave/${id}/approval`, payload),
    },
})

//病例知识库
export const symptomKn = createService({ service: 'pharmacy', modules: 'symptom-kn' })

//巡店任务目标
export const pharmacyTaskTarget = createService({
    service: 'pharmacy',
    modules: 'task-target',
    others: {
        invalid: (id) => api.post(`/pharmacy/task-target/${id}/invalid`),
        publish: (id) => api.post(`/pharmacy/task-target/${id}/publish`),
        modifyPwd: (payload) => api.post('/upms/account/modify/pwd', payload),
    },
})

// 巡店任务
export const pharmacyTask = createService({
    service: 'pharmacy',
    modules: 'task',
    others: {
        //及格率
        chuqinlv: (params) => api.get('/pharmacy/task/statis/chuqinlv', { params }),
        //及格率
        jigelv: (params) => api.get('/pharmacy/task/statis/jigelv', { params }),
        his: (params) => api.get('/pharmacy/task/his', { params }),
    },
})

//鱼药店评级
export const pharmacyComment = createService({ service: 'pharmacy', modules: 'pharmacy-comment' })

//检测机构评级
export const pharmacyTestingOrganComment = createService({ service: 'pharmacy', modules: 'testing-organ-comment' })

// 检测能力
export const testingCap = createService({ service: 'pharmacy', modules: 'testing-capability' })

// 合格证设置
export const certificateConf = createService({ service: 'inspect', modules: 'certificate-conf' })

// 合格证
export const certificate = createService({ service: 'inspect', modules: 'certificate' })

//巡店计划
export const pharmacyPlan = createService({
    service: 'pharmacy',
    modules: 'plan',
    others: {
        //设置巡查日期
        setDays: (payload) => api.put(`/pharmacy/plan/1/days`, payload),
    },
})

//二维码 /api/notify/wx-msg/qr-png
export const notify = createService({
    service: 'notify',
    modules: 'wx-msg',
    others: {
        getQrCode: () => api.get('/notify/wx-msg/qr-png'),
    },
})

// 地图搜索关键词
export const getAddressByKeywords = (params) =>
    axios.get('/place/v1/suggestion', {
        baseURL: '/ws',
        params,
    })

// 批次号管理
export const batchNo = createService({
    service: 'inspect',
    modules: 'fish-batch-no',
})
