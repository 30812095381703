/*
 * @LastEditTime: 2022-09-20 11:46:19
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics/Date.js
 */

import { useState } from 'react'
import dayjs from 'dayjs'
import { useInterval } from 'react-use'

const arr = ['一', '二', '三', '四', '五', '六', '日']

export default function Main() {
    const [currentTime, setTime] = useState(null)
    const [currentDate] = useState(dayjs())
    useInterval(() => {
        setTime(dayjs().format('HH:mm:ss'))
    }, 1000)
    return (
        <div className='self-end h-full flex flex-col items-center justify-center '>
            <div className='w-72 text-[#64e5ce] text-2xl'>{currentTime}</div>
            <div className='w-72 text-[#daf4ef] text-xl'>
                {currentDate.format('YYYY年MM月DD日')} 星期{arr[currentDate.get('day') - 1]}
            </div>
        </div>
    )
}
