/*
 * @LastEditTime: 2022-10-13 10:04:37
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\User\Expert\Create.js
 */
import { DatePicker, Form, Input, Segmented, Select, Switch } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { user } from 'service'

const { Item, useForm } = Form

const options = [
    { label: '评级专家', value: 'expertAssess' },
    { label: '开药方专家', value: 'expertMedicine' },
]

export default function PatrolCreate({ onClose }) {
    const [form] = useForm()

    const queryClient = useQueryClient()

    const createMutation = useMutation(user.create, {
        onSuccess: () => {
            queryClient.invalidateQueries(['expert'])
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate({
            ...values,
            birthday: values.birthday?.format('YYYY-MM-DD') ?? undefined,
        })
    }

    return (
        <Form form={form} name='patrol-drawer-form' onFinish={onSubmit} layout='vertical' autoComplete='off'>
            <Item label='姓名' name='name' rules={[{ required: true, message: '请输入' }]}>
                <Input />
            </Item>
            <Item
                label='联系电话'
                name='mobile'
                rules={[
                    { required: true, message: '请输入' },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                    },
                ]}
                validateTrigger='onBlur'
            >
                <Input maxLength={11} />
            </Item>
            <Item label='出生日期' name='birthday'>
                <DatePicker className='w-full' />
            </Item>
            <Item label='专家类型' name='type' rules={[{ required: true, message: '请选择' }]}>
                <Select options={options} />
            </Item>
            <Item label='擅长领域' name='goodAt' rules={[{ required: true, message: '请输入' }]}>
                <Input />
            </Item>
            <Item label='性别' name='gender' initialValue='male'>
                <Segmented
                    options={[
                        { label: '男', value: 'male' },
                        { label: '女', value: 'female' },
                    ]}
                />
            </Item>
            <Item
                label='账号状态'
                name='enabled'
                valuePropName='checked'
                initialValue={true}
                rules={[{ required: true, message: '请输入选择类型' }]}
            >
                <Switch />
            </Item>
            <Item label='专家介绍' name='remark' rules={[{ required: true, message: '请输入' }]}>
                <Input.TextArea />
            </Item>
        </Form>
    )
}
