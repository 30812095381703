/*
 * @LastEditTime: 2022-09-20 15:14:45
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics/Subscript.js
 */
import JB from 'assets/jiaobiao.png'

export default function JiaoBiao() {
    return (
        <>
            <div
                className='transform -rotate-90 w-5 h-5 bg-no-repeat bg-center bg-contain absolute top-1 left-1'
                style={{ backgroundImage: `url(${JB})` }}
            />
            <div
                className='w-5 h-5 bg-no-repeat bg-center bg-contain absolute top-1 right-1'
                style={{ backgroundImage: `url(${JB})` }}
            />
            <div
                className='transform rotate-90 w-5 h-5 bg-no-repeat bg-center bg-contain absolute bottom-1 right-1'
                style={{ backgroundImage: `url(${JB})` }}
            />
            <div
                className='transform rotate-180 w-5 h-5 bg-no-repeat bg-center bg-contain absolute bottom-1 left-1'
                style={{ backgroundImage: `url(${JB})` }}
            />
        </>
    )
}
