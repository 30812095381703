/*
 * @LastEditTime: 2022-09-20 16:53:57
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Information/InformationType/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Drawer, Form, Input, message, Tag, Select } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, BooleanParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useMutation, useQuery } from 'react-query'

import { useDisclosure } from 'utils/useDisclosure'
import { cmsType } from 'service'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function InformationType() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        name: StringParam,
        state: BooleanParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
    })
    const [current, setCurrent] = useState(null)

    const { data, isFetching, refetch } = useQuery(['cms-type', 'page', query], () => cmsType.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const deleteMutation = useMutation(cmsType.delete, {
        onSuccess: () => {
            refetch()
            message.success('删除成功')
        },
    })

    const upMutation = useMutation(cmsType.up, {
        onSuccess: refetch,
    })

    const downMutation = useMutation(cmsType.down, {
        onSuccess: refetch,
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '资讯类型',
            dataIndex: 'name',
        },
        {
            title: '类型状态',
            key: 'state',
            width: 100,
            render: (r) => (r.state ? <Tag color='success'>启用</Tag> : <Tag color='error'>禁用</Tag>),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (_, r, index) => (
                <Space>
                    <Button
                        type='link'
                        size='small'
                        disabled={index === 0}
                        onClick={() => upMutation.mutate({ sourceId: r.id, targetId: data.content[index - 1].id })}
                    >
                        上移
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        disabled={index * (query.page + 1) === data.totalElements - 1}
                        onClick={() => downMutation.mutate({ sourceId: r.id, targetId: data.content[index + 1].id })}
                    >
                        下移
                    </Button>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]
    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) => setQuery({ ...value, page: 0 })}
            >
                <Item name='name'>
                    <Input placeholder='资讯类型' />
                </Item>
                <Item name='state' className='w-48'>
                    <Select
                        placeholder='类型状态'
                        allowClear
                        options={[
                            { label: '启用', value: true },
                            { label: '禁用', value: false },
                        ]}
                    />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建类型' : '修改类型'}
                onClose={onClose}
                extra={
                    <Button form='information-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
