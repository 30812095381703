/*
 * @LastEditTime: 2022-08-18 15:50:16
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/BasicData/TestingCapability/Create.js
 */
import { Form, Input, Radio } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { testingCap } from 'service'

const { Item } = Form

export default function FishTypeCreate({ onClose }) {
    const queryClient = useQueryClient()

    const createMutation = useMutation(testingCap.create, {
        onSuccess: () => {
            queryClient.invalidateQueries('testing-capability')
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate(values)
    }

    return (
        <Form name='testing-capability-form' onFinish={onSubmit} autoComplete='off' layout='vertical'>
            <Item label='检测能力名称' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='残留限值' name='value' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item
                label='是否影响检测单及格'
                name='affectPass'
                rules={[{ required: true, message: '必填' }]}
                initialValue={true}
            >
                <Radio.Group>
                    <Radio.Button value={true}>是</Radio.Button>
                    <Radio.Button value={false}>否</Radio.Button>
                </Radio.Group>
            </Item>
        </Form>
    )
}
