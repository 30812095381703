/**
 * 鱼药店病例库
 */
import tw from 'twin.macro'
import { Button, Space, Table, Form, Input, Select, DatePicker, Tooltip } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { useQueries } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { symptom, symptomCatalog } from 'service'
import { Enscrypt } from 'utils/encrypt'

const Wrap = tw.div`w-full h-full space-y-4 p-4`

const { RangePicker } = DatePicker
const { Item } = Form

export default function Main() {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [query, setQuery] = useQueryParams({
        name: StringParam,
        code: StringParam,
        catalogId: StringParam,
        pharmacyName: StringParam,
        period: ArrayParam,
        mainSymptom: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })

    const [{ data: options }, { data, isFetching }] = useQueries([
        {
            queryKey: ['symptom-catalog', 'list'],
            queryFn: () => symptomCatalog.list(),
            placeholderData: [],
            select: (data) => data.map((x) => ({ label: x.name, value: x.id })),
        },
        {
            queryKey: ['symptom', 'page', query],
            queryFn: () => symptom.page(query),
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        },
    ])

    const handleOpenDetail = (item) => {
        const id = Enscrypt(item.id)
        navigate(`/disease/fish-shop/${id}`)
    }

    const columns = [
        {
            title: '病例编号',
            dataIndex: 'code',
        },
        {
            title: '病例名称',
            dataIndex: 'name',
        },
        {
            title: '病例分类',
            dataIndex: 'catalogName',
        },
        {
            title: '病情描述',
            dataIndex: 'mainSymptom',
            width: 150,
            ellipsis: { showTitle: false },
            render: (text) => (
                <Tooltip placement='topLeft' title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: '养殖户名称',
            dataIndex: 'fisher',
        },
        {
            title: '录入鱼药店',
            dataIndex: 'pharmacyName',
        },
        {
            title: '录入时间',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 80,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleOpenDetail(r)}>
                        详情
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                className='gap-2'
                form={form}
                autoComplete='off'
                layout='inline'
                onFinish={(value) => {
                    const val = {
                        ...value,
                        period: value.period
                            ? [value.period[0].format('YYYY-MM-DD'), value.period[1].format('YYYY-MM-DD')]
                            : undefined,
                        page: 0,
                    }
                    setQuery(val)
                }}
            >
                <Item name='code' className='w-48'>
                    <Input placeholder='病例编号' allowClear />
                </Item>
                <Item name='name' className='w-48'>
                    <Input placeholder='病例名称' allowClear />
                </Item>
                <Item name='catalogId' className='w-48'>
                    <Select allowClear placeholder='病例分类' options={options} />
                </Item>
                <Item name='mainSymptom' className='w-48'>
                    <Input placeholder='病情描述' allowClear />
                </Item>
                <Item name='pharmacyName' className='w-48'>
                    <Input placeholder='录入鱼药店' allowClear />
                </Item>

                <Item name='period' className='w-60'>
                    <RangePicker placeholder={['录入时间', '时间范围']} allowClear />
                </Item>
                <Item>
                    <Button type='primary' htmlType='submit' ghost>
                        查询
                    </Button>
                </Item>
            </Form>

            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    showSizeChanger: false,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
        </Wrap>
    )
}
