/*
 * @LastEditTime: 2022-09-27 14:41:43
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\FullStatistics2\Patrol\TaskHistory.js
 */
import { Loading, ScrollBoard } from '@jiaminghi/data-view-react'
import dayjs from 'dayjs'
import { isNil } from 'ramda'
import { useQuery } from 'react-query'
import { pharmacyTask } from 'service'

export default function Main({ pharmacyId, onRowClick }) {
    const { data, isFetching } = useQuery(
        ['pharmacy-task', 'his', pharmacyId],
        () => pharmacyTask.his({ pharmacyId, taskType: 'plan', state: 2 }),
        {
            placeholderData: [],
            enabled: !!pharmacyId,
        }
    )

    const handleClick = ({ rowIndex }) => {
        onRowClick(data[rowIndex].id)
    }

    return (
        <div className='h-full w-full flex-shrink-0'>
            {isNil(pharmacyId) ? (
                <div className='text-white h-full fond-bold flex items-center justify-center text-base'>
                    请先选择药店
                </div>
            ) : isFetching ? (
                <Loading />
            ) : (
                <ScrollBoard
                    className='w-full h-full text-sm'
                    config={{
                        header: ['巡查时间', '巡查员'],
                        data: data.map((x) => [dayjs(x.completeTime).format('YYYY年M月D日'), x.patrollerName]),
                        rowNum: 10,
                        headerBGC: 'transparent',
                        oddRowBGC: 'transparent',
                        evenRowBGC: 'transparent',
                        hoverPause: false,
                    }}
                    onClick={handleClick}
                ></ScrollBoard>
            )}
        </div>
    )
}
