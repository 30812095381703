/**
 * 鱼药店病例详情
 */

import { Descriptions, Divider, Button, Modal, Image, Spin, message, Space } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import tw from 'twin.macro'
import { useQuery, useMutation } from 'react-query'
import { isNil } from 'ramda'

import { symptom } from 'service'
import { Decrypt } from 'utils/encrypt'

const { Item } = Descriptions

const Wrap = tw.div`w-full h-full relative overflow-y-auto`
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b bg-white sticky top-0 text-xl gap-2 z-10`
const Content = tw.div`space-y-3 p-4`

export default function Detail() {
    const { id } = useParams()
    const navigate = useNavigate()

    const { isLoading, data } = useQuery(['symptom', 'one', id], () => symptom.one(Decrypt(id)), {
        enabled: !!id,
    })

    const addskMutation = useMutation(symptom.addsk, {
        onSuccess: () => {
            message.success('添加成功')
        },
    })

    const handleAddsk = () => {
        Modal.confirm({
            title: '提醒',
            content: '是否确认将该病例添加到总病例库？添加后将自动隐藏养殖户相关信息。',
            onOk: () => addskMutation.mutate(Decrypt(id)),
        })
    }

    if (isLoading) {
        return <Spin />
    }

    return (
        <Wrap>
            <Header>
                <div className='h-full flex items-center'>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
                    查看病例
                </div>
                <Button type='primary' onClick={handleAddsk}>
                    添加到总病例库
                </Button>
            </Header>

            <Content>
                <Divider>病症信息</Divider>
                <Descriptions
                    column={2}
                    layout='vertical'
                    size='small'
                    labelStyle={{ width: '300px', color: '#9ca3af' }}
                >
                    <Item label='养殖户'>{data.fisher}</Item>
                    <Item label='联系电话'>{data.fisherPhone}</Item>
                    <Item label='养殖鱼塘'>{data.pondName}</Item>
                    <Item label='来店时间'>{data.comingTime}</Item>
                    <Item label='病例名称'>{data.name}</Item>
                    <Item label='病例分类'>{data.catalogName}</Item>
                    <Item label='患病鱼种' span={2}>
                        {data.fryTypeName}
                    </Item>
                    <Item label='病例图片' span={2}>
                        <Space>
                            {data.attachmentVOList?.map((item) => (
                                <Image
                                    key={item.key}
                                    width={150}
                                    height={150}
                                    src={item.url}
                                    style={{ objectFit: 'cover' }}
                                />
                            ))}
                        </Space>
                    </Item>
                    <Item label='病情描述' span={2}>
                        {data.mainSymptom}
                    </Item>
                    <Item label='录入鱼药店'>{data.pharmacyName}</Item>
                    <Item label='录入人'>{data.createBy}</Item>
                    <Item label='录入时间'>{data.createTime}</Item>
                </Descriptions>
                {!isNil(data.treatVO) && (
                    <>
                        <Divider>治疗方案</Divider>
                        <Descriptions column={1} layout='vertical' size='small'>
                            <Item label='治疗方式'>{data.treatVO?.treatTypeName}</Item>
                            <Item label='治疗方案'>{data.treatVO?.treatPlan}</Item>
                        </Descriptions>
                    </>
                )}
            </Content>
        </Wrap>
    )
}
