/*
 * @LastEditTime: 2022-08-26 10:26:58
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Shop/TestingOrgan/TestingOrganInfo/Update.js
 */
import { Form, Input, Switch, Select } from 'antd'
import { isEmpty } from 'ramda'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { account, role } from 'service'
import { TEST_APPID } from 'utils/const'

const { Item, useForm } = Form

export default function AccountCreate({ current, onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()

    const { data } = useQuery(['role', 'list', TEST_APPID], () => role.list({ appid: TEST_APPID }), {
        placeholderData: [],
    })

    const createMutation = useMutation(account.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('account')
            onClose()
        },
    })

    const getSelectValue = (_, values) => {
        return isEmpty(values) ? [] : values?.map((op) => op.children.props.value)
    }

    const onSubmit = (values) => {
        createMutation.mutate({
            ...values,
            id: current.id,
            appid: current.appid,
            testingOrganId: current.testingOrganId,
        })
    }

    return (
        <Form
            initialValues={{ ...current, roleVOList: current.roleVOList.map((x) => ({ ...x, value: x.id })) }}
            form={form}
            name='account-drawer-form'
            onFinish={onSubmit}
            layout='vertical'
            autoComplete='off'
        >
            <Item hidden label='是否可用' name='enabled' initialValue={true}>
                <Input />
            </Item>
            <Item label='帐号' name='account' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item
                label='角色'
                name='roleVOList'
                rules={[{ required: true, message: '必填' }]}
                getValueFromEvent={getSelectValue}
            >
                <Select mode='multiple'>
                    {data.map((x) => (
                        <Select.Option key={x.id} value={x.id}>
                            <div value={{ id: x.id, value: x.id, name: x.name }}>{x.name}</div>
                        </Select.Option>
                    ))}
                </Select>
            </Item>
            <Item label='姓名' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='手机号' name='mobile' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item
                label='账号状态'
                name='enabled'
                valuePropName='checked'
                rules={[{ required: true, message: '请输入选择类型' }]}
            >
                <Switch />
            </Item>
        </Form>
    )
}
