/*
 * @LastEditTime: 2022-08-30 17:46:04
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Disease/Total/Detail.js
 */
/**
 * 总病例库详情
 */

import { Descriptions, Button, Image, Spin, Space, Divider } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import tw from 'twin.macro'
import { useQuery } from 'react-query'
import { isNil } from 'ramda'

import { symptomKn } from 'service'
import { Decrypt } from 'utils/encrypt'

const { Item } = Descriptions

const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center border-b bg-white sticky top-0 text-xl gap-2 z-10`
const Content = tw.div`p-4 space-y-4`

export default function Detail() {
    const { id } = useParams()
    const navigate = useNavigate()

    const { isLoading, data } = useQuery(['symptom-kn', 'one', id], () => symptomKn.one(Decrypt(id)), {
        enabled: !!id,
    })

    if (isLoading) {
        return <Spin />
    }

    return (
        <Wrap>
            <Header>
                <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
                查看病例
            </Header>
            <Content>
                <Divider>病例信息</Divider>
                <Descriptions column={2} bordered labelStyle={{ width: '200px' }}>
                    <Item label='病例名称'>{data.name}</Item>
                    <Item label='病例编号'>{data.code}</Item>
                    <Item label='鱼病分类'>{data.catalogName}</Item>
                    <Item label='添加时间'>{data.createTime}</Item>
                    <Item label='患病鱼种' span={2}>
                        {data.fryTypeName}
                    </Item>
                    <Item label='病例图片' span={2}>
                        <Space>
                            {data.attachmentVOList?.map((item) => (
                                <Image
                                    key={item.key}
                                    width={150}
                                    height={150}
                                    src={item.url}
                                    style={{ objectFit: 'cover' }}
                                />
                            ))}
                        </Space>
                    </Item>
                    <Item label='病情描述' span={2}>
                        {data.mainSymptom}
                    </Item>
                </Descriptions>
                {!isNil(data.treatVO) && (
                    <>
                        <Divider>治疗方案</Divider>
                        <Descriptions column={1} bordered labelStyle={{ width: '200px' }}>
                            <Item label='治疗方式'>{data.treatVO.treatTypeName}</Item>
                            <Item label='治疗方案'>{data.treatVO.treatPlan}</Item>
                        </Descriptions>
                    </>
                )}
            </Content>
        </Wrap>
    )
}
