/*
 * @LastEditTime: 2022-10-08 07:51:30
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\App.js
 */
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useQueries } from 'react-query'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { QueryParamProvider } from 'use-query-params'
import { isEmpty, isNil } from 'ramda'
import { useEffect, useMemo } from 'react'
import { message } from 'antd'

import { user, purview } from 'service'
import { menusAtom, userInfoAtom, purviewTreeAtom, purviewsAtom } from 'store/auth'

// 框架页面
import Main from 'pages/Main'
import Login from 'pages/Login'

// 数据大屏
import FullStatistics from 'pages/FullStatistics'
import FullStatistics2 from 'pages/FullStatistics2'

//业务页面
import Dashboard from 'pages/Dashboard'
import InformationType from 'pages/Information/InformationType'
import InformationContent from 'pages/Information/InformationContent'
import TaskTarget from 'pages/Task/Target'
import TaskList from 'pages/Task/List'
import TaskDetail from 'pages/Task/List/Detail'
import Inspector from 'pages/User/Inspector'
import Patroller from 'pages/User/Salesman'
import Expert from 'pages/User/Expert'
import Fisher from 'pages/User/Fisher'
import SystemAccount from 'pages/System/Account'
import SystemRole from 'pages/System/Role'
import DrugsType from 'pages/Drugs/Type'
import DrugsCategory from 'pages/Drugs/Category'
import MedicationRecord from 'pages/Medication/Record'
import MedicationRecordDetail from 'pages/Medication/Record/Detail'
import DiseaseType from 'pages/Disease/Type'
import DiseaseFishShop from 'pages/Disease/FishShop'
import DiseaseFishShopDetail from 'pages/Disease/FishShop/Detail'
import DiseaseTotal from 'pages/Disease/Total'
import DiseaseTotalDetail from 'pages/Disease/Total/Detail'
import Pharmacy from 'pages/Shop/Fish'
import TestingOrgan from 'pages/Shop/TestingOrgan'
import TaskStoreTarget from 'pages/Task/TaskStoreTarget'
import TaskStoreList from 'pages/Task/TaskStoreList'
import PharmacyTaskDetail from 'pages/Task/TaskStoreList/Detail'
import FishType from 'pages/BasicData/FishType'
import FishPond from 'pages/BasicData/Pond'
import AreaManager from 'pages/BasicData/Area'
import ShopInspector from 'pages/Statistics/ShopInspector'
import Drugs from 'pages/Statistics/Drugs'
import FishIll from 'pages/Statistics/FishIll'
import Mechanism from 'pages/Statistics/Mechanism'

import GradeRecord from 'pages/Grade/Record'
import TestingCapability from 'pages/BasicData/TestingCapability'
import DetectionRecord from 'pages/Detection/Record'
import DetectionRecordDetail from 'pages/Detection/Record/Detail'

import CertificateRecord from 'pages/Certificate/Record'
import CertificateDetail from 'pages/Certificate/Record/Detail'
import CertificateSetting from 'pages/Certificate/Setting'
import CertificateBatch from 'pages/Certificate/Batch'

//错误页面
import ErrorPage from 'components/ErrorPage'
import { OPS_APPID } from 'utils/const'
import { Loading } from '@jiaminghi/data-view-react'

const routesMap = {
    '/information/type': InformationType,
    '/information/content': InformationContent,
    '/task/target': TaskTarget,
    '/task/list': TaskList,
    '/user/inspector': Inspector,
    '/user/salesman': Patroller,
    '/user/professor': Expert,
    '/user/fishman': Fisher,
    '/system/account': SystemAccount,
    '/system/role': SystemRole,
    '/drugs/type': DrugsType,
    '/drugs/category': DrugsCategory,
    '/medication/record': MedicationRecord,
    '/disease/type': DiseaseType,
    '/disease/fish-shop': DiseaseFishShop,
    '/disease/total': DiseaseTotal,
    '/shop/fish': Pharmacy,
    '/shop/testing': TestingOrgan,
    '/task/store-target': TaskStoreTarget,
    '/task/store-list': TaskStoreList,
    '/basicdata/fishtype': FishType,
    '/basicdata/pond': FishPond,
    '/basicdata/area': AreaManager,
    '/basicdata/testing-capability': TestingCapability,
    '/statistics/shopinspector': ShopInspector,
    '/statistics/drugs': Drugs,
    '/statistics/fishill': FishIll,
    '/statistics/mechanism': Mechanism,
    '/grade/record': GradeRecord,
    '/detection/record': DetectionRecord,
    '/certificate/setting': CertificateSetting,
    '/certificate/record': CertificateRecord,
    '/certificate/batch': CertificateBatch,
}

const RouteAdapter = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const adaptedHistory = useMemo(
        () => ({
            replace(location) {
                navigate(location, { replace: true, state: location.state })
            },
            push(location) {
                navigate(location, { replace: false, state: location.state })
            },
        }),
        [navigate]
    )
    return children({ history: adaptedHistory, location })
}

const renderRoutes = (list) => {
    return list.map((item) => {
        if (item.children) {
            return (
                <Route key={item.id} path={item.path}>
                    {renderRoutes(item.children)}
                </Route>
            )
        } else {
            const Component = item.disabled ? () => <ErrorPage status='403' title='无权访问' /> : routesMap[item.key]
            return isNil(Component) ? null : <Route key={item.id} path={item.path} element={<Component />} />
        }
    })
}

export default function App() {
    const [userInfo, setUserInfo] = useRecoilState(userInfoAtom)
    const setPurviewTree = useSetRecoilState(purviewTreeAtom)
    const menus = useRecoilValue(menusAtom)
    const purviews = useRecoilValue(purviewsAtom)
    const navigate = useNavigate()

    const [{ isLoading }, { isLoading: purviewLoading }] = useQueries([
        {
            queryKey: ['current'],
            queryFn: () => user.getCurrent(),
            enabled: !!localStorage.getItem('Authorization'),
            onSuccess: (data) => setUserInfo(data),
            onError: (error) => {
                message.error(`${error.msg}，请重新登录`)
                navigate('/login', { replace: true })
            },
        },
        {
            queryKey: ['purview', 'tree', OPS_APPID],
            queryFn: () => purview.tree({ appid: OPS_APPID }),
            enabled: !isEmpty(userInfo),
            onSuccess: (data) => setPurviewTree(data),
        },
    ])

    useEffect(() => {
        // 如果没有token则直接跳转登录页面
        if (!localStorage.getItem('Authorization')) {
            navigate('/login', { replace: true })
        }
        //eslint-disable-next-line
    }, [])

    if (isLoading || purviewLoading) {
        return (
            <div className='h-screen w-screen'>
                <Loading />
            </div>
        )
    }

    return (
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
            <Routes>
                <Route path='/' element={<Main />}>
                    <Route index element={<Navigate to='dashboard' replace />}></Route>
                    <Route path='/dashboard' element={<Dashboard />} />
                    {renderRoutes(menus)}
                    <Route path='/task/detail/:taskId' element={<TaskDetail />} />
                    <Route path='/pharmacy-task/detail/:taskId' element={<PharmacyTaskDetail />} />
                    <Route path='/medication/record/:recordId' element={<MedicationRecordDetail />} />
                    <Route path='/disease/fish-shop/:id' element={<DiseaseFishShopDetail />} />
                    <Route path='/disease/total/:id' element={<DiseaseTotalDetail />} />
                    <Route path='/detection/record/detail/:recordId' element={<DetectionRecordDetail />} />
                    <Route path='/certificate/record/detail/:certificateId' element={<CertificateDetail />} />
                </Route>
                {purviews.includes('/full-statistics') && (
                    <>
                        <Route path='full-statistics' element={<FullStatistics />} />
                        <Route path='full-statistics2' element={<FullStatistics2 />} />
                    </>
                )}
                <Route path='/login' element={<Login />}></Route>
                <Route path='*' element={<ErrorPage status='404' title='找不到模块' />} />
            </Routes>
        </QueryParamProvider>
    )
}
