import { Button, Form, Input, message, DatePicker, TreeSelect, Table } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { length } from 'ramda'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { area, pharmacyTaskTarget } from 'service'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function Create({ onClose }) {
    const queryClient = useQueryClient()

    const { data } = useQuery(['area', 'tree', 'street'], () => area.tree({ level: 'street' }), {
        placeholderData: [],
    })

    const createMutation = useMutation(pharmacyTaskTarget.create, {
        onSuccess: () => {
            message.success('新增成功')
            queryClient.invalidateQueries(['pharmacy-task-target'])
            onClose()
        },
    })

    const handleTreeSelect = (_, label) => {
        return label.map(({ props }) => props.value)
    }

    // 保存
    const onSubmit = (values) => {
        createMutation.mutate({ ...values, endTime: values.endTime.format('YYYY-MM-DD') })
    }

    return (
        <Form name='patroller-target-form' onFinish={onSubmit} autoComplete='off' layout='vertical'>
            <Item label='任务目标名称' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='任务截止日期' name='endTime' rules={[{ required: true, message: '必填' }]}>
                <DatePicker className='w-full' />
            </Item>
            <Item
                label='任务范围'
                name='scopeVOList'
                rules={[{ required: true, message: '必填' }]}
                getValueFromEvent={handleTreeSelect}
            >
                <TreeSelect treeCheckable allowClear>
                    {renderTreeNode(data)}
                </TreeSelect>
            </Item>
            <Item label='任务要求内容' required>
                <Form.List
                    name='stepsVOList'
                    rules={[{ required: true, message: '必填' }]}
                    initialValue={[{ step: 1, remark: '' }]}
                >
                    {(fields, { add, remove }) => (
                        <>
                            <Table
                                dataSource={fields}
                                pagination={false}
                                columns={[
                                    {
                                        title: '任务步骤',
                                        key: 'step',
                                        render: (_, __, index) => (
                                            <>
                                                <Item
                                                    className='!mb-0'
                                                    hidden
                                                    name={[fields[index].name, 'step']}
                                                    {...fields[index].restField}
                                                    initialValue={index + 1}
                                                >
                                                    <Input />
                                                </Item>
                                                {index + 1}
                                            </>
                                        ),
                                    },
                                    {
                                        title: '任务说明',
                                        key: 'remark',
                                        render: (_, __, index) => (
                                            <Item
                                                className='!mb-0'
                                                name={[fields[index].name, 'remark']}
                                                rules={[{ required: true, message: '必填' }]}
                                            >
                                                <Input placeholder='单行输入' />
                                            </Item>
                                        ),
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        render: (_, __, index) => (
                                            <Button
                                                type='text'
                                                icon={<MinusCircleOutlined />}
                                                onClick={() => remove(fields[index].name)}
                                                disabled={length(fields) === 1}
                                            />
                                        ),
                                    },
                                ]}
                            />
                            <Item className='!mt-4'>
                                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    添加任务要求内容
                                </Button>
                            </Item>
                        </>
                    )}
                </Form.List>
            </Item>
        </Form>
    )
}
