/*
 * @LastEditTime: 2022-09-06 14:08:42
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Main/Menu.js
 */
import { Menu, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { split } from 'ramda'
import { useRecoilValue } from 'recoil'

import { menusAtom } from 'store/auth'

const { Sider } = Layout

export default function Main({ collapsed }) {
    const [selectedKey, setSelectedKey] = useState('')
    const menus = useRecoilValue(menusAtom)
    const location = useLocation()
    const navigate = useNavigate()

    const handleClick = ({ key }) => {
        navigate(key)
    }

    useEffect(() => {
        setSelectedKey(location.pathname)
    }, [location])

    return (
        <Sider collapsed={collapsed} className='h-full'>
            <div className='h-[64px] text-white text-xl flex items-center justify-center'>后台管理系统</div>
            <Menu
                className='h-[calc(100vh-64px)] overflow-y-auto'
                defaultOpenKeys={[`/${split('/', location.pathname)[1]}`]}
                selectedKeys={[selectedKey]}
                mode='inline'
                theme='dark'
                items={menus}
                onClick={handleClick}
            />
        </Sider>
    )
}
