/*
 * @LastEditTime: 2022-09-29 10:00:56
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/User/Salesman/Leave.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Descriptions, Form, Select, Modal, Radio, Input, Divider } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { findLast, propEq } from 'ramda'
import { useMutation, useQuery } from 'react-query'
import { leave } from 'service'

import { userInfoAtom } from 'store/auth'
import { useRecoilValue } from 'recoil'

const Wrap = tw.div`w-full h-full`
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b sticky top-0 bg-white z-10`
const Content = tw.div`p-4 space-y-4`
const { Item, useForm } = Form
const { TextArea } = Input

const options = [
    { label: '待审批', value: 'pending' },
    { label: '已通过', value: 'passed' },
    { label: '已拒绝', value: 'fail' },
]

export default function Leave({ current, onClose }) {
    const [leaveInfo, setLeave] = useState()
    const userInfo = useRecoilValue(userInfoAtom)

    const [form1] = useForm()

    const [query, setQuery] = useState({
        page: 0,
        size: 10,
        userId: current.bindId,
        accountId: userInfo.id,
    })

    const [isModalVisible, setIsModalVisible] = useState(false)

    const { data, isFetching, refetch } = useQuery(['leave-page', query], () => leave?.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const approvalMutation = useMutation(leave.approval, {
        onSuccess: refetch,
    })

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const approval = (value) => {
        approvalMutation.mutate({ id: leaveInfo.id, payload: { ...value, accountId: userInfo.id } })
    }

    const columns = [
        {
            title: '请假开始时间',
            dataIndex: 'leaveBegin',
        },
        {
            title: '请假结束时间',
            dataIndex: 'leaveEnd',
        },
        {
            title: '请假时长',
            dataIndex: 'duration',
        },
        {
            title: '审批状态',
            key: 'enabled',
            render: (r) => findLast(propEq('value', r.leaveStatus))(options).label,
        },
        {
            title: '审批人',
            dataIndex: 'accountName',
        },
        {
            title: '审批时间',
            dataIndex: 'approvalTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => {
                            showModal()
                            setLeave(r)
                        }}
                    >
                        查看详情
                    </Button>
                </Space>
            ),
        },
    ]
    return (
        <Wrap>
            <Header>
                <Space>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={onClose} />
                    <span className='text-xl font-bold'>{current.name}的请假管理</span>
                </Space>
            </Header>
            <Content>
                <Form className='gap-2' autoComplete='off' layout='inline' onFinish={(value) => setQuery(value)}>
                    <Item name='leaveStatus' className='w-48'>
                        <Select placeholder='审批状态' allowClear options={options} />
                    </Item>
                    <Item>
                        <Button type='primary' ghost htmlType='submit'>
                            查询
                        </Button>
                    </Item>
                </Form>
                <Table
                    rowKey='id'
                    dataSource={data?.content}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                        total: data?.totalElements,
                        pageSize: query.size,
                        current: query.page + 1,
                        showSizeChanger: false,
                        onChange: (page) => setQuery({ page: page - 1 }),
                    }}
                />
            </Content>
            <Modal
                destroyOnClose
                footer={null}
                title='请假详情'
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Descriptions>
                    <Descriptions.Item label='申请人' span={3}>
                        {leaveInfo?.userName}
                    </Descriptions.Item>
                    <Descriptions.Item label='请假开始时间' span={3}>
                        {leaveInfo?.leaveBegin}
                    </Descriptions.Item>
                    <Descriptions.Item label='请假结束时间' span={3}>
                        {leaveInfo?.leaveEnd}
                    </Descriptions.Item>
                    <Descriptions.Item label='请假时长' span={3}>
                        {leaveInfo?.duration}天
                    </Descriptions.Item>
                    <Descriptions.Item label='请假理由' span={3}>
                        {leaveInfo?.reason}
                    </Descriptions.Item>
                    <Descriptions.Item label='申请时间' span={3}>
                        {leaveInfo?.createTime}
                    </Descriptions.Item>
                    <Descriptions.Item label='状态' span={3}>
                        {findLast(propEq('value', leaveInfo?.leaveStatus))(options)?.label}
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
                <div className='flex flex-col'>
                    {leaveInfo?.leaveStatus === 'pending' ? (
                        <Form
                            form={form1}
                            className='gap-2'
                            autoComplete='off'
                            layout='horizontal'
                            onFinish={(value) => {
                                handleOk()
                                approval(value)
                            }}
                        >
                            <Item
                                name='approvalStatus'
                                className='w-48'
                                label='审批'
                                rules={[{ required: true, message: '选择' }]}
                            >
                                <Radio.Group>
                                    <Radio value='true'>同意</Radio>
                                    <Radio value='false'>拒绝</Radio>
                                </Radio.Group>
                            </Item>
                            <Item
                                name='approvalReason'
                                className='w-96'
                                label='意见'
                                rules={[{ required: true, message: '请输入' }]}
                            >
                                <TextArea rows={3} />
                            </Item>
                            <Item>
                                <Button type='primary' ghost htmlType='submit'>
                                    审批
                                </Button>
                            </Item>
                        </Form>
                    ) : (
                        <Descriptions>
                            <Descriptions.Item label='审批状态' span={3}>
                                {findLast(propEq('value', leaveInfo?.leaveStatus))(options)?.label}
                            </Descriptions.Item>
                            <Descriptions.Item label='审批意见' span={3}>
                                {leaveInfo?.approvalReason}
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                </div>
            </Modal>
        </Wrap>
    )
}
