/*
 * @LastEditTime: 2022-09-29 09:37:15
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/List/FishPond.js
 */
import { Descriptions } from 'antd'
import { head, is } from 'ramda'
import { useMemo } from 'react'

const { Item } = Descriptions
const channel = {
    self: '自销',
    fish: '鱼中',
    corporate: '企业收',
}

export default function Main({ data }) {
    const current = useMemo(() => (data ? (is(Array, data) ? head(data) : data) : {}), [data])
    return (
        <Descriptions bordered column={2} labelStyle={{ width: '300px' }}>
            <Item label='鱼塘编号'>{current.fishPondId}</Item>
            <Item label='鱼塘名称'>{current.fishPondName}</Item>
            <Item label='鱼塘租户'>{current.pondTenant}</Item>
            <Item label='鱼塘租户电话'>{current.pondTenantPhone}</Item>
            <Item label='实际养殖户'>{current.actualFarmers}</Item>
            <Item label='实际养殖户电话'>{current.actualFarmersPhone}</Item>
            <Item label='养殖鱼种'>{current.fryTypeVOSet?.map((x) => x.fryType).join(',')}</Item>
            <Item label='投苗时间'>{current.seedlingsDate}</Item>
            <Item label='投苗数量'>{current.seedlingsNumber}斤</Item>
            <Item label='目标规格'>{current.target}斤</Item>
            <Item label='投苗规格'>{current.seedlingSpecifications}口斤</Item>
            <Item label='目标市场价'>{current.marketPrice}元/斤</Item>
            <Item label='计划收鱼时间'>{current.planHarvestTime}</Item>
            <Item label='销售渠道'>{channel[current.channel]}</Item>
            <Item label='巡查员'>{current.inspectUserName}</Item>
            <Item label='提交时间'>{current.createTime}</Item>
        </Descriptions>
    )
}
