/*
 * @LastEditTime: 2022-09-22 10:22:51
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/List/Agriculture.js
 */
import { Checkbox, Descriptions } from 'antd'
import { head, is } from 'ramda'
import { useMemo } from 'react'

const { Item } = Descriptions

const inspectTypeMap = {
    inspect: '巡访检查',
    visit: '明察暗访',
    flight: '飞行检查',
}

export default function Main({ data }) {
    const current = useMemo(() => (data ? (is(Array, data) ? head(data) : data) : {}), [data])
    return (
        <Descriptions bordered column={2} labelStyle={{ width: '300px' }}>
            <Item label='检查类型'>{inspectTypeMap[current.inspectType]}</Item>
            <Item label='检查地点'>{current.address}</Item>
            <Item label='被检查单位名称'>{current.company}</Item>
            <Item label='类别' span={2}>
                {current.catalogName}
            </Item>
            <Item label='生产（经营）品种'>{current.variety}</Item>
            <Item label='规模（面积/库存等）'>{current.scale}</Item>
            <Item span={2} label='环境清洁，田间、沟渠、池边、养殖场是否无农兽药、肥料、饲料等投入品包装废弃物'>
                <Checkbox checked={current.cleaned} />
            </Item>
            <Item span={2} label='告知书、承诺书妥善存放'>
                <Checkbox checked={current.stored} />
            </Item>
            <Item span={2} label='是否建立完善的生产经营管理制度、标准、操作规范等'>
                <Checkbox checked={current.perfect} />
            </Item>
            <Item span={2} label='生产档案记录是否规范'>
                <Checkbox checked={current.standard} />
            </Item>
            <Item span={2} label='是否发现禁限用药物、不规范药品、肥料和饲料等'>
                <Checkbox checked={current.forbidden} />
            </Item>
            <Item span={2} label='农资存放是否规范整洁，进出库记录是否完善'>
                <Checkbox checked={current.whole} />
            </Item>
            <Item span={2} label='主要负责人是否了解农产品质量管理的相关法律、法规及相关禁止性规定'>
                <Checkbox checked={current.learn} />
            </Item>
            <Item span={2} label='销售的农产品附有《广东省使用农产品合格证》'>
                <Checkbox checked={current.attached} />
            </Item>
            <Item span={2} label='主要问题'>
                {current.problem}
            </Item>
            <Item span={2} label='整改意见'>
                {current.opinion}
            </Item>
            <Item span={2} label='限制整改时间'>
                {current.rectificationDate}
            </Item>
            <Item span={2} label='备注'>
                {current.remark}
            </Item>
            <Item label='巡查员'>{current.inspectUserName}</Item>
            <Item label='巡查时间'>{current.createTime}</Item>
        </Descriptions>
    )
}
