/*
 * @LastEditTime: 2022-09-22 17:47:28
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/PondInfo.js
 */
import { useQuery } from 'react-query'
import { isNil } from 'ramda'

import TimeRender from 'components/TimeRender'
import { fishPond } from 'service'
import { Loading } from '@jiaminghi/data-view-react'

export default function Main({ pondId }) {
    const { data, isFetching } = useQuery(['fish-pond', 'one', pondId], () => fishPond.one(pondId), {
        placeholderData: {},
        enabled: !!pondId,
    })
    return (
        <div className='flex-shrink-0 w-full h-full p-2 text-[#daf4ef] overflow-y-auto'>
            {isNil(pondId) ? (
                <div className='text-white h-full fond-bold flex items-center justify-center text-base'>
                    请先选择鱼塘
                </div>
            ) : isFetching ? (
                <Loading />
            ) : (
                <>
                    <div className='leading-10 w-full text-xl font-blod'>{data.pondName}</div>
                    <div className='flex flex-col justify-between gap-2'>
                        <div>{data.location}</div>
                        <div className='grid grid-cols-2 gap-2'>
                            <div>
                                <div className='text-[#17cee8]'>鱼塘租户</div>
                                <div>{data.pondTenant}</div>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>租户电话</div>
                                <div>{data.pondTenantPhone}</div>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>鱼塘养殖户</div>
                                <div>{data.actualFarmers}</div>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>养殖户电话</div>
                                <div>{data.actualFarmersPhone}</div>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>鱼塘规模</div>
                                <div>{data.pondArea}亩</div>
                            </div>
                        </div>
                        <div>
                            <div className='text-[#17cee8]'>当前鱼种信息</div>
                            <TimeRender>{data.fryTypeVOSet?.map((x) => x.fryType).join('、')}</TimeRender>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
