/*
 * @LastEditTime: 2022-08-30 17:24:53
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/Mechanism/index.js
 */
import tw from 'twin.macro'
import React from 'react'

import TestingCapability from './TestingCapability'
import TestingFish from './TestingFish'
import TestingType from './TestingType'

const Wrap = tw.div`flex flex-col space-y-4 p-4`

export default function Mechanism() {
    return (
        <Wrap>
            <TestingType />
            <TestingFish />
            <TestingCapability />
        </Wrap>
    )
}
