/**
 * 总病例库
 */

import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Form, Input, DatePicker, Select, message, Tooltip } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { useQueryClient, useQueries, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { isNil } from 'ramda'
import dayjs from 'dayjs'

import { useDisclosure } from 'utils/useDisclosure'
import ConfirmButton from 'components/ConfirmButton'
import { symptomCatalog, symptomKn } from 'service'
import { Enscrypt } from 'utils/encrypt'

import Create from './Create'
import Update from './Update'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { RangePicker } = DatePicker
const { Item } = Form

const Publish = ({ current, onClose }) => {
    return isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />
}

export default function Main() {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        name: StringParam,
        code: StringParam,
        catalogId: StringParam,
        period: ArrayParam,
        mainSymptom: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const [{ data: options }, { data, isFetching }] = useQueries([
        {
            queryKey: ['symptom-catalog', 'list'],
            queryFn: () => symptomCatalog.list(),
            placeholderData: [],
            select: (data) => data.map((x) => ({ label: x.name, value: x.id })),
        },
        {
            queryKey: ['symptom-kn', 'page', query],
            queryFn: () => symptomKn.page(query),
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        },
    ])

    const deleteMutation = useMutation(symptomKn.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(['symptom-kn', 'page'])
            message.success('删除成功')
        },
    })

    const handleOpenDetail = (item) => {
        const id = Enscrypt(item.id)
        navigate(`/disease/total/${id}`)
    }

    const addIllness = () => {
        setCurrent(null)
        onOpen()
    }
    const updateIllness = (item) => {
        setCurrent(item.id)
        onOpen()
    }

    const columns = [
        {
            title: '病例编号',
            dataIndex: 'code',
        },
        {
            title: '病例名称',
            dataIndex: 'name',
        },
        {
            title: '病例分类',
            dataIndex: 'catalogName',
            width: 100,
        },
        {
            title: '病情描述',
            dataIndex: 'mainSymptom',
            ellipsis: { showTitle: false },
            render: (text) => (
                <Tooltip placement='topLeft' title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: '添加时间',
            dataIndex: 'createTime',
        },
        {
            title: '治疗方式',
            dataIndex: 'treatVO',
            render: (x) => x?.treatTypeName,
        },
        {
            title: '操作',
            key: 'operate',
            width: 180,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleOpenDetail(r)}>
                        详情
                    </Button>
                    <Button type='link' size='small' onClick={() => updateIllness(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]

    return isOpen ? (
        <Publish current={current} onClose={onClose} />
    ) : (
        <Wrap>
            <Form
                className='gap-2'
                form={form}
                autoComplete='off'
                layout='inline'
                onFinish={(value) => {
                    setQuery({
                        ...value,
                        period: !isNil(value.period)
                            ? [
                                  dayjs(value.period[0]).format('YYYY-MM-DD 00:00:00'),
                                  dayjs(value.period[1]).format('YYYY-MM-DD 23:59:59'),
                              ]
                            : undefined,
                        page: 0,
                    })
                }}
            >
                <Item name='code' className='w-48'>
                    <Input placeholder='病例编号' allowClear />
                </Item>
                <Item name='name' className='w-48'>
                    <Input placeholder='病例名称' allowClear />
                </Item>
                <Item name='catalogId' className='w-48'>
                    <Select allowClear placeholder='病例分类' options={options} />
                </Item>
                <Item name='mainSymptom' className='w-48'>
                    <Input placeholder='病情描述' allowClear />
                </Item>
                <Item name='period' className='w-60'>
                    <RangePicker placeholder={['开始时间', '结束时间']} />
                </Item>
                <Item>
                    <Button type='primary' htmlType='submit' ghost>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={addIllness}>
                        添加病例
                    </Button>
                </Item>
            </Form>

            <Table
                rowKey='id'
                loading={isFetching}
                dataSource={data.content}
                columns={columns}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
        </Wrap>
    )
}
