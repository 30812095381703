/*
 * @LastEditTime: 2022-08-26 17:41:55
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/TaskStoreTarget/Detail.js
 */
import { Form, Input, DatePicker, Table, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'

import { area } from 'service'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function Detail({ current }) {
    const { data } = useQuery(['area', 'tree', 'street'], () => area.tree({ level: 'street' }), {
        placeholderData: [],
    })

    return (
        <Form
            autoComplete='off'
            layout='vertical'
            initialValues={{
                ...current,
                endTime: dayjs(current.endTime),
                scopeVOList: current.scopeVOList.map((x) => ({ ...x, value: x.areaPaths })),
            }}
            disabled
        >
            <Item label='任务目标名称' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='任务截止日期' name='endTime' rules={[{ required: true, message: '必填' }]}>
                <DatePicker className='w-full' />
            </Item>
            <Item label='任务范围' name='scopeVOList' rules={[{ required: true, message: '必填' }]}>
                <TreeSelect treeCheckable allowClear>
                    {renderTreeNode(data)}
                </TreeSelect>
            </Item>
            <Item label='任务要求内容' required>
                <Form.List
                    name='stepsVOList'
                    rules={[{ required: true, message: '必填' }]}
                    initialValue={[{ step: 1, remark: '' }]}
                >
                    {(fields) => (
                        <Table
                            dataSource={fields}
                            pagination={false}
                            columns={[
                                {
                                    title: '任务步骤',
                                    key: 'step',
                                    render: (_, __, index) => (
                                        <>
                                            <Item
                                                className='!mb-0'
                                                hidden
                                                name={[fields[index].name, 'step']}
                                                {...fields[index].restField}
                                                initialValue={index + 1}
                                            >
                                                <Input />
                                            </Item>
                                            {index + 1}
                                        </>
                                    ),
                                },
                                {
                                    title: '任务说明',
                                    key: 'remark',
                                    render: (_, __, index) => (
                                        <Item
                                            className='!mb-0'
                                            name={[fields[index].name, 'remark']}
                                            rules={[{ required: true, message: '必填' }]}
                                        >
                                            <Input placeholder='单行输入' />
                                        </Item>
                                    ),
                                },
                            ]}
                        />
                    )}
                </Form.List>
            </Item>
        </Form>
    )
}
