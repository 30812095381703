/*
 * @LastEditTime: 2022-09-09 09:13:23
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/System/Role/Update.js
 */
import { useState } from 'react'
import { Divider, Form, Input, Spin, Tree, Radio } from 'antd'
import { useMutation, useQueryClient, useQueries } from 'react-query'

import { role, purview } from 'service'
import { OPS_APPID, TEST_APPID, YYD_APPID } from 'utils/const'

const { Item, useForm, useWatch } = Form

export default function RoleUpdate({ current, onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()
    const [checkedKeys, setCheckedKeys] = useState([])
    const appid = useWatch('appid', form)

    const [{ data: treeData, isLoading }, { data: roleDetail, isFetching }] = useQueries([
        {
            queryKey: ['preview-tree', appid],
            queryFn: () => purview.tree({ appid }),
            placeholderData: [],
            enabled: !!appid,
        },
        {
            queryKey: ['role-one', current.id],
            queryFn: () => role.one(current.id),
            placeholderData: {},
            cacheTime: 0,
            onSuccess: (data) => setCheckedKeys({ checked: data.purviewVOList?.map((item) => item.id) ?? [] }),
        },
    ])

    const updateMutation = useMutation(role.update, {
        onSuccess: () => {
            queryClient.invalidateQueries(['role-page'])
            onClose()
        },
    })

    const onSubmit = (values) => {
        updateMutation.mutate({
            ...values,
            id: current.id,
            appid: OPS_APPID,
            purviewVOList: checkedKeys.checked?.map((item) => ({ id: item })) ?? [],
        })
    }

    if (isFetching || isLoading) {
        return <Spin />
    }

    return (
        <Form
            form={form}
            name='role-drawer-form'
            onFinish={onSubmit}
            layout='vertical'
            autoComplete='off'
            initialValues={roleDetail}
        >
            <Item label='角色Code' name='code' rules={[{ required: true }]}>
                <Input disabled />
            </Item>
            <Item label='角色名称' name='name' rules={[{ required: true }]}>
                <Input />
            </Item>
            <Item label='所属应用' name='appid' hidden>
                <Radio.Group>
                    <Radio.Button value={OPS_APPID}>管理端</Radio.Button>
                    <Radio.Button value={YYD_APPID}>鱼药店端</Radio.Button>
                    <Radio.Button value={TEST_APPID}>检测机构端</Radio.Button>
                </Radio.Group>
            </Item>
            <Item label='角色说明' name='remark'>
                <Input.TextArea showCount maxLength={200} />
            </Item>
            <Divider orientation='left'>功能权限</Divider>
            <Tree
                defaultExpandAll
                treeData={treeData}
                fieldNames={{ key: 'id', title: 'label' }}
                checkable
                checkStrictly
                checkedKeys={checkedKeys}
                onCheck={(value) => setCheckedKeys(value)}
            />
        </Form>
    )
}
