/*
 * @LastEditTime: 2022-08-26 16:23:42
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/utils/getTreeLen.js
 */
export function getPaths(treeData, value) {
    const valueMap = {}
    const loops = (list, parent) => {
        return (list || []).map(({ children, path, label }) => {
            const node = (valueMap[path] = {
                parent,
                path,
                label,
            })
            node.children = loops(children, node)
            return node
        })
    }

    loops(treeData)

    const getPath = (value) => {
        const path = []
        let current = valueMap[value]
        while (current) {
            path.unshift({ path: current.path, label: current.label })
            current = current.parent
        }
        return path
    }

    return getPath(value)
}
