/*
 * @LastEditTime: 2022-10-26 09:38:40
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Certificate/Record/ProducationLog.js
 */
import { useState } from 'react'
import { Button, Space, Table } from 'antd'
import { useQuery } from 'react-query'
import { task } from 'service'
import { useNavigate } from 'react-router-dom'
import { Enscrypt } from 'utils/encrypt'

export default function ProductionLog({ current }) {
    const navigate = useNavigate()

    const [query] = useState({
        size: 100,
        fishPondId: current.fishPondId,
        completeStartTime: current.seedlingsTime,
        completeEndTime: current.harvestTime,
    })

    const { data, isFetching } = useQuery(
        ['certificate-pond-record', 'list', query],
        () => task.certificatePondRecord(query),
        {
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        }
    )

    const columns = [
        {
            title: '巡塘时间',
            dataIndex: 'completeTime',
        },
        {
            title: '巡查人',
            dataIndex: 'inspectUserName',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => navigate(`/task/detail/${Enscrypt(r.id)}`)}>
                        查看详情
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <Table
            className='mt-4'
            rowKey='id'
            dataSource={data.content}
            columns={columns}
            loading={isFetching}
            pagination={false}
        />
    )
}
