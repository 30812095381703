/*
 * @LastEditTime: 2022-09-09 17:23:31
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Information/InformationContent/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Select, Table, Form, Input, DatePicker, Image, Tag, Modal, message } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useMutation, useQueries, useQueryClient } from 'react-query'
import dayjs from 'dayjs'

import { useDisclosure } from 'utils/useDisclosure'

import ConfirmButton from 'components/ConfirmButton'
import TimeRender from 'components/TimeRender'
import Create from './Create'
import Update from './Update'
import { cmsContent, cmsType } from 'service'

const Wrap = tw.div`w-full h-full space-y-4 relative p-4`
const { Item } = Form
const { RangePicker } = DatePicker

const Publish = ({ current, onClose }) => {
    return isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />
}

export default function InformationContent() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        number: StringParam,
        title: StringParam,
        cmsTypeId: NumberParam,
        start: StringParam,
        stop: StringParam,
        grounding: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const [cmsTypeData, { data, isFetching }] = useQueries([
        { queryKey: ['cms-type', 'list'], queryFn: () => cmsType.list(), placeholderData: [] },
        {
            queryKey: ['cms-content', 'page', query],
            queryFn: () => cmsContent.page(query),
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        },
    ])

    const deleteMutation = useMutation(cmsContent.delete, {
        onSuccess: () => {
            message.success('删除成功')
            queryClient.invalidateQueries('cms-content')
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'number',
            width: 200,
        },
        {
            title: '封面图片',
            key: 'url',
            render: (r) => <Image className='object-contain' width={64} height={64} src={r.url} />,
        },
        {
            title: '资讯标题',
            dataIndex: 'title',
        },
        {
            title: '资讯类型',
            dataIndex: 'cmsTypeName',
        },
        {
            title: '是否推荐',
            key: 'recommend',
            render: (r) => (r.recommend ? '是' : '否'),
        },
        {
            title: '上架状态',
            key: 'grounding',
            render: (r) => (r.grounding ? <Tag color='success'>上架中</Tag> : <Tag color='error'>未上架</Tag>),
        },
        {
            title: '发布人',
            dataIndex: 'publisherName',
        },
        {
            title: '发布时间',
            key: 'publishedTime',
            render: (r) => <TimeRender>{r.publishedTime}</TimeRender>,
        },
        {
            title: '新增时间',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]
    return isOpen ? (
        <Publish current={current} onClose={onClose} />
    ) : (
        <Wrap>
            <Form
                autoComplete='off'
                layout='inline'
                onFinish={(value) => {
                    setQuery({
                        ...value,
                        start: value.time ? value.time[0]?.format('YYYY-MM-DD') : undefined,
                        stop: value.time ? value.time[1]?.format('YYYY-MM-DD') : undefined,
                        time: undefined,
                        page: 0,
                    })
                }}
                initialValues={{ ...query, time: [query.start && dayjs(query.start), query.stop && dayjs(query.stop)] }}
                className='gap-2'
            >
                <Item name='number' className='w-48'>
                    <Input placeholder='资讯编号' allowClear />
                </Item>
                <Item name='title' className='w-48'>
                    <Input placeholder='资讯标题' allowClear />
                </Item>
                <Item name='cmsTypeId' className='w-48'>
                    <Select
                        placeholder='资讯类型'
                        allowClear
                        options={cmsTypeData.data.map((item) => ({ value: item.id, label: item.name }))}
                    />
                </Item>
                <Item name='time' className='w-60'>
                    <RangePicker placeholder={['发布时间', '时间范围']} />
                </Item>
                <Item name='grounding' className='w-48'>
                    <Select
                        placeholder='发布状态'
                        allowClear
                        options={[
                            { label: '上架中', value: 'true' },
                            { label: '未上架', value: 'false' },
                        ]}
                    />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Modal
                visible={isOpen}
                title={isNil(current) ? '新建资讯类型' : '修改资讯类型'}
                onClose={onClose}
                mask={false}
                getContainer={false}
                width='100%'
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Modal>
        </Wrap>
    )
}
