/*
 * @LastEditTime: 2022-09-29 16:18:51
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Grade/Record/Detail.js
 */
import tw from 'twin.macro'
import { useMemo } from 'react'
import { head, map, sum } from 'ramda'

import { Descriptions, Image, Divider, Table } from 'antd'
import { userType } from './TestOrgan'

const { Item } = Descriptions
const Wrap = tw.div`w-full h-[70vh] space-y-4 overflow-y-auto`

export default function DrugStore({ data }) {
    const { pharmacyVO, detailVO } = data

    const expertContent = useMemo(() => detailVO || { optionsVOList: [] }, [detailVO])
    return (
        <Wrap>
            <div className='w-full grid grid-cols-12'>
                <div className='col-span-2 flex items-center justify-center border'>
                    {pharmacyVO?.attachments && (
                        <Image
                            className='object-cover'
                            width='80%'
                            height='80%'
                            src={head(pharmacyVO.attachments)?.url}
                        />
                    )}
                </div>
                <Descriptions className='col-span-10 w-full' column={2} bordered labelStyle={{ width: '200px' }}>
                    <Item label='评级对象名称'>{pharmacyVO?.name}</Item>
                    <Item label='门店负责人'>{pharmacyVO?.principal}</Item>
                    <Item label='药店地址' span={2}>
                        {pharmacyVO?.address}
                    </Item>
                    <Item label='所属镇区'>{pharmacyVO?.areaName}</Item>
                    <Item label='所属行业'>{pharmacyVO?.industry}</Item>
                </Descriptions>
            </div>
            <Divider>评级信息</Divider>
            <Descriptions layout='vertical' column={2}>
                <Item label='评级者身份'>{userType[data?.userType]}</Item>
                <Item label='评级者'>{data?.userName}</Item>
                <Item label='评级分数'>{data?.score}</Item>
                <Item label='评级时间'>{data?.createdTime}</Item>
            </Descriptions>
            {data.userType === 'expertAssess' && (
                <>
                    <Divider>评级内容</Divider>
                    <Table
                        dataSource={expertContent.optionsVOList}
                        bordered
                        pagination={false}
                        columns={[
                            {
                                title: '评价类别',
                                render: (_, __, index) => {
                                    if (index === 0) {
                                        return '场所与设施'
                                    } else if (index === 3) {
                                        return '机构与人员'
                                    } else if (index === 6) {
                                        return '规章制度'
                                    } else if (index === 9) {
                                        return '采购与入库'
                                    } else if (index === 12) {
                                        return '陈列、储存、销售、运输、售后服务'
                                    } else {
                                        return null
                                    }
                                },
                                onCell: (_, index) => {
                                    if ([0, 3, 6, 9, 12].includes(index)) {
                                        return { rowSpan: 3 }
                                    } else {
                                        return { rowSpan: 0 }
                                    }
                                },
                            },
                            { title: '评价内容', width: '55%', key: 'content', render: (r) => r.optionVO.content },
                            { title: '分数', key: 'score', render: (r) => r.optionVO.fullScore },
                            { title: '评价选项', dataIndex: 'optionRes' },
                            { title: '该项得分', dataIndex: 'score' },
                        ]}
                        summary={(pageData) => (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} />
                                    <Table.Summary.Cell index={1} />
                                    <Table.Summary.Cell index={2}>
                                        {sum(map((x) => x.optionVO.fullScore, pageData))}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        {sum(map((x) => x.score, pageData))}
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={4} align='right'>
                                        <span className='font-bold'>折算5分制</span>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>
                                        <span className='font-bold'>{detailVO?.fiveScore ?? 0}</span>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        )}
                    />
                </>
            )}
        </Wrap>
    )
}
