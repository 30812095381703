import tw from 'twin.macro'

import { Button, Form, Input, message, Drawer, Tree } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { area } from 'service'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useDisclosure } from 'utils/useDisclosure'
import { useState } from 'react'

import Create from './Create'
import Update from './Update'
import { empty, isEmpty } from 'ramda'

const { Item } = Form

const Wrap = tw.div`px-2 space-y-4 relative p-4`

export default function Area() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [current, setCurrent] = useState(null)
    const [handle, setHandle] = useState('')
    const [result, setResult] = useState([])
    const { refetch } = useQuery(['area-tree'], () => area.tree(), {
        placeholderData: [],
        onSuccess: (item) => {
            setResult(item)
        },
    })
    const deleteMutation = useMutation(area.delete, {
        onSuccess: () => {
            refetch()
            message.success('删除成功')
        },
    })

    const add = (node) => {
        setHandle('add')
        setCurrent(node)
        onOpen()
    }
    const edit = (node) => {
        setHandle('edit')
        setCurrent(node)
        onOpen()
    }
    const del = (node) => {
        deleteMutation.mutate(node.id)
    }

    const fn = (node, filter) => {
        let res = false
        if (filter(node)) {
            res = true
        } else if (node.children instanceof Array) {
            const _data = node.children.filter((node) => fn(node, filter))
            node.children = _data
            if (node.children.length > 0) {
                res = true
            }
        }
        return res
    }
    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) => {
                    if (value.name) {
                        setResult(
                            result.filter((node) =>
                                fn(node, (node) => {
                                    return node.label.indexOf(value.name) !== -1
                                })
                            )
                        )
                    } else {
                        refetch()
                    }
                }}
            >
                <Item name='name'>
                    <Input
                        placeholder='区域名称'
                        onChange={(e) => {
                            console.log(e.target.value)
                            if (isEmpty(e.target.value)) {
                                refetch()
                            }
                        }}
                    />
                </Item>

                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
            </Form>
            <Tree
                blockNode
                treeData={result}
                defaultExpandParent={true}
                titleRender={(item) => (
                    <div
                        key={item.id}
                        title={item.label}
                        className='group w-full h-full flex flex-row items-center relative'
                    >
                        <span>{item.label}</span>

                        <div
                            style={{ backgroundColor: 'rgba(245, 245, 245, 0.5)' }}
                            className='absolute right-0 h-full space-x-4 text-sm hidden items-center group-hover:flex'
                        >
                            {item.type !== 'village' && (
                                <PlusOutlined
                                    className='hover:text-blue-500 transition-all'
                                    onClick={() => add(item)}
                                />
                            )}
                            <EditOutlined className='hover:text-blue-500 transition-all' onClick={() => edit(item)} />
                            <DeleteOutlined className='hover:text-red-500 transition-all' onClick={() => del(item)} />
                        </div>
                    </div>
                )}
            ></Tree>
            <Drawer
                visible={isOpen}
                title={handle === 'add' ? '添加下级地区' : '修改地区'}
                onClose={onClose}
                extra={
                    <Button form='area-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {handle === 'add' ? (
                    <Create current={current} onClose={onClose} />
                ) : (
                    <Update current={current} onClose={onClose} />
                )}
            </Drawer>
        </Wrap>
    )
}
