import tw from 'twin.macro'
import { Form, Input, TreeSelect, Upload, Modal, AutoComplete } from 'antd'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { useState, useMemo } from 'react'

import { FILE_URL } from 'utils/api'
import { fishPond, area, user } from 'service'
import { isEmpty, isNil, head, mergeLeft } from 'ramda'
import { PlusOutlined } from '@ant-design/icons'
import Map from 'components/Map'
import { useDisclosure } from 'utils/useDisclosure'
import { bMapTransQQMap, qqMapTransBMap } from 'utils/geo'

const { Item, useWatch, useForm } = Form

const Wrap = tw.div`w-full h-full relative space-y-4`
export default function FishTypeUpdate({ current, onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()
    const imgLen = useWatch('url', form)
    const [phone, setPhone] = useState(current.actualFarmersPhone)
    const { isOpen, onOpen: mapOpen, onClose: mapClose } = useDisclosure()

    const transfer = (data) => {
        if (data) {
            return data.map((x) => ({
                ...x,
                disabled: x.type !== 'village' ? true : false,
                children: transfer(x.children),
            }))
        }
    }
    const { data: treeData } = useQuery(['area-tree'], () => area.tree(), {
        placeholderData: [],

        select: (data) => {
            return transfer(data)
        },
    })

    const { data: pondData } = useQuery(['fish-pond-one'], () => fishPond.one(current.id), {
        placeholderData: {},
        cacheTime: 0,
    })

    const { data: users } = useQuery(
        ['user-search-by-phone', phone],
        () => user.search({ type: 'fisher', kw: phone }),
        {
            placeholderData: [],
            enabled: phone !== undefined,
            cacheTime: 0,
        }
    )

    const updateMutation = useMutation(fishPond.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('pond')
            onClose()
        },
    })

    const onSubmit = (values) => {
        const url = head(values.url).response?.body.url ?? current.pondImage
        const submitData = { ...mergeLeft({ ...values }, { ...pondData }), pondImage: url }
        updateMutation.mutate(submitData)
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const onMapChange = (position) => {
        // 给到后台也是百度地图
        const { lng,lat } = qqMapTransBMap(position.lng, position.lat)
        form.setFieldsValue({ latitude: lat, longitude: lng, location: position.address })
        mapClose()
    }

    // 后端坐标是百度地图，需转换成功腾讯地图
    const position = useMemo(() => bMapTransQQMap(current.longitude, current.latitude))

    const numberInput = (e) => {
        const { value } = e.target
        const tempArr = value.split('.')
        if (tempArr[1]?.length) {
            return `${tempArr[0]}.${tempArr[1].slice(0, 2)}`.replace(/\[^\d{1,}\.\d{1,}|\d{1,}]/g, '')
        } else {
            return value.replace(/\[^\d{1,}\.\d{1,}|\d{1,}]/g, '')
        }
    }
    return (
        <Wrap>
            <Modal title='地图' visible={isOpen} onCancel={mapClose} footer={null} width={800}>
                <Map
                    defaultAddress={current.location}
                    onChange={onMapChange}
                    position={position}
                    onClose={mapClose}
                />
            </Modal>

            <Form
                form={form}
                className='max-w-[500px]'
                name='pond-form'
                onFinish={onSubmit}
                autoComplete='off'
                layout='vertical'
                initialValues={{
                    ...current,
                    url: [
                        {
                            url: current.pondImage,
                        },
                    ],
                }}
            >
                <Item label='鱼塘名称' name='pondName' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item label='鱼塘编号' name='pondCode' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item label='所属区域' name='areaId' rules={[{ required: true, message: '请选择' }]}>
                    <TreeSelect
                        treeData={treeData}
                        fieldNames={{ value: 'id' }}
                        onSelect={(_, node) => {
                            form.setFieldsValue({ areaName: node.label })
                            form.setFieldsValue({ areaPaths: node.value })
                        }}
                    />
                </Item>
                <Item label='areaName' hidden name='areaName'>
                    <Input />
                </Item>
                <Item label='areaPaths' hidden name='areaPaths'>
                    <Input />
                </Item>
                <Item label='鱼塘坐标' rules={[{ required: true, message: '必填' }]} name='location'>
                    <Input onClick={mapOpen} readOnly />
                </Item>
                <Item label='latitude' hidden name='latitude'>
                    <Input />
                </Item>
                <Item label='longitude' hidden name='longitude'>
                    <Input />
                </Item>
                <Item label='鱼塘面积' name='pondArea'>
                    <Input />
                </Item>

                <Item
                    label='鱼塘租户电话'
                    name='pondTenantPhone'
                    getValueFromEvent={numberInput}
                    rules={[
                        {
                            pattern: /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
                            message: '请输入正确的手机号',
                        },
                        { required: true, message: '必填' },
                    ]}
                >
                    <Input />
                </Item>
                <Item label='鱼塘租户' name='pondTenant' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>

                <Item label='实际养殖户' name='actualFarmers' rules={[{ required: true, message: '必填' }]}>
                    <AutoComplete
                        value={phone}
                        onChange={(value) => setPhone(value)}
                        placeholder='请输入养殖户姓名或者电话号码'
                        options={users}
                        onSelect={(_, node) => {
                            form.setFieldsValue({ actualFarmersPhone: node.mobile })
                            form.setFieldsValue({ actualFarmers: node.name })
                            form.setFieldsValue({ actualFarmersId: node.id })
                            setPhone('')
                        }}
                        fieldNames={{ value: 'id', label: 'name' }}
                    />
                </Item>
                <Item label='实际养殖户号码' name='actualFarmersPhone' getValueFromEvent={numberInput} hidden>
                    <Input />
                </Item>
                <Item label='实际养殖户' name='actualFarmersId' hidden>
                    <Input />
                </Item>
                <Item
                    label='鱼塘'
                    name='url'
                    getValueFromEvent={normFile}
                    valuePropName='fileList'
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Upload
                        action={FILE_URL}
                        accept='image/*'
                        headers={{
                            auth: localStorage.getItem('Authorization'),
                        }}
                        name='file'
                        listType='picture-card'
                        data={{ bucketName: 'img' }}
                    >
                        {(isNil(imgLen) || isEmpty(imgLen)) && (
                            <div>
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </div>
                        )}
                    </Upload>
                </Item>
            </Form>
        </Wrap>
    )
}
