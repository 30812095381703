/*
 * @LastEditTime: 2022-09-21 17:55:35
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics/InspectionTrend.js
 * @FileName: 出勤率
 */
import { useState } from 'react'
import { Decoration3, Loading } from '@jiaminghi/data-view-react'
import { Form, Select as AtSelect, DatePicker, TreeSelect } from 'antd'
import styled from 'styled-components'
import { useQueries } from 'react-query'
import { isEmpty, isNil, unnest } from 'ramda'
import { Line } from '@ant-design/plots'
import dayjs from 'dayjs'

import Subscript from './Subscript'

import { task, area, fishPond } from 'service'

const Select = styled(AtSelect)`
    .ant-select-selector {
        background-color: transparent !important;
        border: 1px solid #05cde7 !important;
        color: #05cde7;
    }
    .ant-select-arrow {
        color: #05cde7;
    }
`

const TSelect = styled(TreeSelect)`
    .ant-select-selector {
        background-color: transparent !important;
        border: 1px solid #05cde7 !important;
        color: #05cde7;
    }
    .ant-select-arrow {
        color: #05cde7;
    }
`

const RangePicker = styled(DatePicker.RangePicker)`
    background-color: transparent !important;
    border: 1px solid #05cde7 !important;
    .ant-picker-input > input,
    .ant-picker-suffix,
    .ant-picker-separator {
        color: #05cde7;
    }
`
const { Item } = Form

export default function Main({ areaPaths }) {
    const [query, setQuery] = useState({
        areaPaths,
        pondId: undefined,
        period: [dayjs().subtract(30, 'day'), dayjs()],
    })

    const [areas, pond, pondList] = useQueries([
        { queryKey: ['area', 'tree'], queryFn: () => area.tree() },
        {
            queryKey: ['full-statistics', 'pond', query],
            queryFn: () => task.pond({ ...query, period: query.period.map((x) => x.format('YYYY-MM-DD')) }),
            select: (data) =>
                unnest(
                    data.map((x) => [
                        { day: x.day, type: '正常巡塘率', rate: (x.completedInTime / x.total).toFixed(4) * 100 },
                        { day: x.day, type: '超时巡塘率', rate: (x.completedOverTime / x.total).toFixed(4) * 100 },
                        { day: x.day, type: '异常率', rate: (x.fixed / x.total).toFixed(4) * 100 },
                    ])
                ),
            enabled: !isEmpty(query.areaPaths) && !isNil(query.areaPaths),
        },
        {
            queryKey: ['pond', 'list', areaPaths],
            queryFn: () => fishPond.list({ areaPaths }),
            select: (data) => data.map((item) => ({ value: item.id, label: item.pondName })),
        },
    ])

    return (
        <div className='w-full h-full p-4 relative'>
            <Subscript />
            <div
                className='h-full flex flex-col border border-[#093068] shadow-[#093068]'
                style={{
                    boxShadow: '0 0 10px 3px #093068',
                }}
            >
                <div className='h-12 flex items-center justify-center gap-4 flex-shrink-0 bg-gradient-to-r from-transparent via-[rgba(255,255,255,0.1)] to-transparent'>
                    <Decoration3 style={{ width: '150px', height: '20px' }} />
                    <span className='text-white text-xl'>鱼塘巡查趋势</span>
                    <Decoration3 style={{ width: '150px', height: '20px' }} />
                </div>
                <div className='h-full flex flex-col bg-[#093068] bg-opacity-60 p-4'>
                    <Form className='!px-4 flex-shrink-0 gap-2' layout='inline'>
                        <Item className='w-36'>
                            <TSelect
                                value={query.areaPaths}
                                size='small'
                                placeholder='区域'
                                treeData={areas.data}
                                dropdownMatchSelectWidth={false}
                                onChange={(value) => setQuery({ ...query, areaPaths: value })}
                                getPopupContainer={(e) => e.parentNode}
                            />
                        </Item>
                        <Item className='w-36'>
                            <Select
                                showSearch
                                size='small'
                                placeholder='鱼塘'
                                value={query.pondId}
                                allowClear
                                options={pondList.data}
                                onChange={(value) => setQuery({ ...query, pondId: value })}
                                optionFilterProp='label'
                                getPopupContainer={(e) => e.parentNode}
                            />
                        </Item>
                        <Item>
                            <RangePicker
                                className='z-[9999]'
                                allowClear={false}
                                value={query.period}
                                size='small'
                                onChange={(value) => setQuery({ ...query, period: value })}
                                getPopupContainer={(e) => e.parentNode}
                            />
                        </Item>
                    </Form>
                    <div className='h-full'>
                        {pond.isError ? (
                            <div className='w-full h-full flex items-center justify-center text-[#05cde7] text-base'>
                                无法获取数据
                            </div>
                        ) : pond.isFetching ? (
                            <Loading />
                        ) : (
                            <Line
                                padding={50}
                                xField='day'
                                yField='rate'
                                seriesField='type'
                                data={pond.data || []}
                                xAxis={{ type: 'time', label: { style: { fill: '#fff' } } }}
                                yAxis={{ label: { style: { fill: '#fff' } } }}
                                label={{ formatter: (text) => `${text.rate.toFixed(2)}%`, style: { fill: '#fff' } }}
                                legend={{ position: 'top-right', itemName: { style: { fill: '#fff' } } }}
                                tooltip={{
                                    formatter: (datum) => ({
                                        name: datum.type,
                                        value: `${datum.rate.toFixed(2)}%`,
                                    }),
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
