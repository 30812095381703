/*
 * @LastEditTime: 2022-10-11 08:58:23
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\User\Inspector\Update.js
 */
import { DatePicker, Form, Input, Segmented, Switch, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { area, user } from 'service'

const { Item } = Form

export default function PatrolUpdate({ current, onClose }) {
    const queryClient = useQueryClient()
    const { data } = useQuery(['area-tree'], () => area.tree(), { placeholderData: [] })

    const updateMutation = useMutation(user.update, {
        onSuccess: () => {
            queryClient.invalidateQueries(['inspector'])
            onClose()
        },
    })

    const onSubmit = (values) => {
        updateMutation.mutate({
            ...values,
            id: current.id,
            areaVOList: values.areaVOList.map((item) => ({ id: item })),
            birthday: values.birthday?.format('YYYY-MM-DD') ?? undefined,
        })
    }

    return (
        <Form
            name='patrol-drawer-form'
            onFinish={onSubmit}
            layout='vertical'
            autoComplete='off'
            initialValues={{
                ...current,
                birthday: current.birthday ? dayjs(current.birthday) : null,
                areaVOList: current?.areaVOList?.map((x) => x.id),
            }}
        >
            <Item label='姓名' name='name' rules={[{ required: true, message: '请输入' }]}>
                <Input />
            </Item>
            <Item
                label='手机号码'
                name='mobile'
                rules={[
                    { required: true, message: '请输入' },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                    },
                ]}
                validateTrigger='onBlur'
            >
                <Input maxLength={11} disabled={!!current.unionid} />
            </Item>
            <Item label='出生日期' name='birthday'>
                <DatePicker className='w-full' />
            </Item>
            <Item label='所属村镇' name='areaVOList' rules={[{ required: true, message: '请选择' }]}>
                <TreeSelect treeData={data} fieldNames={{ value: 'id' }} treeCheckable treeNodeFilterProp='label' />
            </Item>
            <Item label='性别' name='gender'>
                <Segmented
                    options={[
                        { label: '男', value: 'male' },
                        { label: '女', value: 'female' },
                    ]}
                />
            </Item>
            <Item
                label='账号状态'
                name='enabled'
                valuePropName='checked'
                rules={[{ required: true, message: '请选择' }]}
            >
                <Switch />
            </Item>
            {/* <Item
                label='工作状态'
                name='onWork'
                valuePropName='checked'
                rules={[{ required: true, message: '请输入选择类型' }]}
                initialValue={true}
            >
                <Switch />
            </Item> */}
            <Item hidden name='type'>
                <Input />
            </Item>
        </Form>
    )
}
