/*
 * @LastEditTime: 2022-08-30 15:53:10
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/FishIll/Diagnosis.js
 */
import { Card, Form, Select, Radio, TreeSelect, Empty, Spin } from 'antd'
import { useState, useMemo } from 'react'
import { useQueries } from 'react-query'
import { isEmpty, isNil } from 'ramda'
import { Line } from '@ant-design/plots'

import { area, symptom, fishPond } from 'service'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function Diagnosis() {
    const [query, setQuery] = useState({
        areaPaths: undefined,
        pondCode: undefined,
        period: 'fifteen',
    })

    const [areaTree, ponds, symptomDataPond] = useQueries([
        {
            queryKey: ['area', 'tree', 'street'],
            queryFn: () => area.tree({ level: 'street' }),
            placeholderData: [],
        },
        {
            queryKey: ['pond', 'list', query.areaPaths],
            queryFn: () => fishPond.list({ areaPaths: query.areaPaths }),
            placeholderData: [],
        },
        {
            queryKey: ['symptom-statis-pond', query],
            queryFn: () => symptom.statisByPond(query),
            placeholderData: [],
            enabled: !isNil(query.period) && !isEmpty(query.period),
            keepPreviousData: true,
            select: (data) =>
                data.map((x) => ({
                    day: x.period,
                    amount: x.count,
                    pondName: x.pondName,
                })),
        },
    ])

    const config = useMemo(
        () => ({
            data: symptomDataPond.data,
            xField: 'day',
            yField: 'amount',
            seriesField: 'pondName',
            label: {},
            xAxis: {
                type: 'time',
            },
            point: {
                size: 5,
                shape: 'diamond',
                style: {
                    fill: 'white',
                    stroke: '#5B8FF9',
                    lineWidth: 2,
                },
            },
            tooltip: {
                showMarkers: false,
            },
            interactions: [
                {
                    type: 'marker-active',
                },
            ],
            state: {
                active: {
                    style: {
                        shadowBlur: 4,
                        stroke: '#000',
                        fill: 'red',
                    },
                },
            },
        }),
        [symptomDataPond.data]
    )

    return (
        <Card title='鱼塘病诊统计' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={symptomDataPond.isFetching}>
                <Form autoComplete='off' layout='inline' className='gap-2 justify-end !mb-4'>
                    <Item className='w-48'>
                        <TreeSelect
                            placeholder='全部区域'
                            allowClear
                            onChange={(value) => setQuery({ ...query, areaPaths: value })}
                            treeNodeFilterProp='filter'
                            showSearch
                        >
                            {renderTreeNode(areaTree.data, 'street')}
                        </TreeSelect>
                    </Item>
                    <Item className='w-48'>
                        <Select
                            value={query.pondCode}
                            fieldNames={{ label: 'pondName', value: 'pondCode' }}
                            placeholder='全部鱼塘'
                            allowClear
                            options={ponds.data}
                            onChange={(pondCode) => setQuery({ ...query, pondCode })}
                        />
                    </Item>
                    <Item>
                        <Radio.Group
                            value={query.period}
                            onChange={({ target }) => setQuery({ ...query, period: target.value })}
                        >
                            <Radio.Button value='fifteen'>近15天</Radio.Button>
                            <Radio.Button value='thirty'>近30天</Radio.Button>
                            <Radio.Button value='bymonth'>近一年</Radio.Button>
                        </Radio.Group>
                    </Item>
                </Form>
                {isEmpty(symptomDataPond.data) ? (
                    <Empty className='h-60 flex flex-col items-center justify-center' />
                ) : (
                    <Line {...config} />
                )}
            </Spin>
        </Card>
    )
}
