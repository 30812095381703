/*
 * @LastEditTime: 2022-09-02 13:41:40
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/TaskStoreList/TaskRecord.js
 */
import { useMemo } from 'react'
import { is, head } from 'ramda'
import { Descriptions, Image } from 'antd'

const { Item } = Descriptions

export default function Main({ data }) {
    const current = useMemo(() => (data ? (is(Array, data) ? head(data) : data) : {}), [data])
    return (
        <Descriptions bordered column={1} labelStyle={{ width: '200px' }}>
            <Item label='任务药店'>{current.pharmacyName}</Item>
            <Item label='任务人员'>{current.patrollerName}</Item>
            {current.stepVOSet?.map((item) => (
                <Item key={item.id} label={item.remark}>
                    {item.attachmentsVOList?.map((x) => (
                        <Image key={x.id} src={x.url} alt='现场照片' height='300px' />
                    ))}
                </Item>
            ))}
        </Descriptions>
    )
}
