/**
 * 病例分类
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Drawer, Form, Input, message, Tooltip } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { isNil } from 'ramda'

import { useDisclosure } from 'utils/useDisclosure'
import ConfirmButton from 'components/ConfirmButton'
import { symptomCatalog } from 'service'

import AddTypeForm from './AddTypeForm'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function Main() {
    const [form] = Form.useForm()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const queryClient = useQueryClient()
    const [query, setQuery] = useQueryParams({
        name: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })
    const [current, setCurrent] = useState(null)

    const { isFetching, data } = useQuery(['symptom-catalog', 'list', query], () => symptomCatalog.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const handleEdit = (item) => {
        setCurrent(item.id)
        onOpen()
    }
    const handleAdd = () => {
        setCurrent(null)
        onOpen()
    }
    const deleteMutation = useMutation(symptomCatalog.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(['symptom-catalog', 'list'])
            message.success('删除成功')
        },
    })

    const columns = [
        {
            title: '分类名称',
            dataIndex: 'name',
        },
        {
            title: '分类说明',
            dataIndex: 'desr',
            ellipsis: {
                showTitle: false,
            },
            render: (text) => (
                <Tooltip placement='topLeft' title={text}>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: '添加人',
            dataIndex: 'createBy',
        },
        {
            title: '添加时间',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleEdit(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                className='gap-2'
                form={form}
                autoComplete='off'
                layout='inline'
                onFinish={(value) => setQuery({ ...value, page: 0 })}
            >
                <Item name='name' className='w-48'>
                    <Input placeholder='分类名称' />
                </Item>
                <Item>
                    <Button type='primary' htmlType='submit' ghost>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleAdd}>
                        新建
                    </Button>
                </Item>
            </Form>

            <Table
                loading={isFetching}
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />

            <Drawer
                destroyOnClose
                width={520}
                title={isNil(current) ? '新增分类' : '编辑分类'}
                placement='right'
                onClose={onClose}
                visible={isOpen}
                extra={
                    <Button form='add-type-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
            >
                <AddTypeForm onClose={onClose} current={current} />
            </Drawer>
        </Wrap>
    )
}
