/*
 * @LastEditTime: 2022-10-26 09:37:41
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Certificate/Record/Symptom.js
 */
import { Table } from 'antd'
import { useQuery } from 'react-query'

import { symptomManifest } from 'service'

export default function Symptom({ current }) {
    const { data, isFetching } = useQuery(
        ['symptom-manifest'],
        () =>
            symptomManifest.certificateRecord({
                fishPondId: current.fishPondId,
                deliveriedStartTime: current.seedlingsTime,
                deliveriedEndTime: current.harvestTime,
            }),
        {
            placeholderData: [],
        }
    )

    const columns = [
        {
            title: '用药时间',
            dataIndex: 'deliveriedTime',
        },
        {
            title: '用药记录',
            dataIndex: 'medicineName',
        },
    ]

    return <Table className='mt-4' rowKey='id' dataSource={data} columns={columns} loading={isFetching} />
}
