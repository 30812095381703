/*
 * @LastEditTime: 2022-08-26 10:23:22
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/utils/api.js
 */
import axios from 'axios'
import qs from 'qs'
import { OPS_APPID } from 'utils/const'

export const BASE_URL = '/api'
export const FILE_URL = '/api/oss/oss/upload'

export const api = axios.create({
    baseURL: BASE_URL,
    // timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
        appid: OPS_APPID,
    },
    paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })
    },
})

api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('Authorization')
    if (token) {
        config.headers = {
            ...config.headers,
            auth: `${token}`,
        }
    }
    return config
})

api.interceptors.response.use(
    (response) => {
        const { data } = response
        if (data.success) {
            return data.body
        } else {
            if (data.code === 401) {
                const params = localStorage.getItem('errors') ? JSON.parse(localStorage.getItem('errors')) : []
                params.push(response)
                localStorage.setItem('errors', JSON.stringify(params))
            }
            throw data
        }
    },
    (err) => {
        return Promise.reject(err)
    }
)
