/*
 * @LastEditTime: 2022-08-30 17:24:32
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/FishIll/index.js
 */
import tw from 'twin.macro'
import React from 'react'

import Medication from './Medication'
import Case from './Case'
import Diagnosis from './Diagnosis'

const Wrap = tw.div`flex flex-col space-y-4 p-4`

export default function FishIll() {
    return (
        <Wrap>
            <Medication />
            <Case />
            <Diagnosis />
        </Wrap>
    )
}
