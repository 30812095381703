import tw from 'twin.macro'
import { Form, Input, Select, DatePicker, Switch, Modal, TreeSelect, Checkbox, Upload } from 'antd'
import { useMutation, useQueryClient, useQueries } from 'react-query'
import dayjs from 'dayjs'
import { isNil, find } from 'ramda'
import { PlusOutlined } from '@ant-design/icons'

import { testingOrgan, area, testingCap } from 'service'
import Map from 'components/Map'
import { FILE_URL } from 'utils/api'
import { useDisclosure } from 'utils/useDisclosure'

const { Item, useForm, useWatch } = Form
const { TreeNode } = TreeSelect

const Wrap = tw.div`w-full h-full relative space-y-4`

const businessOptions = [
    { label: '在业', value: 'industry' },
    { label: '暂停营业', value: 'suspend_industry' },
    { label: '停业', value: 'close_down' },
]

export const renderTreeNode = (list) => {
    return list.map((node) => {
        if (node.children) {
            return (
                <TreeNode
                    key={node.id}
                    value={node.id}
                    selectable={node.type === 'street'}
                    title={
                        <div
                            className='flex items-center'
                            value={{
                                areaId: node.id,
                                areaName: node.label,
                                areaPaths: node.value,
                                value: node.value,
                            }}
                        >
                            {node.label}
                        </div>
                    }
                >
                    {renderTreeNode(node.children)}
                </TreeNode>
            )
        } else {
            return (
                <TreeNode
                    key={node.id}
                    value={node.id}
                    title={
                        <div
                            className='flex items-center'
                            value={{
                                areaId: node.id,
                                areaName: node.label,
                                areaPaths: node.value,
                                value: node.value,
                            }}
                        >
                            {node.label}
                        </div>
                    }
                    selectable={node.type === 'street'}
                />
            )
        }
    })
}

export default function TestingOrganCreate({ current, onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()
    const { isOpen, onOpen: mapOpen, onClose: mapClose } = useDisclosure()
    const imgLen = useWatch('attachments', form)

    const [areas, caps] = useQueries([
        { queryKey: ['area', 'tree'], queryFn: () => area.tree(), placeholderData: [] },
        { queryKey: ['testing-capability', 'list'], queryFn: () => testingCap.list(), placeholderData: [] },
    ])

    const updateMutation = useMutation(testingOrgan.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('testing-organ')
            onClose()
        },
    })

    const normFile = (e) => {
        return e && e.fileList.map((x) => x.response?.body ?? x)
    }

    const onMapChange = (position) => {
        form.setFieldsValue({ latitude: position.lat, longitude: position.lng, area: position.address })
        mapClose()
    }

    const onSubmit = (values) => {
        updateMutation.mutate({
            ...values,
            capabilities: values.capabilities.map((x) => find((y) => y.id === x, caps.data)),
        })
    }

    return (
        <Wrap>
            <Modal title='地图' visible={isOpen} onCancel={mapClose} destroyOnClose footer={null} width={800}>
                <Map
                    defaultAddress={current.area}
                    onChange={onMapChange}
                    onClose={mapClose}
                    position={{ lat: current.latitude, lng: current.longitude }}
                />
            </Modal>
            <Form
                form={form}
                name='testingOrgan-drawer-form'
                onFinish={onSubmit}
                autoComplete='off'
                layout='vertical'
                initialValues={{
                    ...current,
                    filingTime: dayjs(current.filingTime),
                    capabilities: current.capabilities.map((x) => x.id),
                }}
            >
                <Item hidden name='id'>
                    <Input />
                </Item>
                <Item hidden name='latitude'>
                    <Input />
                </Item>
                <Item hidden name='longitude'>
                    <Input />
                </Item>
                <Item label='机构名称' name='name' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item label='机构负责人' name='nameInCharge' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item label='负责人联系电话' name='phoneInCharge' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item name='businessStatus' label='机构经营状态' rules={[{ required: true, message: '必填' }]}>
                    <Select className='w-full' allowClear options={businessOptions} />
                </Item>
                <Item label='所属镇区' name='areaId' rules={[{ required: true, message: '必填' }]}>
                    <TreeSelect
                        allowClear
                        onSelect={(_, node) => {
                            form.setFieldsValue({ areaName: node.title.props.value.areaName })
                            form.setFieldsValue({ areaPaths: node.title.props.value.value })
                        }}
                    >
                        {renderTreeNode(areas.data)}
                    </TreeSelect>
                </Item>
                <Item name='areaName' label='区域名称' hidden>
                    <Input />
                </Item>
                <Item name='areaPaths' label='区域paths' hidden>
                    <Input />
                </Item>
                <Item label='机构地址' rules={[{ required: true, message: '必填' }]} name='area'>
                    <Input onClick={mapOpen} readOnly />
                </Item>
                <Item label='检测能力' rules={[{ required: true, message: '必填' }]} name='capabilities'>
                    <Checkbox.Group>
                        {caps.data.map((item) => (
                            <Checkbox key={item.id} value={item.id}>
                                {item.name}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                </Item>
                <Item label='机构企业名称' name='enterName' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item label='所属行业' name='industry' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item label='统一社会信用代码' name='socialCode' rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Item>
                <Item label='备案通过日期' name='filingTime' rules={[{ required: true, message: '必填' }]}>
                    <DatePicker className='w-full' />
                </Item>
                <Item label='机构介绍' name='introduce' rules={[{ required: true, message: '必填' }]}>
                    <Input.TextArea />
                </Item>
                <Item
                    label='机构图片'
                    name='attachments'
                    getValueFromEvent={normFile}
                    valuePropName='fileList'
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Upload
                        action={FILE_URL}
                        accept='image/*'
                        headers={{
                            auth: localStorage.getItem('Authorization'),
                        }}
                        name='file'
                        listType='picture-card'
                        data={{ bucketName: 'img' }}
                    >
                        {(isNil(imgLen) || imgLen.length < 1) && (
                            <div>
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </div>
                        )}
                    </Upload>
                </Item>
                <Item
                    label='机构状态'
                    name='state'
                    valuePropName='checked'
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Switch />
                </Item>
            </Form>
        </Wrap>
    )
}
