/*
 * @LastEditTime: 2022-09-29 09:38:06
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/Inspect/TaskInfo.js
 */
import { Descriptions, Image, Space, Spin } from 'antd'
import { head } from 'ramda'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { task } from 'service'
import tw, { styled } from 'twin.macro'

const Wrap = styled.div`
    ${tw`h-[90vh] overflow-y-auto`};
    .ant-descriptions-title {
        color: #fff;
    }
`
const Title = tw.div`w-full bg-gradient-to-b from-[rgba(61,169,169,0.85)] to-transparent text-white text-lg px-4 py-2 rounded-full text-center`
const { Item } = Descriptions

const taskTypeMap = {
    plan: '计划任务',
    rectification: '整改任务',
    fish: '鱼类抽查（非计划任务）',
    water: '水质抽查（非计划任务）',
    storehouse: '仓库检查（非计划任务）',
    log: '日志检查（非计划任务）',
}

const inspectTypeMap = {
    inspect: '巡访检查',
    visit: '明察暗访',
    flight: '飞行检查',
}

const channel = {
    self: '自销',
    fish: '鱼中',
    corporate: '企业收',
}

export default function Main({ taskId, pondInfo, getContainer }) {
    const { data, isFetching } = useQuery(['task', 'one', taskId], () => task.one(taskId), {
        placeholderData: {},
        enabled: !!taskId,
    })
    const current = useMemo(() => (data.agricultureRecordVOList ? head(data.agricultureRecordVOList) : {}), [data])
    const pond = useMemo(() => (data.fishPondRecordVOList ? head(data.fishPondRecordVOList) : {}), [data])

    if (isFetching) {
        return <Spin className='flex items-center justify-center' />
    }

    return (
        <Wrap className='space-y-4'>
            <Title>巡查任务信息</Title>
            <div className='grid grid-cols-2 text-white gap-2'>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务编号</div>
                    <div>{data.code}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务鱼塘</div>
                    <div>{data.fishPondName}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务类型</div>
                    <div>{taskTypeMap[data.taskType]}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务截止时间</div>
                    <div>{data.endTime}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>鱼塘位置</div>
                    <div>{pondInfo.location || '未记录'}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>发布时间</div>
                    <div>{data.publishedTime}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>巡塘员</div>
                    <div>{data.inspectUserName}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>任务状态</div>
                    <div>{data.state === 2 ? '已完成' : '待完成'}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>完成时间</div>
                    <div>{data.completeTime}</div>
                </div>
                <div className='flex flex-col'>
                    <div className='text-[#02cde6]'>是否超期</div>
                    <div>{data.overdue ? '超期' : '未超期'}</div>
                </div>
            </div>
            <Title>巡查记录信息</Title>
            <div className='grid grid-cols-2 gap-2'>
                <Descriptions
                    title='农产品质量安全检查记录表'
                    bordered
                    size='small'
                    labelStyle={{ backgroundColor: '#58768c', fontSize: '12px', color: '#fff' }}
                    contentStyle={{ backgroundColor: '#58768c', fontSize: '12px', color: '#9cf4ff' }}
                    column={2}
                >
                    <Item label='检查类型'>{inspectTypeMap[current.inspectType]}</Item>
                    <Item label='检查地点'>{current.address}</Item>
                    <Item label='被检查单位名称'>{current.company}</Item>
                    <Item label='类别' span={2}>
                        {current.catalogName}
                    </Item>
                    <Item label='生产（经营）品种'>{current.variety}</Item>
                    <Item label='规模（面积/库存等）'>{current.scale}</Item>
                    <Item span={2} label='环境清洁，田间、沟渠、池边、养殖场是否无农兽药、肥料、饲料等投入品包装废弃物'>
                        {current.cleaned ? '是' : '否'}
                    </Item>
                    <Item span={2} label='告知书、承诺书妥善存放'>
                        {current.stored ? '是' : '否'}
                    </Item>
                    <Item span={2} label='是否建立完善的生产经营管理制度、标准、操作规范等'>
                        {current.perfect ? '是' : '否'}
                    </Item>
                    <Item span={2} label='生产档案记录是否规范'>
                        {current.standard ? '是' : '否'}
                    </Item>
                    <Item span={2} label='是否发现禁限用药物、不规范药品、肥料和饲料等'>
                        {current.forbidden ? '是' : '否'}
                    </Item>
                    <Item span={2} label='农资存放是否规范整洁，进出库记录是否完善'>
                        {current.whole ? '是' : '否'}
                    </Item>
                    <Item span={2} label='主要负责人是否了解农产品质量管理的相关法律、法规及相关禁止性规定'>
                        {current.learn ? '是' : '否'}
                    </Item>
                    <Item span={2} label='销售的农产品附有《广东省使用农产品合格证》'>
                        {current.attached ? '是' : '否'}
                    </Item>
                    <Item span={2} label='主要问题'>
                        <div className='whitespace-pre-wrap'>{current.problem}</div>
                    </Item>
                    <Item span={2} label='整改意见'>
                        <div className='whitespace-pre-wrap'>{current.opinion}</div>
                    </Item>
                    <Item span={2} label='限制整改时间'>
                        {current.rectificationDate}
                    </Item>
                    <Item span={2} label='备注'>
                        <div className='whitespace-pre-wrap'>{current.remark}</div>
                    </Item>
                    <Item label='巡查时间'>{current.inspectDate}</Item>
                    <Item label='巡查员'>{current.inspectUserName}</Item>
                </Descriptions>
                <div className='space-y-10'>
                    <Descriptions
                        title='鱼塘巡查信息记录表'
                        bordered
                        size='small'
                        column={2}
                        labelStyle={{ backgroundColor: '#58768c', fontSize: '12px', color: '#fff' }}
                        contentStyle={{ backgroundColor: '#58768c', fontSize: '12px', color: '#9cf4ff' }}
                    >
                        <Item label='鱼塘编号'>{pond.fishPondId}</Item>
                        <Item label='鱼塘名称'>{pond.fishPondName}</Item>
                        <Item label='鱼塘租户'>{pond.pondTenant}</Item>
                        <Item label='鱼塘租户电话'>{pond.pondTenantPhone}</Item>
                        <Item label='实际养殖户'>{pond.actualFarmers}</Item>
                        <Item label='实际养殖户电话'>{pond.actualFarmersPhone}</Item>
                        <Item label='养殖鱼种'>{pond.fryTypeVOSet?.map((x) => x.fryType).join(',')}</Item>
                        <Item label='投苗时间'>{pond.seedlingsDate}</Item>
                        <Item label='投苗数量'>{pond.seedlingsNumber}斤</Item>
                        <Item label='目标规格'>{pond.target}斤</Item>
                        <Item label='投苗规格'>{pond.seedlingSpecifications}口斤</Item>
                        <Item label='目标市场价'>{pond.marketPrice}元/斤</Item>
                        <Item label='计划收鱼时间'>{pond.planHarvestTime}</Item>
                        <Item label='销售渠道'>{channel[pond.channel]}</Item>
                        <Item label='巡查员'>{pond.inspectUserName}</Item>
                        <Item label='提交时间'>{pond.createTime}</Item>
                    </Descriptions>
                    <Descriptions
                        size='small'
                        column={2}
                        labelStyle={{ fontSize: '12px', color: '#fff' }}
                        contentStyle={{ fontSize: '12px', color: '#9cf4ff' }}
                        layout='vertical'
                    >
                        <Item label='打卡时间'>{data.punchTime}</Item>
                        <Item label='打卡地点'>{data.location}</Item>
                        <Item label='任务照片'>
                            <Space>
                                {data.attachmentsVOList &&
                                    data.attachmentsVOList.map((x) => (
                                        <Image
                                            src={x.url}
                                            className='!w-24 !h-24 object-cover'
                                            preview={{ getContainer }}
                                        />
                                    ))}
                            </Space>
                        </Item>
                    </Descriptions>
                </div>
            </div>
        </Wrap>
    )
}
