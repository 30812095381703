import { Button, Space, Table, Form, Input, message, Drawer, Popover, Image, TreeSelect } from 'antd'
import tw from 'twin.macro'

import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'
import { useDisclosure } from 'utils/useDisclosure'
import { fishPond, area } from 'service'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'

const { Item, useForm } = Form

const Wrap = tw.div`w-full h-full space-y-4 p-4`

export default function FishPond() {
    const [form] = useForm()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [current, setCurrent] = useState(null)

    const [query, setQuery] = useQueryParams({
        pondName: StringParam,
        pondCode: StringParam,
        areaPaths: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'creationDate,desc'),
    })
    const { data, isFetching, refetch } = useQuery(['pond', 'page', query], () => fishPond.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const { data: areaOptions } = useQuery(['area', 'tree'], () => area.tree(), {
        placeholderData: [],
    })

    const initQrCodeMutation = useMutation(fishPond.initQrCode, {
        onSuccess: () => {
            message.success('操作成功')
            refetch()
        },
    })

    const deleteMutation = useMutation(fishPond.delete, {
        onSuccess: () => {
            if (data.content.length === 1 && query.page !== 0) {
                setQuery({ ...query, page: query.page - 1 })
            } else {
                refetch()
            }
            message.success('删除成功')
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '鱼塘编号',
            dataIndex: 'pondCode',
        },
        {
            title: '鱼塘名称',
            dataIndex: 'pondName',
        },
        {
            title: '所属区域',
            dataIndex: 'areaName',
        },
        {
            title: '鱼塘租户',
            dataIndex: 'pondTenant',
        },
        {
            title: '鱼塘租户电话',
            dataIndex: 'pondTenantPhone',
        },
        {
            title: '实际养殖户',
            dataIndex: 'actualFarmers',
        },
        {
            title: '实际养殖户电话',
            dataIndex: 'actualFarmersPhone',
        },
        {
            title: '鱼塘二维码',
            align: 'center',
            key: 'qrCode',
            render: (item) =>
                item.pondQrCode ? (
                    <Popover content={<Image src={item.pondQrCode} preview={false} />} trigger='click'>
                        <Button size='small' type='link'>
                            查看
                        </Button>
                    </Popover>
                ) : (
                    <Button size='small' type='link' onClick={() => initQrCodeMutation.mutate(item.id)}>
                        生成二维码
                    </Button>
                ),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>

                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                form={form}
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) => {
                    setQuery({ ...value, page: 0 })
                }}
                initialValues={{
                    ...query,
                }}
            >
                <Item name='pondCode'>
                    <Input placeholder='鱼塘编号' />
                </Item>
                <Item name='pondName'>
                    <Input placeholder='鱼塘名称' />
                </Item>
                <Item name='areaPaths' className='w-48'>
                    <TreeSelect allowClear treeData={areaOptions} placeholder='所属区域' />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>

            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                    showSizeChanger: false,
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建鱼塘' : '修改鱼塘'}
                onClose={onClose}
                extra={
                    <Button form='pond-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
