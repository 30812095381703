/*
 * @LastEditTime: 2022-08-30 16:56:09
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Drugs/Category/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Drawer, Form, Input, message, Image, Select } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useMutation, useQueries, useQueryClient } from 'react-query'

import { useDisclosure } from 'utils/useDisclosure'
import { medicine, medicineCatalog } from 'service'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function InformationType() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        name: StringParam,
        code: StringParam,
        prescription: StringParam,
        catalogId: StringParam,
        symptom: StringParam,
        published: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const [{ data: options }, { data, isFetching }] = useQueries([
        {
            queryKey: ['medicine-type', 'list'],
            queryFn: () => medicineCatalog.list(),
            placeholderData: [],
            select: (data) => data.map((x) => ({ label: x.name, value: x.id })),
        },
        {
            queryKey: ['medicine', 'page', query],
            queryFn: () => medicine.page(query),
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        },
    ])

    const deleteMutation = useMutation(medicine.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries('medicine')
            message.success('删除成功')
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '药品编号',
            dataIndex: 'code',
        },
        {
            title: '药品图片',
            key: 'url',
            render: (r) => (
                <Image
                    src={
                        r.attachmentsVOList !== undefined && r.attachmentsVOList.length > 0
                            ? r.attachmentsVOList[0].url
                            : ''
                    }
                    className='object-contain'
                    width={64}
                    height={64}
                />
            ),
        },
        {
            title: '药品名称',
            dataIndex: 'name',
        },
        {
            title: '症状',
            dataIndex: 'symptom',
        },
        {
            title: '是否处方药',
            key: 'prescription',
            render: (r) => (r.prescription ? '是' : '否'),
        },
        {
            title: '上架状态',
            key: 'prescription',
            render: (r) => (r.published ? '上架' : '下架'),
        },
        {
            title: '添加时间',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]
    return (
        <Wrap>
            <Form
                autoComplete='off'
                layout='inline'
                onFinish={(value) => setQuery({ ...value, page: 0 })}
                className='gap-2'
            >
                <Item name='name' className='w-48'>
                    <Input placeholder='药品名称' />
                </Item>
                <Item name='code' className='w-48'>
                    <Input placeholder='药品编号' />
                </Item>
                <Item name='catalogId' className='w-48'>
                    <Select allowClear placeholder='药品分类' options={options} />
                </Item>
                <Item name='prescription' className='w-48'>
                    <Select
                        allowClear
                        placeholder='是否处方药'
                        options={[
                            { label: '是', value: 1 },
                            { label: '否', value: 0 },
                        ]}
                    />
                </Item>
                <Item name='published' className='w-48'>
                    <Select
                        allowClear
                        placeholder='上架状态'
                        options={[
                            { label: '上架', value: true },
                            { label: '下架', value: false },
                        ]}
                    />
                </Item>
                <Item name='symptom' className='w-48'>
                    <Input placeholder='适用症状' />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Drawer
                visible={isOpen}
                width='600px'
                title={isNil(current) ? '新建药品' : '修改药品'}
                onClose={onClose}
                extra={
                    <Button form='medicine-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
