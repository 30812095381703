/*
 * @LastEditTime: 2022-09-28 09:58:48
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/Patrol/CaseEntry.js
 * @FileName: 出勤率
 */
import { Loading } from '@jiaminghi/data-view-react'
import { useQuery } from 'react-query'
import { isNil } from 'ramda'
import { Area } from '@ant-design/plots'

import { symptom } from 'service'

export default function Main({ pharmacyId }) {
    const { data, isFetching, isError } = useQuery(
        ['full-statistics', 'symptom', pharmacyId],
        () =>
            symptom.statisByPharmacy({
                pharmacyId,
            }),
        {
            enabled: !!pharmacyId,
            staleTime: 0,
        }
    )

    return (
        <div className='h-full flex flex-col'>
            <div className='h-full'>
                {isNil(pharmacyId) ? (
                    <div className='text-white h-full fond-bold flex items-center justify-center text-base'>
                        请先选择药店
                    </div>
                ) : isError ? (
                    <div className='w-full h-full flex items-center justify-center text-[#64e5ce] text-base'>
                        无法获取数据
                    </div>
                ) : isFetching ? (
                    <Loading />
                ) : (
                    <Area
                        padding='auto'
                        xField='month'
                        yField='count'
                        data={data || []}
                        yAxis={{ label: { style: { fill: '#fff' } } }}
                        xAxis={{ label: { style: { fill: '#fff' } } }}
                        label={{ style: { fill: '#fff' } }}
                        tooltip={{
                            formatter: (datum) => ({ name: '总数', value: datum.count }),
                        }}
                    />
                )}
            </div>
        </div>
    )
}
