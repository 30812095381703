/*
 * @LastEditTime: 2022-09-02 13:51:10
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/TaskStoreList/Detail.js
 */
import { useState } from 'react'
import tw from 'twin.macro'
import { Descriptions, Spin, Button, Divider, Tag, Segmented, Empty, Select, message } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { isNil } from 'ramda'

import { pharmacyTask, user } from 'service'
import { Decrypt } from 'utils/encrypt'

import FarmProduce from './FarmProduce'
import TaskRecord from './TaskRecord'
import Picture from './Picture'

const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center border-b bg-white sticky top-0 text-xl gap-2 z-10`
const Content = tw.div`p-4 space-y-4`
const { Item } = Descriptions

const taskTypeMap = {
    plan: '计划任务',
    rectification: '整改任务',
    unplanned: '非计划任务',
}

const CustomForm = ({ farmProduceVOList, taskRecordVOList, taskType, punchTime, location, attachmentsVOList }) => {
    const [view, setView] = useState('farm')
    return isNil(farmProduceVOList) && isNil(taskRecordVOList) ? (
        <Empty />
    ) : taskType === 'unplanned' ? (
        <TaskRecord data={taskRecordVOList} />
    ) : (
        <>
            <div className='text-center'>
                <Segmented
                    value={view}
                    options={[
                        { label: '农业投入品检查记录表', value: 'farm' },
                        { label: '打卡照片', value: 'picture' },
                    ]}
                    onChange={(value) => setView(value)}
                />
            </div>
            {view === 'farm' ? (
                <FarmProduce data={farmProduceVOList} />
            ) : (
                <Picture punchTime={punchTime} location={location} attachmentsVOList={attachmentsVOList} />
            )}
        </>
    )
}

export default function TaskDetail() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { taskId } = useParams()
    const { data, isFetching } = useQuery(['patroller-task', 'one', taskId], () => pharmacyTask.one(Decrypt(taskId)), {
        placeholderData: {},
    })

    const { data: patrollers } = useQuery(['patroller', 'list', data.areaId], () => user.getpatrollers(data.areaId), {
        placeholderData: [],
        enabled: !!data.areaId,
    })

    const updateMutation = useMutation(pharmacyTask.update, {
        onSuccess: () => {
            message.success('更新成功')
            queryClient.invalidateQueries('patroller-task')
        },
    })

    return (
        <Spin spinning={isFetching}>
            <Wrap>
                <Header>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
                    {data.pharmacyName}
                </Header>
                <Content>
                    <Descriptions bordered column={3}>
                        <Item label='任务编号'>{data.code}</Item>
                        <Item label='任务类型'>{taskTypeMap[data.taskType]}</Item>
                        <Item label='任务状态'>
                            <Tag color={data.state === 1 ? 'blue' : 'green'}>
                                {data.state === 1 ? '待完成' : '已完成'}
                            </Tag>
                        </Item>
                        <Item label='发布时间'>{data.createTime}</Item>
                        <Item label='截止时间'>{data.endTime}</Item>
                        <Item label='完成时间'>{data.completeTime}</Item>
                        <Item label='任务药店'>{data.pharmacyName}</Item>
                        <Item label='巡店员'>
                            {data.state === 1 ? (
                                <Select
                                    className='w-36'
                                    value={data.patrollerName}
                                    fieldNames={{ label: 'name', value: 'id' }}
                                    options={patrollers}
                                    onChange={(value, node) =>
                                        updateMutation.mutate({
                                            ...data,
                                            patrollerId: value,
                                            patrollerName: node.name,
                                        })
                                    }
                                />
                            ) : (
                                data.patrollerName
                            )}
                        </Item>
                        <Item label='是否超期'>
                            <span className={data.overdue ? 'text-red-500' : 'text-blue-500'}>
                                {data.overdue ? '超期' : '未超期'}
                            </span>
                        </Item>
                        <Item label='药店地址' span={3}>
                            {data.pharmacyAddress}
                        </Item>
                    </Descriptions>
                    <Divider>任务相关表单</Divider>
                    <CustomForm {...data} />
                </Content>
            </Wrap>
        </Spin>
    )
}
