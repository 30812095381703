/*
 * @LastEditTime: 2022-09-09 09:06:08
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/System/Role/Create.js
 */
import { Form, Input, Tree, Divider, Spin, Radio } from 'antd'
import { useState } from 'react'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { role, purview } from 'service'
import { OPS_APPID, YYD_APPID, TEST_APPID } from 'utils/const'

const { Item, useForm, useWatch } = Form

export default function RoleCreate({ onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()
    const [checkedKeys, setCheckedKeys] = useState([])
    const appid = useWatch('appid', form)

    const { data, isLoading } = useQuery(['preview-tree', appid], () => purview.tree({ appid }), {
        placeholderData: [],
        enabled: !!appid,
    })

    const createMutation = useMutation(role.create, {
        onSuccess: () => {
            queryClient.invalidateQueries('role')
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate({
            ...values,
            purviewVOList: checkedKeys.checked?.map((item) => ({ id: item })) ?? [],
        })
    }

    if (isLoading) {
        return <Spin />
    }

    return (
        <Form form={form} name='role-drawer-form' onFinish={onSubmit} layout='vertical' autoComplete='off'>
            <Item label='角色名称' name='name' rules={[{ required: true }]}>
                <Input />
            </Item>
            <Item label='角色说明' name='remark'>
                <Input.TextArea showCount maxLength={200} />
            </Item>
            <Item label='所属应用' name='appid' initialValue={OPS_APPID}>
                <Radio.Group>
                    <Radio.Button value={OPS_APPID}>管理端</Radio.Button>
                    <Radio.Button value={YYD_APPID}>鱼药店端</Radio.Button>
                    <Radio.Button value={TEST_APPID}>检测机构端</Radio.Button>
                </Radio.Group>
            </Item>
            <Divider orientation='left'>功能权限</Divider>
            <Tree
                defaultExpandAll
                treeData={data}
                fieldNames={{ key: 'id', title: 'label' }}
                checkable
                checkStrictly
                checkedKeys={checkedKeys}
                onCheck={(value) => setCheckedKeys(value)}
            />
        </Form>
    )
}
