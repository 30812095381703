/*
 * @LastEditTime: 2022-09-22 10:19:50
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/System/Account/Update.js
 */
import { Form, Input, Switch, Select, TreeSelect } from 'antd'
import { useMutation, useQueryClient, useQueries } from 'react-query'

import { account, role, area } from 'service'
import { isEmpty } from 'ramda'

const { Item, useForm } = Form

export default function AccountUpdate({ appid, current, onClose }) {
    const [form] = useForm()
    const queryClient = useQueryClient()

    const [{ data: roleList }, { data: treeData }] = useQueries([
        {
            queryKey: ['role', 'list', appid],
            queryFn: () => role.list({ appid }),
            placeholderData: [],
        },
        {
            queryKey: ['area-tree'],
            queryFn: () => area.tree(),
            placeholderData: [],
        },
    ])

    const handleSelectChange = (_, values) => {
        return isEmpty(values) ? [] : values.map((x) => x.children.props.value)
    }

    const updateMutation = useMutation(account.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('account')
            onClose()
        },
    })

    const onSubmit = (values) => {
        updateMutation.mutate(values)
    }

    return (
        <Form
            form={form}
            name='account-drawer-form'
            onFinish={onSubmit}
            layout='vertical'
            initialValues={{
                ...current,
                roleVOList: current.roleVOList.map((x) => ({ ...x, value: x.id })),
            }}
            autoComplete='off'
        >
            <Item hidden name='id'>
                <Input />
            </Item>
            <Item hidden name='pharmacyId'>
                <Input />
            </Item>
            <Item hidden name='testingOrganId'>
                <Input />
            </Item>
            <Item label='帐号' name='account' rules={[{ required: true, message: '必填' }]}>
                <Input disabled />
            </Item>
            <Item label='姓名' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item
                label='手机号码'
                name='mobile'
                rules={[
                    { required: true, message: '请输入' },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                    },
                ]}
                validateTrigger='onBlur'
            >
                <Input maxLength={11} />
            </Item>
            <Item
                label='角色'
                name='roleVOList'
                rules={[{ required: true, message: '必填' }]}
                getValueFromEvent={handleSelectChange}
            >
                <Select mode='multiple' allowClear>
                    {roleList.map((x) => (
                        <Select.Option key={x.id} value={x.id}>
                            <div value={{ id: x.id, value: x.id, name: x.name }}>{x.name}</div>
                        </Select.Option>
                    ))}
                </Select>
            </Item>
            <Item label='所属区域组织' name='areaId' rules={[{ required: true, message: '请选择' }]}>
                <TreeSelect treeData={treeData} fieldNames={{ value: 'id' }} />
            </Item>
            <Item label='areaName' hidden name='areaName'>
                <Input />
            </Item>
            <Item label='areaPaths' hidden name='areaPaths'>
                <Input />
            </Item>
            <Item
                label='账号状态'
                name='enabled'
                valuePropName='checked'
                rules={[{ required: true, message: '请输入选择类型' }]}
            >
                <Switch />
            </Item>
        </Form>
    )
}
