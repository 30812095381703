/*
 * @LastEditTime: 2022-07-22 09:20:27
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Drugs/Type/Update.js
 */
import { Form, Input } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { medicineCatalog } from 'service'

const { Item } = Form

export default function InformationUpdate({ current, onClose }) {
    const queryClient = useQueryClient()

    const updateMutation = useMutation(medicineCatalog.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('medicine-type')
            onClose()
        },
    })

    const onSubmit = (values) => {
        updateMutation.mutate({ ...values, id: current.id })
    }
    return (
        <Form
            name='medicine-type-drawer-form'
            onFinish={onSubmit}
            initialValues={current}
            autoComplete='off'
            layout='vertical'
        >
            <Item label='分类名称' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='分类说明' name='desr'>
                <Input.TextArea allowClear showCount />
            </Item>
        </Form>
    )
}
