/*
 * @LastEditTime: 2022-09-20 11:43:05
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/index.js
 */
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ConfigProvider, message } from 'antd'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import locale from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn'

import './index.css'
import 'antd/dist/antd.variable.min.css'

import App from './App'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 3,
            retry: false,
            refetchOnWindowFocus: false,
            onError: (error) => {
                if (error.code === 401) {
                    message.warning('登录超时，请重新登录！')
                    localStorage.removeItem('Authorization')
                    setTimeout(() => {
                        window.location.href = '/login'
                    }, 800)
                } else {
                    message.error(error.msg || '发生未知错误')
                }
            },
        },
        mutations: {
            onError: (error) => {
                if (error.code === 401) {
                    message.warning('登录超时，请重新登录！')
                    localStorage.removeItem('Authorization')
                    setTimeout(() => {
                        window.location.href = '/login'
                    }, 800)
                } else {
                    message.error(error.msg || '发生未知错误')
                }
            },
        },
    },
})

ConfigProvider.config({
    theme: {
        primaryColor: '#3e62d7',
    },
})

const root = createRoot(document.getElementById('root'))

root.render(
    <RecoilRoot>
        <BrowserRouter>
            <ConfigProvider locale={locale}>
                <QueryClientProvider client={queryClient}>
                    <App />
                    <ReactQueryDevtools position='bottom-left' />
                </QueryClientProvider>
            </ConfigProvider>
        </BrowserRouter>
    </RecoilRoot>
)
