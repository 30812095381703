/*
 * @LastEditTime: 2022-09-01 11:44:54
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/TaskStoreList/TaskSettings.js
 */
/**
 * 任务设置
 */

import { Form, message, Select, Spin } from 'antd'
import { range } from 'ramda'
import { useMutation, useQuery } from 'react-query'

import { pharmacyPlan } from 'service'

const { Item } = Form
const { Option } = Select

export default function TaskSettings({ onClose }) {
    const { data, isFetching } = useQuery(['pharmacy-plan', 'one'], () => pharmacyPlan.one(1), {
        placeholderData: [],
        cacheTime: 0,
    })
    const createMutation = useMutation(pharmacyPlan.setDays, {
        onSuccess: () => {
            message.success('设置成功')
            onClose()
        },
    })

    const onSubmit = (formData) => {
        createMutation.mutate(formData)
    }

    if (isFetching) {
        return <Spin />
    }

    return (
        <Spin spinning={createMutation.isLoading}>
            <Form onFinish={onSubmit} name='task-set-form' initialValues={{ dupDay: data.dupDay }}>
                <Item label='设置巡店计划任务的属性，每次保存后对下次任务生效' colon={false} />
                <Item label='指定巡查日期' rules={[{ required: true, message: '必选' }]} name='dupDay'>
                    <Select mode='multiple' placeholder='请选择'>
                        {range(1, 32).map((v) => (
                            <Option key={v} value={v}>
                                {v}日
                            </Option>
                        ))}
                    </Select>
                </Item>
            </Form>
        </Spin>
    )
}
