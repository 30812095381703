/*
 * @LastEditTime: 2022-08-30 17:23:29
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/ShopInspector/FishShop.js
 */
import { Bullet } from '@ant-design/plots'
import { Card, Empty, Form, TreeSelect, Spin } from 'antd'
import { useMemo, useState } from 'react'
import { useQueries } from 'react-query'

import { pharmacyTask, area } from 'service'
import { isEmpty, sum, pluck } from 'ramda'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function FishShop() {
    const [areaPaths, setAreaPath] = useState('')

    const [{ data: areaTree }, { data, isFetching }] = useQueries([
        {
            queryKey: ['area-tree', 'street'],
            queryFn: () => area.tree({ level: 'street' }),
            placeholderData: [],
        },
        {
            queryKey: ['pharmacyTask', areaPaths],
            queryFn: () => pharmacyTask.jigelv({ areaPaths }),
            placeholderData: [],
            enabled: !!areaPaths,
            keepPreviousData: true,
            select: (data) => {
                if (isEmpty(data)) {
                    return data
                }
                const total = sum(pluck('total', data))
                const fixeds = sum(pluck('fixeds', data))
                const average = parseFloat(((fixeds / total) * 100).toFixed(1))
                return data.map((x) => ({
                    title: x.pharmacyName,
                    measures: parseFloat([((x.fixeds / x.total) * 100).toFixed(1)]),
                    ranges: [average, 100],
                    target: average,
                }))
            },
        },
    ])

    const config = useMemo(
        () => ({
            data,
            measureField: 'measures',
            rangeField: 'ranges',
            targetField: 'target',
            xField: 'title',
            color: {
                range: ['#FFe0b0', '#bfeec8'],
                measure: '#5B8FF9',
                target: '#3D76DD',
            },
            xAxis: {
                line: null,
            },
            legend: {
                custom: true,
                position: 'bottom',
                items: [
                    {
                        value: '实际值',
                        name: '实际值',
                        marker: { symbol: 'square', style: { fill: '#5B8FF9', r: 5 } },
                    },
                    {
                        value: '平均值',
                        name: '平均值',
                        marker: { symbol: 'line', style: { stroke: '#3D76DD', r: 5 } },
                    },
                ],
            },
        }),
        [data]
    )

    return (
        <Card title='鱼药店及格率' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={isFetching}>
                <Form autoComplete='off' layout='inline' className='gap-2 justify-end'>
                    <Item name='areaPaths' className='w-64'>
                        <TreeSelect
                            allowClear
                            placeholder='镇区'
                            onChange={(value) => setAreaPath(value)}
                            showSearch
                            treeNodeFilterProp='filter'
                        >
                            {renderTreeNode(areaTree, 'street')}
                        </TreeSelect>
                    </Item>
                </Form>
                {isEmpty(data) ? (
                    <Empty className='h-60 flex flex-col items-center justify-center mt-6' />
                ) : (
                    <Bullet {...config} className='mt-6 min-h-[300px]' />
                )}
            </Spin>
        </Card>
    )
}
