import tw from 'twin.macro'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FullscreenExitOutlined, FullscreenOutlined, ExportOutlined, SwapOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

import Date from './Date'
import PharmacyInfo from './PharmacyInfo'
import TaskHistory from './TaskHistory'
import CaseEntry from './CaseEntry'
import PrescriptionRecord from './PrescriptionRecord'
import PharmacyList from './PharmacyList'
import TaskInfo from './TaskInfo'

import { useDisclosure } from 'utils/useDisclosure'
import { area } from 'service'

const ButtonWrap = tw.div`w-[60px] h-[60px] bg-[#041a40] cursor-pointer hover:text-white hover:border-white flex-shrink-0 bg-opacity-70 rounded-full border border-[#02cde6] text-[#02cde6] shadow-md flex flex-col items-center justify-center gap-2 text-sm`
const Left = tw.div`h-full w-[420px] absolute left-0 top-0 bg-gradient-to-r from-[rgba(4,26,64,0.7)] via-[rgba(4,26,64,0.5)] to-transparent flex flex-col items-center justify-between space-y-4 z-50 p-4`
const Title = tw.div`w-full bg-gradient-to-b from-[rgba(61,169,169,0.85)] to-transparent text-white text-lg px-4 py-2 rounded-full text-center`
const Right = tw.div`h-full w-[600px] absolute right-0 top-0 bg-gradient-to-r from-transparent via-[rgba(4,26,64,0.5)] to-[rgba(4,26,64,0.7)] flex flex-col items-center space-y-6 z-50 p-4`

export default function PatrolFullStatic({
    userInfo,
    pharmacyList,
    currentPharmacy,
    isFullscreen,
    onAreaChange,
    onPharmacyChange,
    toggle,
    fullRef,
}) {
    const [taskId, setTaskId] = useState(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()

    const { data: options } = useQuery(['area', 'tree', 'street'], () => area.tree({ level: 'street' }))

    console.log(isOpen)

    const handleTaskClick = (value) => {
        console.log(value)
        setTaskId(value)
        onOpen()
    }

    return (
        <div>
            <Left>
                <div className='h-[5%] text-white text-2xl flex items-center justify-center'>
                    顺德数字水产综合服务平台
                </div>
                <div className='h-[30%] w-full flex flex-col'>
                    <Title>药店信息</Title>
                    <PharmacyInfo pharmacyId={currentPharmacy?.id} />
                </div>
                <div className='h-[60%] w-full flex flex-col'>
                    <Title>历史巡查情况</Title>
                    <TaskHistory pharmacyId={currentPharmacy?.id} onRowClick={handleTaskClick} />
                </div>
            </Left>
            <Right>
                <div className='w-full text-white flex items-center justify-end gap-4'>
                    <Date userInfo={userInfo} />
                    <ButtonWrap onClick={() => toggle(!isFullscreen)}>
                        {isFullscreen ? (
                            <>
                                <FullscreenExitOutlined style={{ fontSize: 18 }} />
                                <span className='text-xs'>退出全屏</span>
                            </>
                        ) : (
                            <>
                                <FullscreenOutlined style={{ fontSize: 18 }} />
                                <span>全屏</span>
                            </>
                        )}
                    </ButtonWrap>
                    <ButtonWrap onClick={() => navigate('/full-statistics', { replace: true })}>
                        <SwapOutlined style={{ fontSize: 18 }} />
                        <span>切换</span>
                    </ButtonWrap>
                    <ButtonWrap onClick={() => navigate(-1)}>
                        <ExportOutlined style={{ fontSize: 18 }} />
                        <span>退出</span>
                    </ButtonWrap>
                </div>
                <div className='h-[25%] w-full flex flex-col gap-4'>
                    <Title>病例录入趋势</Title>
                    <CaseEntry pharmacyId={currentPharmacy?.id} />
                </div>
                <div className='h-[25%] w-full flex flex-col gap-4'>
                    <Title>药方开具趋势</Title>
                    <PrescriptionRecord pharmacyId={currentPharmacy?.id} />
                </div>
                <div className='h-[35%] w-full flex flex-col gap-4'>
                    <Title>镇区范围鱼药店</Title>
                    <PharmacyList
                        data={pharmacyList}
                        options={options}
                        onPharmacyClick={onPharmacyChange}
                        onSelect={onAreaChange}
                    />
                </div>
            </Right>
            <Modal
                visible={isOpen}
                title={null}
                footer={null}
                getContainer={() => fullRef.current}
                width='1200px'
                closable={false}
                centered
                onCancel={onClose}
            >
                <TaskInfo taskId={taskId} pharmacyInfo={currentPharmacy} getContainer={() => fullRef.current} />
            </Modal>
        </div>
    )
}
