import tw from 'twin.macro'
import { Button, Table, Form, Input, Drawer, Select, Space } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { isEmpty, isNil } from 'ramda'
import { useNavigate } from 'react-router-dom'

import { batchNo } from 'service'
import { useDisclosure } from 'utils/useDisclosure'
import { useState } from 'react'

import Create from './Create'
import Update from './Update'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function Record() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const [query, setQuery] = useQueryParams({
        batchNo: StringParam,
        fisher: StringParam,
        fishPondName: StringParam,
        fryTypeName: StringParam,
        expired: NumberParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
    })
    const [current, setCurrent] = useState(null)

    const { data, isFetching } = useQuery(['batchNo', 'page', query], () => batchNo.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '批次号',
            dataIndex: 'batchNo',
        },
        {
            title: '养殖户',
            dataIndex: 'fisher',
        },
        {
            title: '鱼塘',
            dataIndex: 'fishPondName',
        },
        {
            title: '鱼种',
            dataIndex: 'fryTypeName',
        },
        {
            title: '投苗时间',
            dataIndex: 'seedlingsDate',
        },
        {
            title: '预计收成时间',
            dataIndex: 'planHarvestTime',
        },
        {
            title: '批次号获取时间',
            dataIndex: 'createTime',
        },
        {
            title: '状态',
            dataIndex: 'expired',
            render: (text) => (text ? '结束' : '可用'),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => navigate(`/certificate/record?batchNo=${r.batchNo}`)}
                    >
                        相关合格证
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                autoComplete='off'
                layout='inline'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        grantedTimes:
                            !isNil(value.grantedTimes) && !isEmpty(value.grantedTimes)
                                ? [
                                      value.grantedTimes[0].format('YYYY-MM-DD'),
                                      value.grantedTimes[1].format('YYYY-MM-DD'),
                                  ]
                                : undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    grantedTimes:
                        !isNil(query.grantedTimes) && !isEmpty(query.grantedTimes)
                            ? [dayjs(query.grantedTimes[0]), dayjs(query.grantedTimes[1])]
                            : undefined,
                }}
                className='gap-2'
            >
                <Item name='batchNo' className='w-48'>
                    <Input placeholder='批次号' />
                </Item>
                <Item name='fisher' className='w-48'>
                    <Input placeholder='养殖户' />
                </Item>
                <Item name='fishPondName' className='w-48'>
                    <Input placeholder='鱼塘' />
                </Item>
                <Item name='fryTypeName' className='w-48'>
                    <Input placeholder='鱼种' />
                </Item>
                <Item name='expired' className='w-48'>
                    <Select
                        placeholder='状态'
                        options={[
                            { label: '可用', value: 0 },
                            { label: '结束', value: 1 },
                        ]}
                    />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新增
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建批次号' : '编辑批次号'}
                onClose={onClose}
                extra={
                    <Button type='primary' form='batch-no-form' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
