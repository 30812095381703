/*
 * @LastEditTime: 2022-09-27 14:37:43
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\FullStatistics2\Patrol\PharmacyInfo.js
 */
import { useQuery } from 'react-query'
import { isNil } from 'ramda'
import { Rate } from 'antd'

import { pharmacy } from 'service'
import { Loading } from '@jiaminghi/data-view-react'

const businessMap = {
    operate: '在业',
    suspend: '暂停营业',
    close: '停业',
}

export default function Main({ pharmacyId }) {
    const { data, isFetching } = useQuery(['fish-pond', 'one', pharmacyId], () => pharmacy.one(pharmacyId), {
        placeholderData: {},
        enabled: !!pharmacyId,
    })
    return (
        <div className='flex-shrink-0 w-full h-full p-2 text-[#daf4ef] overflow-y-auto'>
            {isNil(pharmacyId) ? (
                <div className='text-white h-full fond-bold flex items-center justify-center text-base'>
                    请先选择鱼药店
                </div>
            ) : isFetching ? (
                <Loading />
            ) : (
                <>
                    <div className='leading-10 w-full text-xl font-blod'>{data.name}</div>
                    <div className='flex flex-col justify-between gap-2'>
                        <div>{data.address}</div>
                        <div className='grid grid-cols-2 gap-2'>
                            <div>
                                <div className='text-[#17cee8]'>当前评分</div>
                                <Rate allowHalf disabled defaultValue={data.score}></Rate>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>所属区域</div>
                                <div>{data.areaName}</div>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>负责人</div>
                                <div>{data.principal}</div>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>负责人电话</div>
                                <div>{data.phone}</div>
                            </div>
                            <div>
                                <div className='text-[#17cee8]'>营业状态</div>
                                <div>{businessMap[data.businessStatus]}</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
