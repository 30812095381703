/*
 * @LastEditTime: 2022-10-26 09:44:29
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Detection/Record/Detail.js
 */
import tw from 'twin.macro'
import { Descriptions, Spin, Button, Divider, Space, Image, Table } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { testingRecord } from 'service'
import { Decrypt } from 'utils/encrypt'
import { useMemo } from 'react'

const { Item } = Descriptions
const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center border-b bg-white sticky top-0 text-xl gap-2 z-10`
const Content = tw.div`p-4 space-y-4`

const testingTypeMap = {
    symptom: '细菌、病毒检测',
    seeding: '药残检测(入苗检测)',
    emergence: '药残检测(出鱼检测)',
    spot: '药残检测(抽检监测)',
}

const sampleMap = {
    self: '自送',
    send: '邮寄',
}

const simpleStateMap = {
    self: '自取',
    entrust: '委托中心处理',
    mail: '样品寄回（邮寄）',
    other: '其他',
}

const getReportTypeMap = {
    self: '自取',
    express: '快递',
    fax: '传真',
    mail: '邮件',
}

const testingResultMap = {
    negative: '阴性',
    doubtPositive: '疑阳',
    positive: '阳性',
}

const serviceTypeEunmMap = {
    standardTwo: '加急服务（2个工作日）',
    standardOne: '加急服务（1个工作日）',
    other: '其他',
}

export default function TaskDetail() {
    const navigate = useNavigate()
    const { recordId } = useParams()
    const { data, isFetching } = useQuery(
        ['testing-record', 'one', recordId],
        () => testingRecord.one(Decrypt(recordId)),
        {
            placeholderData: {},
            cacheTime: 0,
        }
    )

    const symptom = useMemo(() => data.symptomVO || {}, [data])
    const result = useMemo(() => data.testingResultVO || {}, [data])

    if (isFetching) {
        return (
            <div className='w-full h-full flex items-center justify-center'>
                <Spin />
            </div>
        )
    }

    return (
        <Wrap>
            <Header>
                <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
                {data.code}
            </Header>
            <Content>
                {data.testingType === 'symptom' && (
                    <>
                        <Divider>病例信息</Divider>
                        <Descriptions bordered column={3}>
                            <Item label='任务编号'>{data.code}</Item>
                            <Item label='养殖户'>{symptom.fisher}</Item>
                            <Item label='联系电话'>{symptom.fisherPhone}</Item>
                            <Item label='养殖鱼塘'>{symptom.pondName}</Item>
                            <Item label='患病鱼种'>{symptom.fryTypeName}</Item>
                            <Item label='来店时间'>{symptom.comingTime}</Item>
                            <Item label='病例分类'>{symptom.catalogName}</Item>
                            <Item label='病例名称'>{symptom.name}</Item>
                        </Descriptions>
                    </>
                )}
                <Divider>检测信息</Divider>
                {data.testingType === 'symptom' ? (
                    <Descriptions bordered column={2}>
                        <Item label='检测分类'>{testingTypeMap[data.testingType]}</Item>
                        <Item label='检测单位'>{data.testingOrganName}</Item>
                        <Item label='送检对象'>{data.testObject}</Item>
                        <Item label='联系电话'>{data.phone}</Item>
                        <Item label='鱼塘号'>{data.pondCode}</Item>
                        <Item label='收样日期'>{data.shouYangTime}</Item>
                        <Item label='批次号'>{data.batchNo}</Item>
                        <Item label='样品名称'>{data.fryTypeName}</Item>
                        <Item label='来样方式'>{sampleMap[data.sampleEunm]}</Item>
                        <Item label='样品数量'>{data.simpleNumber}</Item>
                        <Item label='样品状态'>{data.simpleState}</Item>
                        <Item label='检测项目'>{data.capabilities?.map((x) => x.name).join('、')}</Item>
                        <Item label='病例图片' span={2}>
                            <Space>
                                {data.attachments?.map((img, index) => (
                                    <Image
                                        key={index}
                                        src={img.url}
                                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                    />
                                ))}
                            </Space>
                        </Item>
                        <Item label='创建人'>{data.createBy}</Item>
                        <Item label='创建时间'>{data.createTime}</Item>
                    </Descriptions>
                ) : (
                    //其他三种
                    <Descriptions bordered column={2}>
                        <Item label='检测分类'>{testingTypeMap[data.testingType]}</Item>
                        <Item label='委托编号'>{data.entrustCode}</Item>
                        <Item label='送检对象'>{data.testObject}</Item>
                        <Item label='收样日期'>{data.shouYangTime}</Item>
                        <Item label='鱼塘号'>{data.pondCode}</Item>
                        <Item label='联系电话'>{data.phone}</Item>
                        <Item label='样品名称'>{data.fryTypeName}</Item>
                        <Item label='样品种类'>{data.sampleType}</Item>
                        <Item label='样品数量'>{data.simpleNumber}</Item>
                        <Item label='样品来源'>{data.sampleSource}</Item>
                        <Item label='样品描述'>{data.sampleDescribe}</Item>
                        <Item label='商定完成日期'>{data.finishTime}</Item>
                        <Item label='委托单位/人'>{data.assignor}</Item>
                        <Item label='委托单位/人地址'>{data.assignoraAdress}</Item>
                        <Item label='传真'>{data.fax}</Item>
                        <Item label='批次号'>{data.batchNo}</Item>
                        <Item label='要求检测项目' span={2}>
                            {data.capabilities?.map((x) => x.name).join('、')}
                        </Item>
                        <Item label='检测方法'>{data?.TestingCapabilityResultVO?.method}</Item>
                        <Item label='是否判定'>{data.isJudge ? '是' : '否'}</Item>
                        <Item label='取报告方式'>{getReportTypeMap[data.getReportTypeEunm]}</Item>
                        <Item label='服务类型'>{serviceTypeEunmMap[data.serviceTypeEunm]}</Item>
                        <Item label='样品处理方式'>{simpleStateMap[data.simpleHandleEunm]}</Item>
                        <Item label='检测单位'>{data.testingOrganName}</Item>

                        <Item label='创建人'>{data.createBy}</Item>
                        <Item label='创建时间'>{data.createTime}</Item>
                    </Descriptions>
                )}
                {data.haveResult && (
                    <>
                        <Divider>检测结果</Divider>
                        <Descriptions bordered column={2} layout='vertical'>
                            <Item label='检测日期'>{result.testTime}</Item>
                            <Item label='检测依据'>{result.testBasis}</Item>
                            <Item label='检测结论' span={2}>
                                {result.testConclusion}
                            </Item>
                            <Item label='检测结果' span={2}>
                                <Table
                                    dataSource={result.capabilityResult}
                                    columns={[
                                        { title: '检测项目', dataIndex: 'testCapabilityName' },
                                        { title: '检测方法', dataIndex: 'method' },
                                        { title: '检测标准', dataIndex: 'checkStandard' },
                                        { title: '检测样本', dataIndex: 'sample' },
                                        { title: '检测限值', dataIndex: 'value' },
                                        {
                                            title: '检测结果',
                                            key: 'testingResultEunm',
                                            render: (r) => testingResultMap[r.testingResultEunm],
                                        },
                                        {
                                            title: '是否符合标准',
                                            key: 'isStandard',
                                            render: (r) => (r?.isStandard ? '符合' : '不符合'),
                                        },
                                    ]}
                                    pagination={false}
                                />
                            </Item>
                            <Item label='检查员'>{result.testPerson}</Item>
                            <Item label='复核员'>{result.reviewPerson}</Item>
                            <Item label='结果图片'>
                                <Space>
                                    {result.attachments?.map((img, index) => (
                                        <Image
                                            key={index}
                                            src={img.url}
                                            style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                        />
                                    ))}
                                </Space>
                            </Item>
                        </Descriptions>
                    </>
                )}
            </Content>
        </Wrap>
    )
}
