/*
 * @LastEditTime: 2022-09-20 16:54:29
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Drugs/Type/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Drawer, Form, Input, message } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { isNil, findIndex, propEq } from 'ramda'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useDisclosure } from 'utils/useDisclosure'
import { medicineCatalog } from 'service'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function InformationType() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        name: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const { data, isFetching, refetch } = useQuery(
        ['medicine-type', 'page', query],
        () => medicineCatalog.page(query),
        {
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        }
    )

    const deleteMutation = useMutation(medicineCatalog.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries('medicine-type')
            message.success('删除成功')
        },
    })

    const moveMutation = useMutation(medicineCatalog.move, {
        onSuccess: refetch,
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const move = (item, tag) => {
        var index = findIndex(propEq('id', item.id))(data.content)
        if (tag === 0) {
            if (index === 0) {
                message.info('已经在第一位')
            } else {
                moveMutation.mutate({ sourceId: item.id, targetId: data.content[index - 1].id })
            }
        } else {
            if (index === data.content.length - 1) {
                message.info('已经在最后一位')
            } else {
                moveMutation.mutate({ sourceId: item.id, targetId: data.content[index + 1].id })
            }
        }
    }

    const columns = [
        {
            title: '分类名称',
            dataIndex: 'name',
        },
        {
            title: '分类说明',
            dataIndex: 'desr',
        },
        {
            title: '添加人',
            dataIndex: 'createBy',
        },
        {
            title: '添加时间',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => move(r, 0)}>
                        分类上移
                    </Button>
                    <Button type='link' size='small' onClick={() => move(r, 1)}>
                        分类下移
                    </Button>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]
    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) => setQuery({ ...value, page: 0 })}
            >
                <Item name='name'>
                    <Input placeholder='分类名称' />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建药品类目' : '修改药品类目'}
                onClose={onClose}
                extra={
                    <Button form='medicine-type-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
