import { Button, Space, Table, Form, Input, message, Drawer } from 'antd'
import tw from 'twin.macro'
import { fryType } from 'service'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'
import { useDisclosure } from 'utils/useDisclosure'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'

const { Item, useForm } = Form

const Wrap = tw.div`w-full h-full space-y-4 p-4`

export default function FishType() {
    const [form] = useForm()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [current, setCurrent] = useState(null)

    const [query, setQuery] = useQueryParams({
        fryType: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'creationDate,desc'),
    })
    const { data, isFetching, refetch } = useQuery(['fish-type', 'page', query], () => fryType.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const deleteMutation = useMutation(fryType.delete, {
        onSuccess: () => {
            message.success('删除成功')
            if (data.content.length === 1 && query.page !== 0) {
                setQuery({ ...query, page: query.page - 1 })
            } else {
                refetch()
            }
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    // const handleReset = (item) => {
    //     form.resetFields()
    //     setQuery({ page: 0, size: 10 })
    // }

    const columns = [
        {
            title: '鱼种',
            dataIndex: 'fryType',
        },
        {
            title: '养殖周期（天）',
            dataIndex: 'breedingCycle',
        },
        {
            title: '巡塘周期（天）',
            dataIndex: 'inspectCycle',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                form={form}
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) => {
                    setQuery({ ...value, page: 0 })
                }}
                initialValues={{
                    ...query,
                }}
            >
                <Item name='fryType'>
                    <Input placeholder='鱼种名称' />
                </Item>

                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>

            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                    showSizeChanger: false,
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建鱼种' : '修改鱼种'}
                onClose={onClose}
                extra={
                    <Button form='fish-type-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
