import tw from 'twin.macro'
import { Button, Space, Table, Form, Input, DatePicker } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { isEmpty, isNil } from 'ramda'
import { useNavigate } from 'react-router-dom'

import { certificate } from 'service'
import { Enscrypt } from 'utils/encrypt'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form
const { RangePicker } = DatePicker

export default function Record() {
    const navigate = useNavigate()
    const [query, setQuery] = useQueryParams({
        batchNo: StringParam,
        fisherName: StringParam,
        fishPondName: StringParam,
        fryTypeName: StringParam,
        grantedTimes: ArrayParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'grantedTime,desc'),
    })

    const { data, isFetching } = useQuery(['certificate', 'page', query], () => certificate.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const columns = [
        {
            title: '批次号',
            dataIndex: 'batchNo',
        },
        {
            title: '养殖户',
            dataIndex: 'fisherName',
        },
        {
            title: '联系电话',
            dataIndex: 'fisherPhone',
        },
        {
            title: '鱼塘',
            dataIndex: 'fishPondName',
        },
        {
            title: '鱼种',
            dataIndex: 'fryTypeName',
        },
        {
            title: '投苗时间',
            dataIndex: 'seedlingsTime',
        },
        {
            title: '收鱼时间',
            dataIndex: 'harvestTime',
        },
        {
            title: '发放时间',
            dataIndex: 'grantedTime',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button
                        type='link'
                        size='small'
                        onClick={() => navigate(`/certificate/record/detail/${Enscrypt(r.id)}`, { state: 'test' })}
                    >
                        查看详情
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                autoComplete='off'
                layout='inline'
                // hh:mm:ss
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        grantedTimes:
                            !isNil(value.grantedTimes) && !isEmpty(value.grantedTimes)
                                ? [
                                      value.grantedTimes[0].format('YYYY-MM-DD'),
                                      value.grantedTimes[1].format('YYYY-MM-DD'),
                                  ]
                                : undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    grantedTimes:
                        !isNil(query.grantedTimes) && !isEmpty(query.grantedTimes)
                            ? [dayjs(query.grantedTimes[0]), dayjs(query.grantedTimes[1])]
                            : undefined,
                }}
                className='gap-2'
            >
                <Item name='batchNo' className='w-48'>
                    <Input placeholder='批次号' />
                </Item>
                <Item name='fisherName' className='w-48'>
                    <Input placeholder='养殖户' />
                </Item>
                <Item name='fishPondName' className='w-48'>
                    <Input placeholder='鱼塘' />
                </Item>
                <Item name='fryTypeName' className='w-48'>
                    <Input placeholder='鱼种' />
                </Item>
                <Form.Item name='grantedTimes'>
                    <RangePicker placeholder={['发放时间', '起止时间']} />
                </Form.Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
        </Wrap>
    )
}
