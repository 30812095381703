/*
 * @LastEditTime: 2022-10-17 19:04:17
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\components\Map\index.js
 */
import { useState } from 'react'
import { QMap, Marker, config, utils } from 'react-tmap'
import { Button, AutoComplete, Input } from 'antd'
import { useDebounce } from 'react-use'
import { useQuery } from 'react-query'

import { getAddressByKeywords } from 'service'
import { MAP_KEY } from 'utils/const'

export default function QQMap({
    defaultAddress,
    position = { lat: 23.044984514956717, lng: 113.10081481933594 },
    onChange,
    onClose,
}) {
    const [center, setCenter] = useState(position)
    const [address, setAddress] = useState(defaultAddress)
    const [val, setVal] = useState('')
    const [debounce, setDebounce] = useState('')

    const { data, isFetching } = useQuery(
        ['map', 'keyword', debounce],
        () => getAddressByKeywords({ key: MAP_KEY, region: '佛山', keyword: debounce }),
        {
            enabled: !!debounce,
            select: ({ data }) => data.data,
        }
    )
    const handleMarkerClick = async (_, { latLng }) => {
        const { detail } = await utils.getAddressByPosition(latLng)
        setCenter(latLng)
        setAddress(detail.address)
    }

    const handleSelect = (_, option) => {
        setCenter(option.location)
        setAddress(option.address)
    }

    useDebounce(() => setDebounce(val), 800, [val])

    return (
        <>
            <QMap center={center} style={{ height: '500px' }} zoom={15} events={{ click: handleMarkerClick }}>
                <AutoComplete
                    className='w-48 absolute top-2 z-50'
                    options={data && data.map((item) => ({ ...item, value: item.title }))}
                    onSelect={handleSelect}
                >
                    <Input.Search
                        loading={isFetching}
                        value={val}
                        placeholder='关键词'
                        onChange={({ target }) => setVal(target.value)}
                    />
                </AutoComplete>
                <Marker position={center} visible size={30} animation={config.ANIMATION_DROP} />
            </QMap>
            <div className='w-full border-t mt-4 py-2 space-x-2 text-right'>
                <Button onClick={onClose}>取消</Button>
                <Button type='primary' onClick={() => onChange({ ...center, address })}>
                    确定
                </Button>
            </div>
        </>
    )
}
