/*
 * @LastEditTime: 2022-09-19 08:45:55
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/Target/Create.js
 */
import { Button, Form, Input, DatePicker, Select, Table, TreeSelect, Radio, InputNumber } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { length } from 'ramda'
import { useMutation, useQueries, useQueryClient } from 'react-query'

import { fryType, target, area } from 'service'
import { renderTreeNode } from 'components/TreeNode'
import dayjs from 'dayjs'

const { Item, useForm, useWatch } = Form

export default function TaskCreate({ onClose }) {
    const queryClient = useQueryClient()
    const [form] = useForm()
    const number = useWatch('targetNumType', form)

    const [{ data }, { data: areaOptions }] = useQueries([
        { queryKey: ['fry-type', 'list'], queryFn: () => fryType.list(), placeholderData: [] },
        { queryKey: ['area', 'tree'], queryFn: () => area.tree(), placeholderData: [] },
    ])

    const createMutation = useMutation(target.create, {
        onSuccess: () => {
            queryClient.invalidateQueries('target')
            onClose()
        },
    })

    const handleTreeSelect = (_, label) => {
        return label.map(({ props }) => props.value)
    }

    const onSubmit = (values) => {
        createMutation.mutate({ ...values, state: 0 })
    }

    return (
        <Form
            form={form}
            name='target-drawer-form'
            onFinish={onSubmit}
            layout='vertical'
            autoComplete='off'
            preserve={false}
        >
            <Item label='目标标题' name='name' rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Item>
            <Item label='任务种类' name='taskType' rules={[{ required: true, message: '必填' }]}>
                <Select
                    allowClear
                    options={[
                        { label: '鱼类抽查', value: 'fish' },
                        { label: '水质抽查', value: 'water' },
                        { label: '仓库检查', value: 'storehouse' },
                        { label: '日志检查', value: 'log' },
                    ]}
                />
            </Item>
            <Item
                label='任务范围'
                name='scopeVOList'
                rules={[{ required: true, message: '必填' }]}
                getValueFromEvent={handleTreeSelect}
            >
                <TreeSelect treeCheckable allowClear>
                    {renderTreeNode(areaOptions)}
                </TreeSelect>
            </Item>
            <Item label='任务鱼种' name='fryTypeId'>
                <Select className='w-full' options={data?.map((x) => ({ label: x.fryType, value: x.id }))} />
            </Item>
            <Item label='抽查数量' name='targetNumType' initialValue='all'>
                <Radio.Group>
                    <Radio value='all'>全部鱼塘</Radio>
                    <Radio value='custom'>自定义数量</Radio>
                </Radio.Group>
            </Item>
            <Item
                hidden={number === 'all'}
                name='targetNum'
                rules={[{ required: number === 'custom', message: '必填' }]}
            >
                <InputNumber placeholder='输入数量' className='!w-full' />
            </Item>
            <Item label='任务截止日期' name='endTime' rules={[{ required: true, message: '必填' }]}>
                <DatePicker className='w-full' disabledDate={(current) => current && current < dayjs().endOf('day')} />
            </Item>
            <Item label='任务要求内容' required>
                <Form.List
                    name='stepsVOList'
                    rules={[{ required: true, message: '必填' }]}
                    initialValue={[{ step: 1, remark: '' }]}
                >
                    {(fields, { add, remove }) => (
                        <>
                            <Table
                                dataSource={fields}
                                pagination={false}
                                columns={[
                                    {
                                        title: '任务步骤',
                                        key: 'step',
                                        render: (_, __, index) => (
                                            <>
                                                <Item
                                                    className='!mb-0'
                                                    hidden
                                                    name={[fields[index].name, 'step']}
                                                    {...fields[index].restField}
                                                    initialValue={index + 1}
                                                >
                                                    <Input />
                                                </Item>
                                                {index + 1}
                                            </>
                                        ),
                                    },
                                    {
                                        title: '任务说明',
                                        key: 'remark',
                                        render: (_, __, index) => (
                                            <Item
                                                className='!mb-0'
                                                name={[fields[index].name, 'remark']}
                                                rules={[{ required: true, message: '必填' }]}
                                            >
                                                <Input placeholder='单行输入' />
                                            </Item>
                                        ),
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        render: (_, __, index) => (
                                            <Button
                                                type='text'
                                                icon={<MinusCircleOutlined />}
                                                onClick={() => remove(fields[index].name)}
                                                disabled={length(fields) === 1}
                                            />
                                        ),
                                    },
                                ]}
                            />
                            <Item className='!mt-4'>
                                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                                    添加任务要求内容
                                </Button>
                            </Item>
                        </>
                    )}
                </Form.List>
            </Item>
        </Form>
    )
}
