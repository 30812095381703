import { Line } from '@ant-design/plots'
import { Form, TreeSelect, Radio, Select, Card, Empty, Spin } from 'antd'

import { useMemo, useState } from 'react'
import { useQueries } from 'react-query'

import { medicineStock, area, medicineCatalog } from 'service'
import { isEmpty, last, unnest, update } from 'ramda'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function StockOutStatis() {
    const [query, setQuery] = useState({
        period: 'fifteen',
        areaPaths: undefined,
        catalogIds: [],
    })

    const [{ data: areaTree }, { data: options }, { data, isFetching }] = useQueries([
        {
            queryKey: ['area-tree', 'street'],
            queryFn: () => area.tree({ level: 'street' }),
            placeholderData: [],
        },
        {
            queryKey: ['medicine-type', 'list'],
            queryFn: () => medicineCatalog.list(),
            placeholderData: [],
        },
        {
            queryKey: ['medicine-stock', query],
            queryFn: () => medicineStock.statisStockByCatalog(query),
            placeholderData: [],
            keepPreviousData: true,
            enabled: !!query.areaPaths && !isEmpty(query.catalogIds),
            select: (data) => {
                if (isEmpty(data)) {
                    return data
                }
                return unnest(data.map((x) => x.details.map((y) => ({ ...y, catalog: x.catalogName }))))
            },
        },
    ])

    const config = useMemo(
        () => ({
            data: data || [],
            xField: 'period',
            yField: 'amount',
            seriesField: 'catalog',
            xAxis: {
                type: 'time',
            },
            point: {
                size: 5,
                shape: 'diamond',
                style: {
                    fill: 'white',
                    stroke: '#5B8FF9',
                    lineWidth: 2,
                },
            },
            tooltip: {
                showMarkers: false,
            },
            interactions: [
                {
                    type: 'marker-active',
                },
            ],
            area: {
                style: {
                    fillOpacity: 0.15,
                },
            },
        }),
        [data]
    )

    return (
        <Card title='药品出库趋势' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={isFetching}>
                <Form autoComplete='off' layout='inline' className='gap-2 justify-end'>
                    <Item className='w-48'>
                        <TreeSelect
                            value={query.areaPaths}
                            allowClear
                            placeholder='区域'
                            onSelect={(areaPaths) => setQuery({ ...query, areaPaths })}
                            onClear={() => setQuery({ ...query, areaPaths: undefined })}
                            showSearch
                            treeNodeFilterProp='filter'
                        >
                            {renderTreeNode(areaTree, 'street')}
                        </TreeSelect>
                    </Item>
                    <Item className='w-72'>
                        <Select
                            value={query.catalogIds}
                            placeholder='药品类目'
                            allowClear
                            options={options && options?.map((x) => ({ label: x.name, value: x.id }))}
                            mode='multiple'
                            maxTagCount={3}
                            onChange={(value) => {
                                if (value.length <= 3) {
                                    setQuery({ ...query, catalogIds: value })
                                } else {
                                    setQuery({ ...query, catalogIds: update(2, last(value), query.catalogIds) })
                                }
                            }}
                        />
                    </Item>
                    <Item>
                        <Radio.Group
                            value={query.period}
                            onChange={(e) => setQuery({ ...query, period: e.target.value })}
                        >
                            <Radio.Button value='fifteen'>近15天</Radio.Button>
                            <Radio.Button value='thirty'>近30天</Radio.Button>
                            <Radio.Button value='bymonth'>按年</Radio.Button>
                        </Radio.Group>
                    </Item>
                </Form>
                {isEmpty(data) ? (
                    <Empty className='h-60 flex flex-col items-center justify-center' />
                ) : (
                    <Line {...config} />
                )}
            </Spin>
        </Card>
    )
}
