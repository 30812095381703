import tw from 'twin.macro'
import { PlusOutlined } from '@ant-design/icons'
import { isNil } from 'ramda'
import { Form, Input, Select, Upload, DatePicker, Switch, TreeSelect, Modal, Spin } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import dayjs from 'dayjs'

import { FILE_URL } from 'utils/api'
import { pharmacy, area } from 'service'
import Map from 'components/Map'
import { renderTreeNode } from 'components/TreeNode'
import { useDisclosure } from 'utils/useDisclosure'

const { Item, useWatch, useForm } = Form

const Wrap = tw.div`w-full h-full relative space-y-4 pb-10`

const businessOptions = [
    { label: '在业', value: 'operate' },
    { label: '暂停营业', value: 'suspend' },
    { label: '停业', value: 'close' },
]

export default function PharmacyUpdate({ current, onClose }) {
    const [form] = useForm()
    const imgLen = useWatch('attachments', form)
    const { isOpen, onOpen: mapOpen, onClose: mapClose } = useDisclosure()
    const queryClient = useQueryClient()

    const { data } = useQuery(['area', 'tree', 'street'], () => area.tree({ level: 'street' }), {
        placeholderData: [],
    })

    const updateMutation = useMutation(pharmacy.update, {
        onSuccess: () => {
            queryClient.invalidateQueries('pharmacy')
            onClose()
        },
    })

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return (
            e &&
            e.fileList.map((x) => {
                if (x.response) {
                    return x.response.body
                } else {
                    return x
                }
            })
        )
    }

    const onMapChange = (position) => {
        form.setFieldsValue({ latitude: position.lat, longitude: position.lng, address: position.address })
        mapClose()
    }

    const onSubmit = (values) => {
        updateMutation.mutate({ ...values, ...values.area, area: undefined, value: undefined })
    }
    return (
        <Wrap>
            <Modal title='地图' visible={isOpen} onCancel={mapClose} footer={null} destroyOnClose width={800}>
                <Map
                    defaultAddress={current.address}
                    onChange={onMapChange}
                    position={{ lat: current.latitude, lng: current.longitude }}
                    onClose={mapClose}
                />
            </Modal>
            <Spin spinning={updateMutation.isLoading}>
                <Form
                    initialValues={{
                        ...current,
                        approvalDate: dayjs(current.approvalDate),
                        area: {
                            areaId: current.areaId,
                            areaName: current.areaName,
                            areaPaths: current.areaPaths,
                            value: current.areaPaths,
                        },
                    }}
                    form={form}
                    name='pharmacy-drawer-form'
                    onFinish={onSubmit}
                    autoComplete='off'
                    layout='vertical'
                >
                    <Item hidden name='id'>
                        <Input />
                    </Item>
                    <Item label='药店编号' name='code' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='药店名称' name='name' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='药店负责人' name='principal' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='负责人联系电话' name='phone' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item
                        label='所属镇区'
                        name='area'
                        rules={[{ required: true, message: '必填' }]}
                        getValueFromEvent={(_, args) => args[0].props.value}
                    >
                        <TreeSelect allowClear showSearch treeNodeFilterProp='filter'>
                            {renderTreeNode(data, 'street')}
                        </TreeSelect>
                    </Item>
                    <Item name='areaName' label='所属镇区' hidden>
                        <Input />
                    </Item>
                    <Item name='areaPaths' label='所属镇区' hidden>
                        <Input />
                    </Item>
                    <Item label='latitude' hidden name='latitude'>
                        <Input />
                    </Item>
                    <Item label='longitude' hidden name='longitude'>
                        <Input />
                    </Item>
                    <Item
                        label='药店封面'
                        name='attachments'
                        getValueFromEvent={normFile}
                        valuePropName='fileList'
                        rules={[{ required: true, message: '必填' }]}
                    >
                        <Upload
                            action={FILE_URL}
                            accept='image/*'
                            headers={{
                                auth: localStorage.getItem('Authorization'),
                            }}
                            name='file'
                            listType='picture-card'
                            data={{ bucketName: 'img' }}
                        >
                            {(isNil(imgLen) || imgLen.length < 1) && (
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            )}
                        </Upload>
                    </Item>
                    <Item name='businessStatus'>
                        <Select allowClear placeholder='药店经营状态' options={businessOptions} />
                    </Item>
                    <Item label='药店地址' rules={[{ required: true, message: '必填' }]} name='address'>
                        <Input onClick={mapOpen} readOnly />
                    </Item>
                    <Item label='门店企业名称' name='company' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='所属行业' name='industry' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='统一社会信用代码' name='identifier' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='备案通过日期' name='approvalDate' rules={[{ required: true, message: '必填' }]}>
                        <DatePicker className='w-full' />
                    </Item>
                    <Item label='鱼药店介绍' name='remark' rules={[{ required: true, message: '必填' }]}>
                        <Input.TextArea />
                    </Item>
                    <Item
                        label='药店状态'
                        name='state'
                        valuePropName='checked'
                        rules={[{ required: true, message: '必填' }]}
                    >
                        <Switch />
                    </Item>
                </Form>
            </Spin>
        </Wrap>
    )
}
