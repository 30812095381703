/*
 * @LastEditTime: 2022-09-05 17:09:29
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Disease/Type/AddTypeForm.js
 */
/**
 * 添加分类
 */

import { Form, Input, Spin } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { isNil } from 'ramda'

import { symptomCatalog } from 'service'

const { Item } = Form
const { TextArea } = Input

export default function AddTypeForm({ onClose, current }) {
    const queryClient = useQueryClient()

    const { data, isFetching } = useQuery(['symptom-catalog', 'one', current], () => symptomCatalog.one(current), {
        enabled: !!current,
    })

    const createMutation = useMutation(symptomCatalog.create, {
        onSuccess: () => {
            queryClient.invalidateQueries(['symptom-catalog', 'list'])
            onClose()
        },
    })
    const updateMutation = useMutation(symptomCatalog.update, {
        onSuccess: () => {
            queryClient.invalidateQueries(['symptom-catalog', 'list'])
            onClose()
        },
    })

    const handelSubmit = (formData) => {
        if (!isNil(current)) {
            updateMutation.mutate({
                ...data,
                ...formData,
            })
        } else {
            createMutation.mutate(formData)
        }
    }

    return isFetching ? (
        <Spin />
    ) : (
        <Form
            autoComplete='off'
            layout='vertical'
            name='add-type-form'
            onFinish={handelSubmit}
            initialValues={!isNil(current) ? data : {}}
        >
            <Item name='name' label='分类名称' rules={[{ required: true, message: '分类名称必填' }]}>
                <Input placeholder='请输入内容，最多6个字' />
            </Item>
            <Item name='desr' label='分类说明'>
                <TextArea rows={4} placeholder='多行输入，不超过100字' maxLength={100} showCount />
            </Item>
        </Form>
    )
}
