/*
 * @LastEditTime: 2022-11-07 13:41:29
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/System/Account/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Input, message, Tag, TreeSelect, Select, DatePicker } from 'antd'
import { Segmented, Button, Space, Table, Drawer, Form } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { isNil, isEmpty } from 'ramda'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { useDisclosure } from 'utils/useDisclosure'
import { account, area } from 'service'
import { OPS_APPID, TEST_APPID, YYD_APPID } from 'utils/const'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'
import dayjs from 'dayjs'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function AccountMain() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        account: StringParam,
        name: StringParam,
        mobile: StringParam,
        areaId: StringParam,
        enabled: StringParam,
        period: ArrayParam,
        appid: withDefault(StringParam, OPS_APPID),
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createdTime,desc'),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const { data, isFetching } = useQuery(['account', 'page', query], () => account.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })
    const { data: treeData } = useQuery(['area-tree'], () => area.tree(), { placeholderData: [] })

    const deleteMutation = useMutation(account.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries('account')
            message.success('删除成功')
        },
    })

    const resetPwdMutation = useMutation(account.resetPwd, {
        onSuccess: () => message.success('重置成功'),
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '帐号',
            dataIndex: 'account',
        },
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
        },
        {
            title: '账号类型',
            dataIndex: 'type',
        },
        {
            title: '角色',
            key: 'role',
            render: (r) => r.roleVOList.map((e) => e.name).join('，'),
        },
        {
            title: '所属区域组织',
            dataIndex: 'areaName',
        },
        {
            title: '最后登录时间',
            dataIndex: 'lastLoignTime',
        },
        {
            title: '状态',
            key: 'enabled',
            render: (r) => (r.enabled ? <Tag color='success'>可用</Tag> : <Tag color='error'>禁用</Tag>),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link' }}
                        buttonText='重置密码'
                        tips={`你确定要重置【${r.name}】的密码？`}
                        onConfirm={() => resetPwdMutation.mutate(r.id)}
                    />
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]
    return (
        <Wrap>
            <Form
                autoComplete='off'
                layout='inline'
                className='gap-2'
                onFinish={(value) =>
                    setQuery({
                        ...value,
                        period:
                            !isEmpty(value?.period) && value?.period !== undefined
                                ? [
                                      value?.period[0]?.format('YYYY-MM-DD HH:mm:ss'),
                                      value.period[1].format('YYYY-MM-DD HH:mm:ss'),
                                  ]
                                : undefined,
                        page: 0,
                    })
                }
                initialValues={{
                    ...query,
                    period: query.period ? [dayjs(query.period[0]), dayjs(query.period[1])] : [],
                }}
            >
                <Item>
                    <Segmented
                        value={query.appid}
                        options={[
                            { label: '管理端', value: OPS_APPID },
                            { label: '鱼药店端', value: YYD_APPID },
                            { label: '检测机构端', value: TEST_APPID },
                        ]}
                        onChange={(appid) => setQuery({ appid, page: 0 })}
                    />
                </Item>
                <Item name='account'>
                    <Input placeholder='帐号' />
                </Item>
                <Item name='name'>
                    <Input placeholder='姓名' />
                </Item>
                <Item name='mobile'>
                    <Input placeholder='电话' />
                </Item>
                <Item name='areaId' className='w-48'>
                    <TreeSelect
                        allowClear
                        treeData={treeData}
                        fieldNames={{ value: 'id' }}
                        placeholder='所属区域组织'
                    />
                </Item>
                <Item name='enabled' className='w-48'>
                    <Select
                        placeholder='账号状态'
                        allowClear
                        options={[
                            { label: '可用', value: 'true' },
                            { label: '禁用', value: 'false' },
                        ]}
                    />
                </Item>
                <Item name='period' className='w-60'>
                    <DatePicker.RangePicker showTime className='w-full' placeholder={['最后登录', '始止时间']} />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建帐号' : '修改帐号'}
                onClose={onClose}
                extra={
                    <Button form='account-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? (
                    <Create onClose={onClose} appid={query.appid} />
                ) : (
                    <Update current={current} appid={query.appid} onClose={onClose} />
                )}
            </Drawer>
        </Wrap>
    )
}
