/**
 * 记录详情
 */
import tw from 'twin.macro'
import { Descriptions, Button, Table, Image, Divider, Space } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Spin } from 'antd'

import { Decrypt } from 'utils/encrypt'
import { symptomManifest } from 'service'

const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center border-b bg-white sticky top-0 text-xl gap-2 z-10`
const Content = tw.div`p-4 space-y-4`
const { Item } = Descriptions

export default function RecordDetail() {
    const navigate = useNavigate()
    const { recordId } = useParams()

    const { isLoading, data } = useQuery(
        ['symptom-manifest', 'one', recordId],
        () => symptomManifest.one(Decrypt(recordId)),
        {
            enabled: !!recordId,
        }
    )

    const columns = [
        {
            title: '药品分类',
            dataIndex: 'categoryName',
        },
        {
            title: '药品名称',
            dataIndex: 'medicineName',
        },
        {
            title: '药品规格',
            dataIndex: 'specification',
        },
        {
            title: '用法用量',
            dataIndex: 'usage',
        },
        {
            title: '是否处方药',
            dataIndex: 'prescription',
            render: (r) => (r ? '是' : '否'),
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '数量',
            dataIndex: 'amount',
        },
        {
            title: '总价',
            dataIndex: 'fee',
        },
    ]
    if (isLoading) {
        return <Spin />
    }
    return (
        <Wrap>
            <Header>
                <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
                开方记录
            </Header>
            <Content>
                <Divider>病例信息</Divider>
                <Descriptions bordered column={1} size='small' labelStyle={{ width: 200 }}>
                    <Item label='病例编号'>{data.code}</Item>
                    <Item label='养殖户'>{data.fisher}</Item>
                    <Item label='联系电话'>{data.fisherPhone}</Item>
                    <Item label='养殖鱼塘'>{data.pondName}</Item>
                    <Item label='来店时间'>{data.comingTime}</Item>
                    <Item label='病例分类'>{data.catalogName}</Item>
                    <Item label='病例名称'>{data.symptomName}</Item>
                    <Item label='主诉症状'>{data.mainSymptom}</Item>
                    <Item label='图片'>
                        <Space>
                            {data.attachmentVOList?.map((item) => (
                                <Image
                                    key={item.key}
                                    width={150}
                                    height={150}
                                    src={item.url}
                                    style={{ objectFit: 'cover' }}
                                />
                            ))}
                        </Space>
                    </Item>
                </Descriptions>
                <Divider>药方信息</Divider>
                <Descriptions bordered column={1} size='small'>
                    <Item label='用药信息'>
                        <Table
                            rowKey='id'
                            dataSource={data.medUsageVOList ?? []}
                            columns={columns}
                            pagination={false}
                            size='small'
                        />
                    </Item>
                    <Item label='是否加急'>{data.earged ? '是' : '否'}</Item>
                    <Item label='审核状态'>{data.statusName}</Item>
                    <Item label='开方药店'>{data.pharmacyName}</Item>
                    <Item label='开方药师'>{data.createBy}</Item>
                    <Item label='开方时间'>{data.createTime}</Item>
                </Descriptions>
            </Content>
        </Wrap>
    )
}
