/*
 * @LastEditTime: 2022-10-26 09:39:59
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Certificate/Record/Detail.js
 */
import tw from 'twin.macro'
import { Descriptions, Segmented, Space, Button, Spin } from 'antd'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'

import { certificate } from 'service'
import { Decrypt } from 'utils/encrypt'

import InTest from './InTest'
import Symptom from './Symptom'
import ProductionLog from './ProducationLog'

const { Item } = Descriptions
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b sticky top-0 bg-white z-10`
const Content = tw.div`p-4 space-y-4`

export default function Detail() {
    const { certificateId } = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const { data, isFetching } = useQuery(
        ['certificate', 'one', certificateId],
        () => certificate.one(Decrypt(certificateId)),
        {
            placeholderData: {},
        }
    )

    if (isFetching) {
        return <Spin />
    }

    return (
        <div className='space-y-4'>
            <Header>
                <Space>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />
                    <span className='text-xl font-bold'>合格证信息</span>
                </Space>
            </Header>
            <Content>
                <Descriptions column={3} bordered>
                    <Item label='批次号'>{data?.batchNo}</Item>
                    <Item label='合格证编号'>{data?.code}</Item>
                    <Item label='养殖户'>{data?.farmersName}</Item>
                    <Item label='联系电话'>{data?.farmersPhone}</Item>
                    <Item label='鱼塘'>{data?.fishPondName}</Item>
                    <Item label='鱼种'>{data.fryTypeName}</Item>
                    <Item label='投苗时间'>{data.seedlingsTime}</Item>
                    <Item label='收鱼时间'>{data.harvestTime}</Item>
                    <Item label='申请时间'>{data.createTime}</Item>
                    <Item label='发放时间'>{data.grantedTime}</Item>
                    <Item label='有效期至'>{data.validDate}</Item>
                </Descriptions>
                <div className='text-center'>
                    <Segmented
                        value={location.state}
                        className='mb-5'
                        options={[
                            { label: '入苗检测', value: 'test' },
                            { label: '用药记录', value: 'symptom' },
                            { label: '巡塘任务', value: 'inspect' },
                        ]}
                        onChange={(value) => navigate(location, { state: value, replace: true })}
                    />
                    {location.state === 'test' ? (
                        <InTest current={data} />
                    ) : location.state === 'symptom' ? (
                        <Symptom current={data} />
                    ) : (
                        <ProductionLog current={data} />
                    )}
                </div>
            </Content>
        </div>
    )
}
