import tw from 'twin.macro'
import { Button, Table, Space, message, Drawer } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Descriptions } from 'antd'
import { account } from 'service'
import ConfirmButton from 'components/ConfirmButton'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDisclosure } from 'utils/useDisclosure'
import { useState } from 'react'
import { isNil } from 'ramda'

import Create from './Create'
import Update from './Update'

const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b sticky top-0 bg-white z-10`
const Content = tw.div`p-4 space-y-4`

export default function PharmacyInfoDetail({ current, onClose }) {
    const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()

    const queryClient = useQueryClient()

    const [query, setQuery] = useState({
        pharmacyId: current.id,
        page: 0,
        size: 10,
    })
    const [accountItem, setAccount] = useState(null)
    const { data, isFetching } = useQuery(['account', 'page', query], () => account.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
        cacheTime: 0,
    })

    const deleteMutation = useMutation(account.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries('account')
            message.success('删除成功')
        },
    })

    const handleCreate = () => {
        setAccount(null)
        onOpenDrawer()
    }

    const handleUpdate = (item) => {
        setAccount(item)
        onOpenDrawer()
    }
    const resetPwdMutation = useMutation(account.resetPwd, {
        onSuccess: () => message.success('重置成功'),
    })

    const columns = [
        {
            title: '账号名称',
            dataIndex: 'account',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
        },
        {
            title: '名称',
            dataIndex: 'name',
        },
        {
            title: '创建人',
            dataIndex: 'creater',
        },
        {
            title: '创建时间',
            dataIndex: 'createdTime',
        },
        {
            title: '最近登录时间',
            dataIndex: 'lastLoignTime',
        },
        {
            title: '状态',
            key: 'state',
            render: (r) => (r.enabled ? '启用' : '禁用'),
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link' }}
                        buttonText='重置密码'
                        tips={`你确定要重置【${r.name}】的密码？`}
                        onConfirm={() => resetPwdMutation.mutate(r.id)}
                    />
                    <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                        编辑
                    </Button>
                    <ConfirmButton
                        buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                        onConfirm={() => deleteMutation.mutate(r.id)}
                    />
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Header>
                <Space>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={onClose} />
                    <span className='text-xl font-bold'>账号信息</span>
                </Space>
                <Space>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Space>
            </Header>
            <Content>
                <Descriptions column={2}>
                    <Descriptions.Item label='药店名称'>{current.name}</Descriptions.Item>
                    <Descriptions.Item label='所属区域'>{current.areaName}</Descriptions.Item>
                    <Descriptions.Item label='药店负责人'>{current.principal}</Descriptions.Item>
                    <Descriptions.Item label='负责人联系电话'>{current.phone}</Descriptions.Item>
                </Descriptions>

                <Table
                    rowKey='id'
                    dataSource={data.content}
                    columns={columns}
                    loading={isFetching}
                    pagination={{
                        total: data?.totalElements ?? 0,
                        pageSize: query.size,
                        current: query.page + 1,
                        onChange: (page) => setQuery({ page: page - 1 }),
                    }}
                />
            </Content>

            <Drawer
                visible={isOpenDrawer}
                title={isNil(accountItem) ? '新建帐号' : '修改帐号'}
                onClose={onCloseDrawer}
                extra={
                    <Button form='account-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(accountItem) ? (
                    <Create onClose={onCloseDrawer} current={current} />
                ) : (
                    <Update current={accountItem} onClose={onCloseDrawer} />
                )}
            </Drawer>
        </Wrap>
    )
}
