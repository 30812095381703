/*
 * @LastEditTime: 2022-09-20 10:18:15
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/Date.js
 */

import { useState } from 'react'
import dayjs from 'dayjs'
import { useInterval } from 'react-use'

const arr = ['一', '二', '三', '四', '五', '六', '日']

export default function Main({ userInfo }) {
    const [currentTime, setTime] = useState(null)
    const [currentDate] = useState(dayjs())
    useInterval(() => {
        setTime(dayjs().format('HH:mm:ss'))
    }, 1000)
    return (
        <div className='w-full h-full flex flex-col items-center justify-center text-right'>
            <div className='w-72 text-[#64e5ce] text-2xl flex items-center justify-end gap-4'>
                <div>{userInfo.areaName}</div>
                <div>{currentTime}</div>
            </div>
            <div className='w-72 text-[#daf4ef] text-lg'>
                {currentDate.format('YYYY年MM月DD日')} 星期{arr[currentDate.get('day') - 1]}
            </div>
        </div>
    )
}
