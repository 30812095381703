/*
 * @LastEditTime: 2022-08-30 16:32:49
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Main/index.js
 */
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'

import Menu from './Menu'
import Header from './Header'

const { Content } = Layout

export default function Main() {
    return (
        <Layout className='h-screen w-screen'>
            <Menu />
            <Layout>
                <Header />
                <Content className='p-4'>
                    <div className='w-full h-full overflow-y-auto bg-white rounded-sm shadow-md'>
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}
