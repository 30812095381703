/*
 * @LastEditors: Alps Chan
 * @LastEditTime: 2022-07-20 11:13:50
 * @FilePath: /muyu-web/src/pages/Information/InformationContent/Editor.js
 */
import tw from 'twin.macro'
import styled from 'styled-components'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { useImperativeHandle, forwardRef, useState } from 'react'

import { FILE_URL } from 'utils/api'

import 'braft-editor/dist/index.css'
import 'braft-extensions/dist/table.css'

const Wrap = styled.div`
    & > .bf-container {
        display: flex;
        flex-direction: column;
        & > .bf-controlbar {
            flex-shrink: 0;
        }
        & > .bf-content {
            height: 100%;
        }
    }
    ${tw`h-[420px] border`}
`

const options = {
    defaultColumns: 5, // 默认列数
    defaultRows: 5, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
}

function Editor({ content, disabled }, ref) {
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(content))
    BraftEditor.use(Table(options))

    const handleUpload = (param) => {
        const serviceUrl = `${FILE_URL}?bucketName=img`
        const xhr = new XMLHttpRequest()
        const fd = new FormData()

        const successFn = (response) => {
            // console.log('??', response)
            param.success({
                url: JSON.parse(response.target.response).body.url,
            })
        }

        const progressFn = (event) => {
            // 上传进度发生变化时调用param.progress
            param.progress((event.loaded / event.total) * 100)
        }

        const errorFn = () => {
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.',
            })
        }

        xhr.upload.addEventListener('progress', progressFn, false)
        xhr.addEventListener('load', successFn, false)
        xhr.addEventListener('error', errorFn, false)
        xhr.addEventListener('abort', errorFn, false)

        fd.append('file', param.file)
        xhr.open('POST', serviceUrl, true)
        xhr.setRequestHeader('auth', localStorage.getItem('Authorization'))
        xhr.send(fd)
    }

    useImperativeHandle(ref, () => ({
        getHtml: () => editorState.toHTML(),
    }))

    return (
        <Wrap>
            <BraftEditor
                disabled={disabled}
                value={editorState}
                media={{
                    uploadFn: handleUpload,
                }}
                onChange={(state) => setEditorState(state)}
            />
        </Wrap>
    )
}

export default forwardRef(Editor)
