/*
 * @LastEditTime: 2022-08-03 17:35:49
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Task/TaskStoreList/FarmProduce.js
 */
import { Checkbox, Descriptions } from 'antd'
import { head, is } from 'ramda'
import { useMemo } from 'react'

const { Item } = Descriptions

export default function Main({ data }) {
    const current = useMemo(() => (data ? (is(Array, data) ? head(data) : data) : {}), [data])
    return (
        <Descriptions bordered column={2} labelStyle={{ width: '300px' }}>
            <Item label='被检查单位名称'>{current.company}</Item>
            <Item label='检查地点'>{current.address}</Item>
            <Item label='类别' span={2}>
                {current.catalogName}
            </Item>
            <Item label='生产（经营）品种'>{current.variety}</Item>
            <Item label='规模（面积/库存等）'>{current.scale}</Item>
            <Item span={2} label='依法履行行政许可手续、被检查单位有关人员是否法定资质'>
                <Checkbox checked={current.qualification} />
            </Item>
            <Item span={2} label='依法制作农业投入品生产、经营、销售记录，及其相关台账、资料等'>
                <Checkbox checked={current.make} />
            </Item>
            <Item span={2} label='是否建立完善的农业投入品生产经营管理制度、操作规范等'>
                <Checkbox checked={current.build} />
            </Item>
            <Item span={2} label='商毒农药、限用农药档案记录是否规范'>
                <Checkbox checked={current.pesticide} />
            </Item>
            <Item span={2} label='主要负责人是否了解农产品质量管理的相关法律、法规及相关禁止性规定'>
                <Checkbox checked={current.learn} />
            </Item>
            <Item span={2} label='农业投入品是否实施追溯管理'>
                <Checkbox checked={current.traceability} />
            </Item>
            <Item span={2} label='主要问题'>
                {current.problem}
            </Item>
            <Item span={2} label='整改意见'>
                {current.opinion}
            </Item>
            <Item span={2} label='限制整改时间'>
                {current.rectificationDate}
            </Item>
            <Item span={2} label='备注'>
                {current.remark}
            </Item>
            <Item label='巡查员'>{current.patrollerName}</Item>
            <Item label='巡查时间'>{current.createTime}</Item>
        </Descriptions>
    )
}
