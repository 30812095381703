/*
 * @LastEditTime: 2022-10-13 10:04:50
 * @LastEditors: Alps Chan
 * @FilePath: \muyu-web\src\pages\User\Fisher\Create.js
 */
import { DatePicker, Form, Input, Segmented, Switch } from 'antd'
import { useMutation, useQueryClient } from 'react-query'

import { user } from 'service'

const { Item, useForm } = Form

export default function PatrolCreate({ onClose }) {
    const [form] = useForm()

    const queryClient = useQueryClient()

    const createMutation = useMutation(user.create, {
        onSuccess: () => {
            queryClient.invalidateQueries(['fisher'])
            onClose()
        },
    })

    const onSubmit = (values) => {
        createMutation.mutate({
            ...values,
            age: 1,
            birthday: values.birthday?.format('YYYY-MM-DD') ?? undefined,
        })
    }

    return (
        <Form form={form} name='patrol-drawer-form' onFinish={onSubmit} layout='vertical' autoComplete='off'>
            <Item label='姓名' name='name' rules={[{ required: true, message: '请输入' }]}>
                <Input />
            </Item>
            <Item
                label='手机号码'
                name='mobile'
                rules={[
                    { required: true, message: '请输入' },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                    },
                ]}
                validateTrigger='onBlur'
            >
                <Input maxLength={11} />
            </Item>
            <Item label='出生日期' name='birthday'>
                <DatePicker className='w-full' />
            </Item>
            <Item label='性别' name='gender' initialValue='male'>
                <Segmented
                    options={[
                        { label: '男', value: 'male' },
                        { label: '女', value: 'female' },
                    ]}
                />
            </Item>
            <Item
                label='账号状态'
                name='enabled'
                valuePropName='checked'
                rules={[{ required: true, message: '请输入选择类型' }]}
            >
                <Switch />
            </Item>
            <Item hidden name='type' initialValue='fisher'>
                <Input />
            </Item>
        </Form>
    )
}
