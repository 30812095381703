/*
 * @LastEditTime: 2022-09-06 09:56:33
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Statistics/Mechanism/TestingType.js
 */
import { Column } from '@ant-design/plots'
import { Form, TreeSelect, Radio, Card, Empty, Spin } from 'antd'

import { useMemo, useState } from 'react'
import { useQueries } from 'react-query'

import { testingRecord, area } from 'service'
import { isEmpty, unnest } from 'ramda'
import { renderTreeNode } from 'components/TreeNode'

const { Item } = Form

export default function TestingType() {
    const [query, setQuery] = useState({
        areaPaths: undefined,
        period: 'seven',
    })

    const [{ data: areaTree }, { data, isFetching }] = useQueries([
        {
            queryKey: ['area-tree', 'street'],
            queryFn: () => area.tree({ level: 'street' }),
            placeholderData: [],
        },
        {
            queryKey: ['testing-statis-area', query],
            queryFn: () => testingRecord.statisTestingByType(query),
            placeholderData: [],
            keepPreviousData: true,
            select: (data) =>
                unnest(
                    data.map((x) => [
                        { day: x.period, type: '出苗检测', rate: x.emergence },
                        { day: x.period, type: '入苗检测', rate: x.seeding },
                        { day: x.period, type: '抽检检测', rate: x.spot },
                        { day: x.period, type: '病例检测', rate: x.symptom },
                    ])
                ),
        },
    ])

    const config = useMemo(
        () => ({
            data: data || [],
            isGroup: true,
            xField: 'day',
            yField: 'rate',
            seriesField: 'type',
            label: {
                position: 'middle',
                layout: [
                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
            },
        }),
        [data]
    )

    return (
        <Card title='检测类型合计' headStyle={{ background: '#f1f1f1' }}>
            <Spin spinning={isFetching}>
                <Form autoComplete='off' layout='inline' className='gap-2 justify-end'>
                    <Item className='w-48'>
                        <TreeSelect
                            value={query.areaPaths}
                            placeholder='全部区域'
                            allowClear
                            onChange={(value) => setQuery({ ...query, areaPaths: value })}
                            showSearch
                            treeNodeFilterProp='filter'
                        >
                            {renderTreeNode(areaTree, 'street')}
                        </TreeSelect>
                    </Item>
                    <Item>
                        <Radio.Group
                            value={query.period}
                            onChange={(e) => setQuery({ ...query, period: e.target.value })}
                        >
                            <Radio.Button value='seven'>近7天</Radio.Button>
                            <Radio.Button value='thirty'>近30天</Radio.Button>
                            <Radio.Button value='bymonth'>按年</Radio.Button>
                        </Radio.Group>
                    </Item>
                </Form>
                <div className='mt-4'>
                    {!isEmpty(data) ? (
                        <Column {...config} />
                    ) : (
                        <Empty className='h-60 flex flex-col items-center justify-center' />
                    )}
                </div>
            </Spin>
        </Card>
    )
}
