/*
 * @LastEditTime: 2022-09-15 16:31:06
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/User/Fisher/PondList.js
 */

import { Table, message, Popover, Button, Image } from 'antd'
import { useQuery, useMutation } from 'react-query'

import { fishPond } from 'service'

export default function Main({ actualFarmersId }) {
    const { data, isFetching, refetch } = useQuery(['pond', 'list', actualFarmersId], () =>
        fishPond.list({ actualFarmersId })
    )

    const initQrCodeMutation = useMutation(fishPond.initQrCode, {
        onSuccess: () => {
            message.success('操作成功')
            refetch()
        },
    })

    const columns = [
        {
            title: '鱼塘编号',
            dataIndex: 'pondCode',
        },
        {
            title: '鱼塘名称',
            dataIndex: 'pondName',
        },
        {
            title: '所属区域',
            dataIndex: 'areaName',
        },
        {
            title: '鱼塘租户',
            dataIndex: 'pondTenant',
        },
        {
            title: '鱼塘租户电话',
            dataIndex: 'pondTenantPhone',
        },
        {
            title: '实际养殖户',
            dataIndex: 'actualFarmers',
        },
        {
            title: '实际养殖户电话',
            dataIndex: 'actualFarmersPhone',
        },
        {
            title: '鱼塘二维码',
            align: 'center',
            key: 'qrCode',
            render: (item) =>
                item.pondQrCode ? (
                    <Popover content={<Image src={item.pondQrCode} preview={false} />} trigger='click'>
                        <Button size='small' type='link'>
                            查看
                        </Button>
                    </Popover>
                ) : (
                    <Button size='small' type='link' onClick={() => initQrCodeMutation.mutate(item.id)}>
                        生成二维码
                    </Button>
                ),
        },
    ]

    return (
        <Table
            rowKey='id'
            loading={isFetching}
            scroll={{ y: 520 }}
            columns={columns}
            dataSource={data}
            pagination={false}
        />
    )
}
