/*
 * @LastEditTime: 2022-05-27 08:39:32
 * @LastEditors: Alps Chan
 * @FilePath: /meihua-web/src/page/components/ConfirmButton/index.js
 */
import { Popconfirm, Button } from 'antd'

export default function ConfirmButton({
    tips = '是否确定删除？',
    buttonText = '删除',
    buttonProps,
    iconButton,
    onConfirm,
    disabled,
}) {
    const handleConfirm = (e) => {
        e.stopPropagation()
        onConfirm()
    }

    return disabled ? (
        <Button {...buttonProps} disabled>
            {!iconButton && buttonText}
        </Button>
    ) : (
        <Popconfirm title={tips} onConfirm={handleConfirm} onCancel={(e) => e.stopPropagation()}>
            <Button {...buttonProps} onClick={(e) => e.stopPropagation()}>
                {!iconButton && buttonText}
            </Button>
        </Popconfirm>
    )
}
