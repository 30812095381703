/*
 * @LastEditTime: 2022-09-09 14:30:12
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/utils/createService.js
 */
import { api } from './api'
import { mergeRight, isNil } from 'ramda'

/**
 * @param {object} payload 参数
 * @param {string} [payload.service] 微服务名称
 * @param {string} [payload.modules] 模块名称
 * @param {object[]} [payload.others] 其他接口列表
 */

export function createService({ service, modules, others }) {
    let result = {}
    if (service && modules) {
        // 创建增、删、改、查模块
        result = mergeRight(result, {
            page: (p) => {
                const params = { ...p }
                return api.get(`${service}/${modules}/page`, { params })
            },
            one: (id) => api.get(`${service}/${modules}/${id}`),
            create: (payload) => api.post(`${service}/${modules}`, payload),
            update: (payload) => api.put(`${service}/${modules}/${payload.id}`, payload),
            delete: (id) => api.delete(`${service}/${modules}/${id}`),
            list: (params) => api.get(`${service}/${modules}/list`, { params }),
        })
    }

    if (others && !isNil(others)) {
        // 创建自定义模块
        result = mergeRight(result, others)
    }

    return result
}
