/*
 * @LastEditTime: 2022-08-30 17:47:35
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Grade/Record/index.js
 */
import { Tabs } from 'antd'

import FishShop from './FishShop'
import TestOrgan from './TestOrgan'

const { TabPane } = Tabs

export default function TestingOrgan() {
    return (
        <Tabs tabBarStyle={{ padding: '0 1rem' }} defaultActiveKey='1'>
            <TabPane tab='鱼药店评级' key='1'>
                <FishShop />
            </TabPane>
            <TabPane tab='检测机构评级' key='2'>
                <TestOrgan />
            </TabPane>
        </Tabs>
    )
}
