/*
 * @LastEditTime: 2022-09-28 08:43:29
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/Map.js
 */
import { isEmpty } from 'ramda'
import { QMap, Polygon, Marker, config } from 'react-tmap'
import { useCallback, useMemo } from 'react'

const qq = window.qq

// const selected =
//     'https://shuzihua.oss-cn-shenzhen.aliyuncs.com/2022/9/19/0bc78f5e1e8141c1ad92b7d64fb730ea.png?Expires=4817181116&OSSAccessKeyId=LTAI5t8emK4KPXYxgiA4aW4K&Signature=qRncutsWMPQqodrmHfEW1AB5LuI%3D'
const normal =
    'https://shuzihua.oss-cn-shenzhen.aliyuncs.com/2022/9/19/b18cb623de1341dabcaf47992954e91a.png?Expires=4817181135&OSSAccessKeyId=LTAI5t8emK4KPXYxgiA4aW4K&Signature=R5Mwbhv2uehRkueN4%2FIHzsG%2BFLk%3D'
const filter =
    'https://shuzihua.oss-cn-shenzhen.aliyuncs.com/2022/9/19/0cd4bab2bb3c47bab55679c82d7f1e85.png?Expires=4817181155&OSSAccessKeyId=LTAI5t8emK4KPXYxgiA4aW4K&Signature=3VcMqt%2BQd1dgCnqPfAGj3EhcFrE%3D'

export default function TMap({ showType, selectedPond, pondList = [], pharmacyList = [], onMarkerClick }) {
    const latLng = useMemo(
        () =>
            showType === 'inspect'
                ? !isEmpty(selectedPond) && JSON.parse(selectedPond.pondCoordinate)[0]
                : { lat: selectedPond.latitude, lng: selectedPond.longitude },
        [selectedPond, showType]
    )

    const isActive = useCallback(
        (currentId) =>
            currentId === selectedPond.id
                ? {
                      fillColor: new qq.maps.Color(211, 23, 44, 0.2),
                      strokeColor: new qq.maps.Color(211, 23, 44, 0.8),
                  }
                : {
                      fillColor: new qq.maps.Color(61, 169, 169, 0.3),
                      strokeColor: new qq.maps.Color(61, 169, 169, 0.8),
                  },
        [selectedPond]
    )

    return (
        <QMap
            style={{ height: '100%' }}
            mapTypeId='hybrid'
            center={
                isEmpty(selectedPond)
                    ? {
                          lng: 113.18073986346248,
                          lat: 22.71990708209343,
                      }
                    : latLng
            }
            zoom={18}
        >
            {pharmacyList.map((pm) => (
                <Marker
                    key={pm.id}
                    position={{ lat: pm.latitude, lng: pm.longitude }}
                    visible
                    animation={config.ANIMATION_DROP}
                    icon={selectedPond.id === pm.id ? filter : normal}
                    events={{
                        click: () => onMarkerClick(pm, 'patrol'),
                    }}
                />
            ))}
            {pondList.map((mk) => (
                <Polygon
                    key={mk.id}
                    visible
                    {...isActive(mk.id)}
                    clickable
                    points={JSON.parse(mk.pondCoordinate)}
                    events={{
                        click: () => onMarkerClick(mk, 'inspect'),
                    }}
                />
            ))}
        </QMap>
    )
}
