/*
 * @LastEditTime: 2022-08-30 21:38:33
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Login/index.js
 */
import styled from 'styled-components'
import { Button, Form, Input } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'

import { account } from 'service'

const Item = styled(Form.Item)`
    .ant-form-item-control-input {
        border-bottom: 1px solid #ccc;
    }
`
const CustomInput = styled(Input)`
    height: 60px;
    .ant-input-prefix {
        font-size: 20px;
        margin-right: 20px;
        color: var(--ant-primary-color);
    }
`

export default function LoginPage() {
    const [form] = Form.useForm()

    const loginMutation = useMutation(account.login, {
        onSuccess: (data) => {
            localStorage.setItem('Authorization', data)
            window.location.href = '/'
        },
    })

    const onSubmit = (values) => {
        loginMutation.mutate(values)
    }

    return (
        <div className='w-screen h-screen overflow-hidden bg-gray-200 flex items-center justify-center'>
            <Form
                form={form}
                className='w-[450px] !px-4 !py-10 bg-white rounded-md shadow-md'
                onFinish={onSubmit}
                autoComplete='off'
            >
                <Form.Item>
                    <div className='h-12 flex items-center justify-center text-xl font-bold'>顺德牧渔管理系统</div>
                </Form.Item>
                <Item name='agent' hidden initialValue='ops'>
                    <Input />
                </Item>
                <Item name='account' rules={[{ required: true, message: '请输入帐号' }]}>
                    <CustomInput bordered={false} prefix={<UserOutlined />} size='large' placeholder='用户名' />
                </Item>
                <Item name='pwd' rules={[{ required: true, message: '密码不能为空' }]}>
                    <CustomInput
                        bordered={false}
                        prefix={<LockOutlined />}
                        size='large'
                        placeholder='密码'
                        type='password'
                    />
                </Item>
                <Form.Item>
                    <Button loading={loginMutation.isLoading} type='primary' block size='large' htmlType='submit'>
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
