import tw from 'twin.macro'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FullscreenExitOutlined, FullscreenOutlined, ExportOutlined, SwapOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

import Date from './Date'
import PondInfo from './PondInfo'
import TaskHistory from './TaskHistory'
import InspectionTrend from './InspectionTrend'
import PondUnplannedRate from './PondUnplannedRate'
import FrytypeProportion from './FrytypeProportion'
import FishPondList from './FishPondList'
import TaskInfo from './TaskInfo'

import { useDisclosure } from 'utils/useDisclosure'
import { fryType } from 'service'

const ButtonWrap = tw.div`w-[60px] h-[60px] bg-[#041a40] cursor-pointer hover:text-white hover:border-white flex-shrink-0 bg-opacity-70 rounded-full border border-[#02cde6] text-[#02cde6] shadow-md flex flex-col items-center justify-center gap-2 text-sm`
const Left = tw.div`h-full w-[420px] absolute left-0 top-0 bg-gradient-to-r from-[rgba(4,26,64,0.7)] via-[rgba(4,26,64,0.5)] to-transparent flex flex-col items-center justify-between space-y-4 z-50 p-4`
const Title = tw.div`w-full bg-gradient-to-b from-[rgba(61,169,169,0.85)] to-transparent text-white text-lg px-4 py-2 rounded-full text-center`
const Right = tw.div`h-full w-[600px] absolute right-0 top-0 bg-gradient-to-r from-transparent via-[rgba(4,26,64,0.5)] to-[rgba(4,26,64,0.7)] flex flex-col items-center space-y-6 z-50 p-4`

export default function InspectFullStatic({
    userInfo,
    pondList,
    currentPond,
    isFullscreen,
    onFryTypeChange,
    onPondChange,
    toggle,
    fullRef,
}) {
    const [taskId, setTaskId] = useState(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()

    const { data: options } = useQuery(['fry-type', 'list'], () => fryType.list(), {
        placeholderData: [],
        select: (data) => data.map((x) => ({ label: x.fryType, value: x.id })),
    })

    const handleTaskClick = (value) => {
        setTaskId(value)
        onOpen()
    }

    return (
        <>
            <Left>
                <div className='h-[5%] text-white text-2xl flex items-center justify-center'>
                    顺德数字水产综合服务平台
                </div>
                <div className='h-[30%] w-full flex flex-col'>
                    <Title>鱼塘信息</Title>
                    <PondInfo pondId={currentPond?.id} />
                </div>
                <div className='h-[30%] w-full flex flex-col'>
                    <Title>历史巡查情况</Title>
                    <TaskHistory fishPondId={currentPond?.id} onRowClick={handleTaskClick} />
                </div>
                <div className='h-[30%] w-full flex flex-col'>
                    <Title>鱼种养殖比例</Title>
                    <FrytypeProportion areaPaths={userInfo.areaPaths} />
                </div>
            </Left>
            <Right>
                <div className='w-full text-white flex items-center justify-end gap-4'>
                    <Date userInfo={userInfo} />
                    <ButtonWrap onClick={() => toggle(!isFullscreen)}>
                        {isFullscreen ? (
                            <>
                                <FullscreenExitOutlined style={{ fontSize: 18 }} />
                                <span className='text-xs'>退出全屏</span>
                            </>
                        ) : (
                            <>
                                <FullscreenOutlined style={{ fontSize: 18 }} />
                                <span>全屏</span>
                            </>
                        )}
                    </ButtonWrap>
                    <ButtonWrap onClick={() => navigate('/full-statistics', { replace: true })}>
                        <SwapOutlined style={{ fontSize: 18 }} />
                        <span>切换</span>
                    </ButtonWrap>
                    <ButtonWrap onClick={() => navigate(-1)}>
                        <ExportOutlined style={{ fontSize: 18 }} />
                        <span>退出</span>
                    </ButtonWrap>
                </div>
                <div className='h-[25%] w-full flex flex-col gap-4'>
                    <Title>鱼塘巡查趋势</Title>
                    <InspectionTrend pondId={currentPond?.id} />
                </div>
                <div className='h-[25%] w-full flex flex-col gap-4'>
                    <Title>鱼塘非计划任务完成率</Title>
                    <PondUnplannedRate pondId={currentPond?.id} />
                </div>
                <div className='h-[35%] w-full flex flex-col gap-4'>
                    <Title>目标鱼种养殖鱼塘</Title>
                    <FishPondList
                        data={pondList}
                        options={options}
                        onPondClick={onPondChange}
                        onSelect={onFryTypeChange}
                    />
                </div>
            </Right>
            <Modal
                visible={isOpen}
                title={null}
                footer={null}
                getContainer={() => fullRef.current}
                width='1200px'
                closable={false}
                centered
                onCancel={onClose}
            >
                <TaskInfo taskId={taskId} pondInfo={currentPond} getContainer={() => fullRef.current} />
            </Modal>
        </>
    )
}
