/*
 * @LastEditTime: 2022-09-20 16:55:20
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/System/Role/index.js
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Space, Table, Drawer, Form, Input, message, Segmented } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { useDisclosure } from 'utils/useDisclosure'
import { role } from 'service'
import { OPS_APPID, TEST_APPID, YYD_APPID } from 'utils/const'

import ConfirmButton from 'components/ConfirmButton'
import Create from './Create'
import Update from './Update'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form

export default function RoleMain() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [query, setQuery] = useQueryParams({
        code: StringParam,
        name: StringParam,
        appid: withDefault(StringParam, OPS_APPID),
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
    })
    const [current, setCurrent] = useState(null)
    const queryClient = useQueryClient()

    const { data, isFetching } = useQuery(['role', 'page', query], () => role.page(query), {
        placeholderData: {
            content: [],
            totalElements: 0,
        },
    })

    const deleteMutation = useMutation(role.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries('role')
            message.success('删除成功')
        },
    })

    const handleCreate = () => {
        setCurrent(null)
        onOpen()
    }

    const handleUpdate = (item) => {
        setCurrent(item)
        onOpen()
    }

    const columns = [
        {
            title: '编码',
            dataIndex: 'code',
        },
        {
            title: '角色名称',
            dataIndex: 'name',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) =>
                r.mutable && (
                    <Space>
                        <Button type='link' size='small' onClick={() => handleUpdate(r)}>
                            编辑
                        </Button>
                        <ConfirmButton
                            buttonProps={{ size: 'small', type: 'link', loading: deleteMutation.isLoading }}
                            onConfirm={() => deleteMutation.mutate(r.id)}
                        />
                    </Space>
                ),
        },
    ]
    return (
        <Wrap>
            <Form
                className='gap-2'
                autoComplete='off'
                layout='inline'
                onFinish={(value) => setQuery({ ...value, page: 0 })}
                initialValues={query}
            >
                <Item>
                    <Segmented
                        value={query.appid}
                        options={[
                            { label: '管理端', value: OPS_APPID },
                            { label: '鱼药店端', value: YYD_APPID },
                            { label: '检测机构端', value: TEST_APPID },
                        ]}
                        onChange={(appid) => setQuery({ appid })}
                    />
                </Item>
                <Item name='code'>
                    <Input placeholder='角色编码' />
                </Item>
                <Item name='name'>
                    <Input placeholder='角色名称' />
                </Item>
                <Item>
                    <Button type='primary' ghost htmlType='submit'>
                        查询
                    </Button>
                </Item>
                <Item>
                    <Button type='primary' onClick={handleCreate}>
                        新建
                    </Button>
                </Item>
            </Form>
            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
            <Drawer
                visible={isOpen}
                title={isNil(current) ? '新建角色' : '修改角色'}
                onClose={onClose}
                extra={
                    <Button form='role-drawer-form' type='primary' htmlType='submit'>
                        提交
                    </Button>
                }
                destroyOnClose
            >
                {isNil(current) ? <Create onClose={onClose} /> : <Update current={current} onClose={onClose} />}
            </Drawer>
        </Wrap>
    )
}
