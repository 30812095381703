/**
 * 新增病例
 */
import tw from 'twin.macro'
import { useState } from 'react'
import { Button, Form, Input, Select, Upload, Space, Image, message, Divider } from 'antd'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { isEmpty, isNil, map } from 'ramda'
import { useMutation, useQueries, useQueryClient } from 'react-query'

import { FILE_URL } from 'utils/api'
import { useDisclosure } from 'utils/useDisclosure'
import { symptomKn, symptomCatalog, fryType } from 'service'

const { Item, useWatch, useForm } = Form
const { Option } = Select
const { TextArea } = Input
const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b sticky top-0 bg-white z-10`
const Content = tw.div`p-4 space-y-4`

export default function Create({ onClose }) {
    const [form] = useForm()
    const imgLen = useWatch('attachmentVOList', form)
    const queryClient = useQueryClient()
    const { isOpen: imgIsOpen, onOpen: imgOnOpen, onClose: imgOnClose } = useDisclosure()
    const [previewImg, setPreviewImg] = useState('')

    const [{ data: options }, { data: fryTypes }] = useQueries([
        {
            queryKey: ['symptom-catalog', 'list'],
            queryFn: () => symptomCatalog.list(),
            placeholderData: [],
            select: (data) => data.map((x) => ({ label: x.name, value: x.id })),
        },
        {
            queryKey: ['fry-type', 'list'],
            queryFn: () => fryType.list(),
            placeholder: [],
            select: (data) => data.map((x) => ({ label: x.fryType, value: x.id })),
        },
    ])

    const createMutation = useMutation(symptomKn.create, {
        onSuccess: () => {
            message.success('新增成功')
            queryClient.invalidateQueries(['symptom-kn', 'page'])
            onClose()
        },
    })

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    // 保存
    const onSubmit = (values) => {
        createMutation.mutate({
            ...values,
            treatPlan: undefined,
            treatType: undefined,
            treatVO: {
                treatPlan: values.treatPlan,
                treatType: values.treatType,
            },
            attachmentVOList: map((item) => item.response.body, values.attachmentVOList),
        })
    }

    const handlePreview = (file) => {
        setPreviewImg(file.response.body.url)
        imgOnOpen()
    }

    return (
        <Wrap>
            <Header>
                <Space>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={onClose} />
                    <span className='text-xl font-bold'>添加病例</span>
                </Space>
                <Space>
                    <Button loading={createMutation.isLoading} type='primary' form='add-illness-form' htmlType='submit'>
                        提交
                    </Button>
                </Space>
            </Header>
            <Content>
                <Form form={form} name='add-illness-form' onFinish={onSubmit} autoComplete='off' layout='vertical'>
                    <Divider>病例信息</Divider>
                    <Item label='病例名称' name='name' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='病例编号' rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder='自动添加' readOnly />
                    </Item>
                    <Item label='鱼病分类' name='catalogId' rules={[{ required: true, message: '必填' }]}>
                        <Select allowClear placeholder='病例分类' options={options} />
                    </Item>
                    <Item label='患病鱼种' name='fryTypeId' rules={[{ required: true, message: '必填' }]}>
                        <Select allowClear placeholder='患病鱼种' options={fryTypes} />
                    </Item>
                    <Item
                        label='病例图片（建议尺寸120*90像素，大小不要超过1M，最多6张）'
                        name='attachmentVOList'
                        getValueFromEvent={normFile}
                        valuePropName='fileList'
                        rules={[{ required: true, message: '必填' }]}
                    >
                        <Upload
                            onPreview={handlePreview}
                            action={FILE_URL}
                            accept='image/*'
                            headers={{
                                auth: localStorage.getItem('Authorization'),
                            }}
                            name='file'
                            listType='picture-card'
                            data={{ bucketName: 'img' }}
                        >
                            {(isNil(imgLen) || isEmpty(imgLen) || imgLen.length < 6) && (
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            )}
                        </Upload>
                    </Item>
                    <Item label='病情描述' name='mainSymptom' rules={[{ required: true, message: '必填' }]}>
                        <TextArea rows={4} />
                    </Item>
                    <Divider>治疗方案</Divider>
                    <Item label='治疗方式' name='treatType' rules={[{ required: true, message: '必填' }]}>
                        <Select placeholder='请填写'>
                            <Option value='t1'>消毒+治疗</Option>
                            <Option value='t2'>消毒+治疗+补充营养</Option>
                            <Option value='t3'>消毒+治疗+补充营养+隔离</Option>
                        </Select>
                    </Item>
                    <Item label='治疗方案' name='treatPlan' rules={[{ required: true, message: '必填' }]}>
                        <TextArea rows={4} />
                    </Item>
                </Form>
            </Content>
            <Image
                preview={{
                    visible: imgIsOpen,
                    src: previewImg,
                    onVisibleChange: imgOnClose,
                }}
            />
        </Wrap>
    )
}
