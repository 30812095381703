/*
 * @LastEditors: Alps Chan
 * @LastEditTime: 2022-07-15 09:17:20
 * @FilePath: /muyu-web/src/utils/use.js
 */

import { useState, useCallback, useRef } from 'react'

function useControllableProp(prop, state) {
    const { current: isControlled } = useRef(prop !== undefined)
    const value = isControlled && typeof prop !== 'undefined' ? prop : state
    return [isControlled, value]
}

export function useDisclosure(props = {}) {
    const { onClose: onCloseProp, onOpen: onOpenProp, isOpen: isOpenProp } = props

    const [isOpenState, setIsOpen] = useState(props.defaultIsOpen || false)
    const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState)

    const onClose = useCallback(() => {
        if (!isControlled) {
            setIsOpen(false)
        }
        onCloseProp?.()
    }, [isControlled, onCloseProp])

    const onOpen = useCallback(() => {
        if (!isControlled) {
            setIsOpen(true)
        }
        onOpenProp?.()
    }, [isControlled, onOpenProp])

    const onToggle = useCallback(() => {
        const action = isOpen ? onClose : onOpen
        action()
    }, [isOpen, onOpen, onClose])

    return {
        isOpen: !!isOpen,
        onOpen,
        onClose,
        onToggle,
    }
}
