/*
 * @LastEditTime: 2022-09-09 11:11:57
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Main/UpdatePwdForm.js
 */
import { Form, Input, message } from 'antd'
import { account } from 'service'
import { useMutation } from 'react-query'
const { Item, useForm } = Form
export default function Main({ onClose }) {
    const [form] = useForm()

    const modifyMutation = useMutation(account.modifyPwd, {
        onSuccess: () => {
            message.success('修改成功')
            onClose()
        },
    })
    const onSubmit = ({ oldPwd, newPwd, confirmPwd }) => {
        if (newPwd !== confirmPwd) {
            form.setFields([
                { name: 'newPwd', errors: ['两次密码不一致'] },
                { name: 'confirmPwd', errors: ['两次密码不一致'] },
            ])
            return
        }
        modifyMutation.mutate({
            oldPwd: oldPwd,
            newPwd: newPwd,
        })
    }

    return (
        <Form form={form} name='update-password' layout='vertical' onFinish={onSubmit}>
            <Item label='旧密码' name='oldPwd' rules={[{ required: true, message: '必填' }]}>
                <Input type='password' />
            </Item>
            <Item label='新密码' name='newPwd' rules={[{ required: true, message: '必填' }]} help='密码长度在6～18之间'>
                <Input type='password' minLength={6} maxLength={18} />
            </Item>
            <Item label='确认密码' name='confirmPwd' rules={[{ required: true, message: '必填' }]}>
                <Input type='password' minLength={6} maxLength={18} />
            </Item>
        </Form>
    )
}
