/*
 * @LastEditTime: 2022-08-30 16:46:24
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Information/InformationContent/Create.js
 */
import { useRef } from 'react'
import tw from 'twin.macro'
import { Button, Form, Input, Select, Upload, Radio, Space, DatePicker } from 'antd'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { head, isEmpty, isNil } from 'ramda'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import moment from 'moment'

import Editor from './Editor'

import { FILE_URL } from 'utils/api'
import { cmsContent, cmsType } from 'service'

const { Item, useWatch, useForm } = Form
const Wrap = tw.div`w-full h-full relative`
const Header = tw.div`px-2 h-14 flex items-center justify-between border-b sticky top-0 bg-white z-10`
const Content = tw.div`p-4 space-y-4`

export default function InformationCreate({ onClose }) {
    const editorRef = useRef(null)
    const [form] = useForm()
    const imgLen = useWatch('url', form)
    const queryClient = useQueryClient()

    const { data } = useQuery(['cms-type', 'list'], () => cmsType.list(), {
        placeholderData: [],
    })

    const createMutation = useMutation(cmsContent.create, {
        onSuccess: () => {
            queryClient.invalidateQueries(['cms-content', 'page'])
            onClose()
        },
    })

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    // 保存并发布
    const onPublish = async () => {
        const values = await form.validateFields()
        const content = editorRef.current.getHtml()
        const url = head(values.url).response.body.url
        createMutation.mutate({
            ...values,
            content,
            grounding: true,
            url,
            publishedTime: moment(values.publishedTime).format('YYYY-MM-DD'),
        })
    }

    // 保存
    const onSubmit = (values) => {
        const content = editorRef.current.getHtml()
        const url = head(values.url).response.body.url
        createMutation.mutate({
            ...values,
            content,
            grounding: false,
            url,
            publishedTime: moment(values.publishedTime).format('YYYY-MM-DD'),
        })
    }

    return (
        <Wrap>
            <Header>
                <Space>
                    <Button type='text' icon={<ArrowLeftOutlined />} onClick={onClose} />
                    <span className='text-xl font-bold'>发布资讯</span>
                </Space>
                <Space>
                    <Button ghost type='primary' form='information-drawer-form' htmlType='submit'>
                        保存
                    </Button>
                    <Button type='primary' onClick={onPublish}>
                        保存并且发布
                    </Button>
                </Space>
            </Header>
            <Content>
                <Form
                    form={form}
                    className='w-[800px] grid grid-cols-2 gap-4'
                    name='information-drawer-form'
                    onFinish={onSubmit}
                    autoComplete='off'
                    layout='vertical'
                >
                    <Item label='资讯标题' name='title' rules={[{ required: true, message: '必填' }]}>
                        <Input />
                    </Item>
                    <Item label='资讯类型' name='cmsTypeId' rules={[{ required: true, message: '必填' }]}>
                        <Select options={data.map((item) => ({ value: item.id, label: item.name }))} />
                    </Item>
                    <Item label='发布日期' name='publishedTime' rules={[{ required: true, message: '必填' }]}>
                        <DatePicker className='w-full' />
                    </Item>

                    <Item label='是否推荐' name='recommend' initialValue={false}>
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Item>
                    <Item
                        label='封面图片'
                        name='url'
                        getValueFromEvent={normFile}
                        valuePropName='fileList'
                        rules={[{ required: true, message: '必填' }]}
                    >
                        <Upload
                            action={FILE_URL}
                            accept='image/*'
                            headers={{
                                auth: localStorage.getItem('Authorization'),
                            }}
                            name='file'
                            listType='picture-card'
                            data={{ bucketName: 'img' }}
                        >
                            {(isNil(imgLen) || isEmpty(imgLen)) && (
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            )}
                        </Upload>
                    </Item>
                </Form>
                <Editor ref={editorRef} />
            </Content>
        </Wrap>
    )
}
