/**
 * 药房记录
 */
import tw from 'twin.macro'
import React from 'react'
import { Button, Space, Table, Form, Input, Select, DatePicker } from 'antd'
import { useQueryParams, withDefault, NumberParam, StringParam, ArrayParam } from 'use-query-params'
import { isNil } from 'ramda'
import { useQueries } from 'react-query'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import { symptomCatalog, symptomManifest } from 'service'
import { Enscrypt } from 'utils/encrypt'

const Wrap = tw.div`w-full h-full space-y-4 p-4`
const { Item } = Form
const { RangePicker } = DatePicker

export default function Main() {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [query, setQuery] = useQueryParams({
        code: StringParam,
        symptomName: StringParam,
        catalogId: StringParam,
        status: StringParam,
        period: ArrayParam,
        pharmacyName: StringParam,
        page: withDefault(NumberParam, 0),
        size: withDefault(NumberParam, 10),
        sort: withDefault(StringParam, 'createTime,desc'),
    })

    const [{ data: options }, { data, isFetching }] = useQueries([
        {
            queryKey: ['symptom-catalog', 'list'],
            queryFn: () => symptomCatalog.list(),
            placeholderData: [],
            select: (data) => data.map((x) => ({ label: x.name, value: x.id })),
        },
        {
            queryKey: ['symptom-manifest', 'page', query],
            queryFn: () => symptomManifest.page(query),
            placeholderData: {
                content: [],
                totalElements: 0,
            },
        },
    ])

    const handleOpenDetail = (item) => {
        const id = Enscrypt(item.id)
        navigate(`/medication/record/${id}`)
    }

    const columns = [
        {
            title: '药方编号',
            dataIndex: 'code',
        },
        {
            title: '养殖户名称',
            dataIndex: 'fisher',
        },
        {
            title: '病例名称',
            dataIndex: 'symptomName',
        },
        {
            title: '病例分类',
            dataIndex: 'catalogName',
        },
        {
            title: '费用', //
            dataIndex: 'fee',
        },
        {
            title: '是否含处方药', //
            dataIndex: 'prescription',
            render: (r) => (r ? '是' : '否'),
        },
        {
            title: '开方药店',
            dataIndex: 'pharmacyName',
        },
        {
            title: '开方医师',
            dataIndex: 'createBy',
        },
        {
            title: '开方时间',
            dataIndex: 'createTime',
        },
        {
            title: '审核状态',
            dataIndex: 'statusName',
        },
        {
            title: '操作',
            key: 'operate',
            width: 150,
            align: 'center',
            render: (r) => (
                <Space>
                    <Button type='link' size='small' onClick={() => handleOpenDetail(r)}>
                        查看详情
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <Wrap>
            <Form
                className='gap-2'
                form={form}
                autoComplete='off'
                layout='inline'
                onFinish={(value) => {
                    setQuery({
                        ...value,
                        period: !isNil(value.period)
                            ? [
                                  dayjs(value.period[0]).format('YYYY-MM-DD 00:00:00'),
                                  dayjs(value.period[1]).format('YYYY-MM-DD 23:59:59'),
                              ]
                            : undefined,
                        page: 0,
                    })
                }}
            >
                <Item name='code' className='w-48'>
                    <Input placeholder='药方编号' />
                </Item>
                <Item name='symptomName' className='w-48'>
                    <Input placeholder='病例名称' />
                </Item>
                <Item name='catalogId' className='w-48'>
                    <Select allowClear placeholder='病例分类' options={options} />
                </Item>
                <Item name='status' className='w-48'>
                    <Select
                        placeholder='审核状态'
                        allowClear
                        options={[
                            { label: '待审核', value: 'submitted' },
                            { label: '审核通过', value: 'passed' },
                            { label: '审核未通过', value: 'rejected' },
                            { label: '无需审核', value: 'green' },
                        ]}
                    />
                </Item>
                <Item name='pharmacyName' className='w-48'>
                    <Input placeholder='开方药店' />
                </Item>
                <Item name='period' className='w-60'>
                    <RangePicker placeholder={['开始时间', '结束时间']} />
                </Item>
                <Item>
                    <Button type='primary' htmlType='submit' ghost>
                        查询
                    </Button>
                </Item>
            </Form>

            <Table
                rowKey='id'
                dataSource={data.content}
                columns={columns}
                loading={isFetching}
                pagination={{
                    total: data?.totalElements ?? 0,
                    pageSize: query.size,
                    current: query.page + 1,
                    onChange: (page) => setQuery({ page: page - 1 }),
                }}
            />
        </Wrap>
    )
}
