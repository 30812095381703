/*
 * @LastEditTime: 2022-09-06 14:08:32
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/store/auth.js
 */
import { isEmpty } from 'ramda'
import { atom, selector } from 'recoil'

export const userInfoAtom = atom({
    key: 'userInfo',
    default: {},
})

export const purviewTreeAtom = atom({
    key: 'purviewTree',
    default: [],
})

export const purviewsAtom = selector({
    key: 'purviews',
    get: ({ get }) => get(userInfoAtom).purviewVOList?.map((x) => x.path) ?? [],
})

export const menusAtom = selector({
    key: 'menus',
    get: ({ get }) => {
        const purviews = get(userInfoAtom).purviewVOList?.map((x) => x.path) ?? []
        const purviewsTree = get(purviewTreeAtom)
        const computedList = (list) => {
            return list.map((item) => {
                if (item.children) {
                    return { ...item, children: computedList(item.children), disabled: !purviews.includes(item.path) }
                } else {
                    return { ...item, key: item.path, disabled: !purviews.includes(item.path) }
                }
            })
        }
        if (!isEmpty(purviews) && !isEmpty(purviewsTree)) {
            const res = computedList(purviewsTree)
            // 把数据大屏在左边菜单过滤掉
            return res.filter((x) => x.path !== '/full-statistics')
        } else {
            return []
        }
    },
})
