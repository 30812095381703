/*
 * @LastEditTime: 2022-09-20 10:24:05
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/FullStatistics2/InspectionTrend.js
 * @FileName: 出勤率
 */
import { Loading } from '@jiaminghi/data-view-react'
import { useQuery } from 'react-query'
import { isNil, unnest } from 'ramda'
import { Line } from '@ant-design/plots'
import dayjs from 'dayjs'

import { task } from 'service'

// const RangePicker = styled(DatePicker.RangePicker)`
//     background-color: rgba(4, 27, 65, 0.7) !important;
//     border: 1px solid #64e5ce !important;
//     .ant-picker-input > input {
//         color: #64e5ce;
//     }
//     .ant-picker-suffix {
//         color: #64e5ce;
//     }
// `

export default function Main({ pondId }) {
    const { data, isFetching, isError } = useQuery(
        ['full-statistics', 'pond', pondId],
        () =>
            task.pond({
                pondId,
                period: [dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
            }),
        {
            select: (data) =>
                unnest(
                    data.map((x) => [
                        { day: x.day, type: '正常巡塘率', rate: (x.completedInTime / x.total).toFixed(4) * 100 },
                        { day: x.day, type: '超时巡塘率', rate: (x.completedOverTime / x.total).toFixed(4) * 100 },
                        { day: x.day, type: '异常率', rate: (x.fixed / x.total).toFixed(4) * 100 },
                    ])
                ),
            enabled: !!pondId,
            staleTime: 0,
        }
    )

    return (
        <div className='h-full flex flex-col'>
            <div className='h-full'>
                {isNil(pondId) ? (
                    <div className='text-white h-full fond-bold flex items-center justify-center text-base'>
                        请先选择鱼塘
                    </div>
                ) : isError ? (
                    <div className='w-full h-full flex items-center justify-center text-[#64e5ce] text-base'>
                        无法获取数据
                    </div>
                ) : isFetching ? (
                    <Loading />
                ) : (
                    <Line
                        padding={50}
                        xField='day'
                        yField='rate'
                        seriesField='type'
                        data={data || []}
                        yAxis={{ label: { style: { fill: '#fff' } } }}
                        xAxis={{ type: 'time', label: { style: { fill: '#fff' } } }}
                        label={{ formatter: (text) => `${text.rate.toFixed(2)}%`, style: { fill: '#fff' } }}
                        legend={{ position: 'top-right', itemName: { style: { fill: '#fff' } } }}
                        tooltip={{
                            formatter: (datum) => ({
                                name: datum.type,
                                value: `${datum.rate.toFixed(2)}%`,
                            }),
                        }}
                    />
                )}
            </div>
        </div>
    )
}
