/*
 * @LastEditTime: 2022-08-31 17:44:54
 * @LastEditors: Alps Chan
 * @FilePath: /muyu-web/src/pages/Certificate/Setting/index.js
 */
import { Card, Form, message, Switch, Spin } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { certificateConf } from 'service'
const { Item } = Form

export default function Setting() {
    const { data, isLoading, refetch } = useQuery(['certificate-conf', 'list'], () => certificateConf.list(), {
        placeholderData: [],
        cacheTime: 0,
    })

    const createMutation = useMutation(certificateConf.create, {
        onSuccess: () => {
            message.success('设置成功')
            refetch()
        },
    })

    const onChange = (checked, item) => {
        createMutation.mutate({
            id: item.id,
            necessary: checked,
        })
    }

    if (isLoading) {
        return <Spin />
    }

    return (
        <Card title='条件设置' autoComplete='off'>
            <Form>
                {data.map((e) => (
                    <Item key={e.conf} label={`${e.content}，是否作为出合格证条件`} name={e.conf}>
                        <Switch checked={e.necessary} onChange={(checked) => onChange(checked, e)} />
                    </Item>
                ))}
            </Form>
        </Card>
    )
}
